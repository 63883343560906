<div class="mwx-text-box">
  
  <p>
    The Universe Timeline is being fixed.  An Angular upgrade 
    made breaking change in checkbox handling in this 
    application.  Debugging is being performed.
  </p>

  <form>
        <input type="checkbox">Hello
  </form>
  <button type="submit">Click Me</button>
</div>

<div class="mwx-text-box">
  <form [formGroup]="filterListForm" 
    (ngSubmit)="getTimelineEvents()" novalidate>
    <div class="form-group">
        <input 
          type="checkbox"
          (click)="doCheck($event.target.checked)"
          formControlName="a_test"  >
        <label for="a_test">Test ({{ isChecked }})</label>

      <div *ngFor="let filter of filterCategories">
        <input 
          type=checkbox 
          formControlName="{{ filter.id }}"  >
        <label for="{{ filter.id }}"> {{ filter.name }}</label>

      </div> 
    </div>

    <button 
      class="btn btn-primary btn-sm btn-default"
      type="sumbit" >
      Filter
    </button>
  </form>
</div>

<div class="mwx-text-box">
  <table class="table-striped">
    <tr>
      <th>Date</th>
      <th>Event</th>
      <th>Galactic Caucus</th>
      <th>Chalmon</th>
      <th>Graeth</th>
      <th>Lhaknaar</th>
      <th>Sluve</th>
      <th>Govit</th>
      <th>Genoff</th>
    </tr>
    <tr *ngFor="let timeevent of timeline"> 

      <div *ngIf="timeevent.cyc != null; then thenOutBlock else elseOutBlock">
      </div>
      <ng-template #thenOutBlock>
        <td>
          {{timeevent.aen}}.{{timeevent.jen}}.{{timeevent.qtr}}{{timeevent.cyc}}
        </td>
      </ng-template>
      <ng-template #elseOutBlock>
        <div *ngIf="timeevent.jen != null; then theInBlock else elseInBlock">
        </div>
        <ng-template #thenInBlock>
          <td>
            {{timeevent.aen}}.{{timeevent.jen}}
          </td>
        </ng-template>
        <ng-template #elseInBlock>
          <td>
            {{timeevent.aen}}
          </td>
        </ng-template>
      </ng-template>

      <td> {{ timeevent.event }} </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isGalacticCaucus">&#10003;</i>  -->
        </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isChalmon">&#10003;</i>  -->
        </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isGraeth">&#10003;</i>  -->
        </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isLhaknaar">&#10003;</i>  -->
        </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isSluve">&#10003;</i>  -->
        </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isGovit">&#10003;</i>  -->
        </td>
      <td style="text-align: center">
        <!-- <i *ngIf="timeevent.isGenoff">&#10003;</i>  -->
        </td>
    </tr>
  </table>
</div>

