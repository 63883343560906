const id = "mwx-pvgd//mwx-signin/";

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } 
  from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { faSignInAlt, faWindowClose, faCheckSquare, faKey }
  from '@fortawesome/free-solid-svg-icons';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { NewUser } from '@app/mwx-auth/mwx-user.model';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';

@Component({
  selector: 'app-mwx-signin',
  templateUrl: './mwx-signin.component.html',
  styleUrls: ['./mwx-signin.component.css']
})
export class MwxSigninComponent implements OnInit {

  modalRef: BsModalRef;

  /*  Font Icons  */
  faSignInAlt = faSignInAlt;
  faWindowClose = faWindowClose;
  faCheckSquare = faCheckSquare;
  faKey = faKey;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxSignin",           //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  private signinForm: UntypedFormGroup;
  private loginLoading = false;

  constructor(
    private mwxAuthService: MwxAuthService,
    private modalService: BsModalService,
    private messageBus: MessageBus,
    private fb: UntypedFormBuilder) { 
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);

    //  Create form.
    this.createForm();
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";

    this.signinForm.get('usernameOrEmail').setValue(data.data.username);
    this.signinForm.get('password').setValue(data.data.password);
  }

  open(template: TemplateRef<any>) {
    const fid = id + "open(): ";
    this.modalRef = this.modalService.show(template);
  }

  private createForm(): void {
    const fid = id + "createForm(): ";

    this.signinForm = this.fb.group({
      usernameOrEmail: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      password: new UntypedFormControl('', {
        validators: [Validators.required]
      })
    });
  }

  private onSignin(): void {
    const fid = id + "onSignin(): ";

    this.signinForm.controls.usernameOrEmail.markAsDirty();
    this.signinForm.controls.password.markAsDirty();
    const { usernameOrEmail, password } = this.signinForm.value;

    if(this.signinForm.valid) {
      this.loginLoading = true;

      this.mwxAuthService.login(usernameOrEmail, password).subscribe(
        result => {
          this.modalService.hide();
          this.loginLoading = false;
          this.mwxAuthService.setAuthToken(result.auth_token);
          this.sendLoginNotice();
        },
        err => {
          //  Send a message to the modal
          const message:Message<any> = {
            recipientIds: ["mwxModal"],
            payload: { title: "Login Error", type: "ALERT",
              body: "Invalid login.  Screen Name or Email do not " +
                "match password.  At present, password recovery " +
                "has not been developed.  For assistance, use the " +
                "Contact button on the Home tab of the website." },
            groupId: this.msgq.groupId
          };
          this.msgq.msgqConnection.post(message);

          this.loginLoading = false;
        }
      );
    }
  }

  sendLoginNotice() {
    const fid = id + "sendLoginNotice";

    let message:Message<any> = {
      recipientIds: [],
      payload: {},
      groupId: this.msgq.groupId
    };

    //  Send signin message to app.  Updates menubar.
    message.recipientIds = ["app"];
    message.payload = {  cmd: 0x0004, data: null };
    this.msgq.msgqConnection.post(message);

    //  Send signin message to pvgd-menu.  This updates the user's
    //  assets in the menu.
    message.recipientIds = ["pvgdMenu"];
    message.payload = {  cmd: 0x0004, data: null, hdg: null };
    this.msgq.msgqConnection.post(message);

    //  Send signin message to tabpane.  Updates main menu.
    message.recipientIds = ["mwxTabpane"];
    message.payload = {  cmd: 0x0004, data: null, hdg: null };
    this.msgq.msgqConnection.post(message);

    //  Send a message to the tabpane to add the developer tab.  In 
    //  the future, the addition of tabs at signin will be based
    //  on the user's role.
    message.recipientIds = ["mwxTabpane"];
    message.payload = { cmd: 0x1011, data: "mwxDevelopment", hdg: null };
    this.msgq.msgqConnection.post(message);

    //  Set the current tab to the home tab with the following command.
    message.recipientIds = ["mwxTabpane"];
    message.payload = { cmd: 0x1014, data: null, hdg: null };
    this.msgq.msgqConnection.post(message);

  }

}
