<div class="banner-box">
  <h6 class="banner-text">
    Website Status: Early Development
  </h6>
  <!--
  <h6 class="d-block d-sm-none banner-text">
    Bootstrap Breakpoint: xs</h6>
  <h6 class="d-none d-sm-block d-md-none banner-text">
    Bootstrap Breakpoint: sm</h6>
  <h6 class="d-none d-md-block d-lg-none banner-text">
    Bootstrap Breakpoint: md</h6>
  <h6 class="d-none d-lg-block d-xl-none banner-text">
    Bootstrap Breakpoint: lg</h6>
  <h6 class="d-none d-xl-block banner-text">
    Bootstrap Breakpoint: xl</h6>
  -->
</div>

