<div>

  <div class="hdrRow" align="center">
    <div class="rounded hdrCol" align="center">
      <img class="hdrImg" src="/assets/logos/TyroUnivLogo-Light.svg">
    </div>
  </div>

  <div class="row">

    <div class="col" align="left">

      <h2 align="center">Tyro Universe Info</h2>
      <hr>

      <p><b>Tyro Universe, The Introductory Sci-Fi RPG</b> is a 
      rules-light science fiction adventure role playing game for
      families, new gamers, and casual gaming from Tower Ravens LLC.  
      It shares a simplified version of the Orphans' Realm game system.  
      For more information about Tyro Universe, questions can be 
      directed to our Facebook page.</p>

    </div>
    <div class="col" align="left">

      <h2 align="center">Tools</h2>
      <hr>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('tyroUtilRef')">
        <fa-icon [icon]="faBook"></fa-icon>
        Tyro Universe Reference</button>
        The Tyro Universe Reference provide quick access to data commonly
        needed from the <i>Tyro Universe Rulebook</i>.  It is provided
        here for quick reference.  The complete Tyro Universe rules are
        availble for purchase.
      </p>

    </div>

    <div class="col" align="left">

      <h2 align="center">Products</h2>
      <hr>

      <div *ngFor="let item of items">
        <h5>{{item.name}}</h5>
        <img class='prodImg' src='{{item.thumbURI}}'>
        <p>{{item.desc}}</p>
        <p align="right">
          <span class='{{item.priceSymbol}}'></span>
          <b>{{item.priceText}}</b> &nbsp;
          <button class="btn btn-dark btn-sm prod" type="submit"
            (click)="clickURI(item)" >
            <span class='{{item.btnSymbol}}'></span>
            {{item.btnText}}
          </button>
        </p>
        <hr>
      </div>

    </div>

  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>"Tyro Universe, The Introductory Sci-Fi RPG" and the galaxy 
        logo are trademarks owned by Tower Ravens LLC</small>
    </div>
  </div>

</div>

