<div class="container accrdn" align="center">
  <img class="imgHdg" src="/assets/logos/pvgd-Logo-LtBkg.png">

  <accordion [closeOthers]='true' [isAnimated]='true'>

    <accordion-group id="pvgdMenuHdgCharacters" 
      (isOpenChange)="refreshMenu()">
      <span accordion-heading>
        <fa-icon [icon]="faUserShield"></fa-icon> Characters
      </span>
      <div *ngIf="!mwxAuthSvc.isAuthenticated()" >
        <p><a href="." (click)="requestTab('pvgdXmplChar')">
          X'ample the Redd</a>
        </p>
        <hr>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openSign()">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
          Create Character</button>
        </p>

        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openSign()">
          <fa-icon [icon]="faCog"></fa-icon>
          Character Library</button>
        </p>
      </div>
      <div *ngIf="mwxAuthSvc.isAuthenticated()">
        <div *ngIf="charct; else noChar">
          <div *ngFor="let char of charlist">
            <p><a href="." 
              (click)="requestTab('pvgdChar', 
                {title:char.name, idx:char._id})">
              {{ char.name }}
            </a></p>
          </div>
        </div>
        <ng-template #noChar><p><i>No Characters</i></p></ng-template>
        <hr>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openNewChar()">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
          Create Character</button>
        </p>

        <p><button class="btn btn-dark btn-sm tool" 
          (click)="requestTab('pvgdListChar')">
          <fa-icon [icon]="faCog"></fa-icon>
          Character Library</button>
        </p>

      </div>
      <!-- Prime (2 slots), Paid (0 slots), Transitory (1 slot) -->
      <!-- Button to all characters, including Banked and Archived  -->
    </accordion-group>

    <accordion-group id="pvgdMenuHdgCampaigns"
      (isOpenChange)="refreshMenu()">
      <span accordion-heading>
        <fa-icon [icon]="faUsers"></fa-icon> Campaigns
      </span>

      <!-- Game Master (1 slot prime, 0 slots paid), Member  -->
      <!-- (unlimited slots), Prime (1 slot), paid (0 slots)  -->
      <div *ngIf="!mwxAuthSvc.isAuthenticated()">
        <p><a href="." (click)="requestTab('pvgdXmplCpgn')">
          X'ample & X'emplars</a>
        </p>
        <hr>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openSign()">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
          Create Campaign</button>
        </p>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openSign()">
          <fa-icon [icon]="faCog"></fa-icon>
          Campaign Library</button>
        </p>
      </div>
      <div *ngIf="mwxAuthSvc.isAuthenticated()">
        <div *ngIf="cpgnct; else noCpgn">
          <div *ngFor="let cpgn of cpgnlist">
            <p><a href="." 
              (click)="requestTab('pvgdCpgn', 
                {title:cpgn.name, idx:cpgn._id})">
              {{ cpgn.name }}
            </a></p>
          </div>
        </div>
        <ng-template #noCpgn><p><i>No Campaigns</i></p></ng-template>
        <hr>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openNewCpgn()">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
          Create Campaign</button>
        </p>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="requestTab('pvgdListCpgn')">
          <fa-icon [icon]="faCog"></fa-icon>
          Campaign Library</button>
        </p>

      </div>

    </accordion-group>

    <accordion-group id="pvgdMenuHdgAdventures"
      (isOpenChange)="refreshMenu()">
      <span accordion-heading>
        <fa-icon [icon]="faDragon"></fa-icon> Games
      </span>
      <div *ngIf="!mwxAuthSvc.isAuthenticated()">
        <p><a href="." (click)="requestTab('pvgdXmplGame')">
          X'ample's X'hibition</a>
        </p>
        <hr>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="openSign()">
          <fa-icon [icon]="faCog"></fa-icon>
          Game Chest</button>
        </p>

      </div>
      <div *ngIf="mwxAuthSvc.isAuthenticated()">
        <div *ngIf="gamect; else noGame">
          <div *ngFor="let game of gamelist">
            <p><a href="." 
              (click)="requestTab('pvgdGameBox', 
                {title:game.name, idx:game._id})">
              {{ game.name }}
            </a></p>
          </div>
        </div>
        <ng-template #noGame><p><i>No Game Boxes</i></p></ng-template>
        <hr>
        <p><button class="btn btn-dark btn-sm tool" 
          (click)="requestTab('pvgdListGame')">
          <fa-icon [icon]="faCog"></fa-icon>
          Game Chest</button>
        </p>

      </div>

    </accordion-group>

  </accordion>

  <div *ngIf="!mwxAuthSvc.isAuthenticated()">
    <br>
    <p>
      <button class="btn btn-dark btn-sm"
      (click)="requestTab('pvgdXmplVtt')">
      <fa-icon [icon]="faMapMarkedAlt"></fa-icon> Example Tabletop
      </button>
    </p>
    <hr>
  </div>

  <div *ngIf="mwxAuthSvc.isAuthenticated()">
    <br>
  </div>

</div>

<!-- Modal begin:  New Character -->

<ng-template #newChar>

  <form [formGroup]="newCharForm" (ngSubmit)="onNewChar()" >
    <div class="modal-header">
      <h4 class="modal-title" id="newCharModalLabel">
        <fa-icon [icon]="faPencilAlt"></fa-icon>
        New Character
      </h4>
      <button type="button" class="close" aria-label="Close"
        id="pvgdNewCharBtnClose" (click)="hideNewChar()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <div class="modal-body">
        <div class="form-group" align="center">
          <div>
            <p id="message_txt_id"
              style="background-color:#0EB521; padding:5px;">
              {{ message_txt }}
            </p>
          </div>

          <input id="pvgdNewCharCharname" class="modal-input" 
            style="width: 200px;"
            type="text" placeholder="Character Name"
            formControlName="charName">
          <br>
          <br>
          <select id="pvgdNewCharGameSys" class="form-control" 
            formControlName="gameSys" 
            style="width: 200px; background-color: #20190E; color: #D1B68F;"
            required>
            <option *ngFor="let system of gameSystems" [ngValue]="system">
              {{system}}</option>
          </select>
          <p><small><i>NOTE: The game system cannot be changed
            once the character is created.</i></small></p>
        </div>
    </div>

    <div class="modal-footer">
      <button id="pvgdNewCharBtnCancel"
        type="button" class="btn btn-secondary btn-sm"
        (click)="hideNewChar()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
        Cancel</button>
      <button id="pvgdNewCharBtnSubmit"
        class="btn btn-primary btn-sm"
        [disabled]="!newCharForm.valid" >
        <fa-icon [icon]="faCheckSquare"></fa-icon>
        Submit</button>
    </div>
  </form>

</ng-template>

<!-- Modal end:  New Character -->


<!-- Modal begin:  New Campaign -->

<ng-template #newCpgn let-modal>
  <form [formGroup]="newCpgnForm" (ngSubmit)="onNewCpgn()" >
    <div class="modal-header">
      <h4 class="modal-title" id="newCpgnModalLabel">
        <fa-icon [icon]="faPencilAlt"></fa-icon>
        New Campaign
      </h4>
      <button type="button" class="close" aria-label="Close"
        id="pvgdNewCpgnBtnClose"
        (click)="hideNewCpgn()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <div class="modal-body">
        <div class="form-group" align="center">
          <div>
            <p id="message_txt_id"
              style="background-color:#0EB521; padding:5px;">
              {{ message_txt }}
            </p>
          </div>

          <input id="pvgdNewCpgnCpgnname"
            class="modal-input" type="text" placeholder="Campaign Name"
            style="width: 250px;"
            formControlName="cpgnName">
          <br>
          <br>
          <select id="pvgdNewCpgnGameSys" class="form-control" 
            style="width: 250px; background-color: #20190E; color: #D1B68F;"
            formControlName="gameSys" required>
            <option *ngFor="let system of gameSystems" [ngValue]="system">
              {{system}}</option>
          </select>
          <p><small><i>NOTE: The game system cannot be changed
            once the campaign is created.</i></small></p>
        </div>
    </div>

    <div class="modal-footer">
      <button id="pvgdNewCpgnBtnCancel"
        type="button" class="btn btn-secondary btn-sm"
        (click)="hideNewCpgn()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
        Cancel</button>
      <button id="pvgdNewCpgnBtnSubmit"
        class="btn btn-primary btn-sm"
        [disabled]="!newCpgnForm.valid" >
        <fa-icon [icon]="faCheckSquare"></fa-icon>
        Submit</button>
    </div>
  </form>

</ng-template>

<!-- Modal end:  New Campaign -->


<!-- Modal begin:  New Game Box -->

<ng-template #newGame let-modal>
  <form [formGroup]="newGameForm" (ngSubmit)="onNewGame()" >
    <div class="modal-header">
      <h4 class="modal-title" id="newGameModalLabel">
        <fa-icon [icon]="faPencilAlt"></fa-icon>
        New Game Box
      </h4>
      <button type="button" class="close" aria-label="Close"
        id="pvgdNewGameBtnClose"
        (click)="hideNewGame()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <div class="modal-body">
        <div class="form-group" align="center">
          <div>
            <p id="message_txt_id"
              style="background-color:#0EB521; padding:5px;">
              {{ message_txt }}
            </p>
          </div>

          <input id="pvgdNewGameCpgnname"
            class="modal-input" type="text" placeholder="Game Box Name"
            style="width: 250px;"
            formControlName="gameName">
          <br>
          <br>
          <select id="pvgdNewGameGameSys" class="form-control" 
            style="width: 250px; background-color: #20190E; color: #D1B68F;"
            formControlName="gameSys" required>
            <option *ngFor="let system of gameSystems" [ngValue]="system">
              {{system}}</option>
          </select>
          <p><small><i>NOTE: The game system cannot be changed
            once the game box is created.</i></small></p>
        </div>
    </div>

    <div class="modal-footer">
      <button id="pvgdNewGameBtnCancel"
        type="button" class="btn btn-secondary btn-sm"
        (click)="hideNewGame()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
        Cancel</button>
      <button id="pvgdNewGameBtnSubmit"
        class="btn btn-primary btn-sm"
        [disabled]="!newGameForm.valid" >
        <fa-icon [icon]="faCheckSquare"></fa-icon>
        Submit</button>
    </div>
  </form>

</ng-template>

<!-- Modal end:  New Game Box -->
