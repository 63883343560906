const id = "mwx-pvgd//mwx-main/";

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxContactComponent } from '@app/mwx-contact/mwx-contact.component';
import { faFilePdf, faAddressCard, faSignOutAlt, faDiceD20,
  faQuestionCircle, faMapMarkedAlt, faEnvelope, faDraftingCompass, 
  faProjectDiagram, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-mwx-main',
  templateUrl: './mwx-main.component.html',
  styleUrls: ['./mwx-main.component.css']
})
export class MwxMainComponent implements OnInit {

  /*  Font Icons  */
  faFilePdf = faFilePdf;
  faAddressCard = faAddressCard;
  faSignOutAlt = faSignOutAlt;
  faDiceD20 = faDiceD20;
  faQuestionCircle = faQuestionCircle;
  faMapMarkedAlt = faMapMarkedAlt;
  faEnvelope = faEnvelope;
  faDraftingCompass = faDraftingCompass;
  faProjectDiagram = faProjectDiagram;
  faDiscord = faDiscord;
  faCalendarDays = faCalendarDays;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxMain",             //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    public mwxAuthService: MwxAuthService,
    private messageBus: MessageBus) { 
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  public openWindow(url:string): void {
    const fid = id + "openWindow(): ";
    window.open(url);
  }

  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

  }


  public popupModal(): void {
    const message:Message<any> = {
      recipientIds: ["mwxModal"],
      payload: { title: "Login Required", type: "WARN",
        body: "A login is required to access this area of the " +
          "website.  For assistance or to request an account, " +
          "use the Contact button on the Home tab of the website." },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

  private signout(): void {
    const fid = id + "signout(): ";

    this.mwxAuthService.signout();
  }

}
