//  MarkWilx, Proving Ground, Operative Online

import { NgModule } from '@angular/core';

//  Browser
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from'@angular/platform-browser/animations';

//  Forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//  HTTP
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

//  div resize
//import { AngularResizeEventModule } from 'angular-resize-event';

//  ngx-Bootstrap
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SortableModule } from 'ngx-bootstrap/sortable';

//  ngx-color-picker
import { ColorPickerModule } from 'ngx-color-picker';

//  ngx-socket-io
//import { SocketIoModule } from 'ngx-socket-io';
//import { SocketIoConfig } from 'ngx-socket-io';

//  tree-ngx
import { TreeNgxModule } from 'tree-ngx';

//  svg.js
import { SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
import '@svgdotjs/svg.panzoom.js';

//  Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

//  Three.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import SpriteText from 'three-spritetext';

//  Pipes
import { nl2brPipe } from './mwx-data/nl2br.pipe';

//  Application
import { AppComponent } from './app.component';

//  MarkWilx
import { MwxModalComponent } from './mwx-modal/mwx-modal.component';
import { MwxModalContent } from './mwx-modal/mwx-modal.component';
import { MwxBannerComponent } from './mwx-banner/mwx-banner.component';
import { MwxTabpaneComponent } from './mwx-tabpane/mwx-tabpane.component';
import { MwxMainComponent } from './mwx-main/mwx-main.component';
import { MwxFooterComponent } from './mwx-footer/mwx-footer.component';
import { MwxAboutComponent } from './mwx-about/mwx-about.component';
import { MwxContactComponent } from './mwx-contact/mwx-contact.component';
import { MwxContactService } from './mwx-contact/mwx-contact.service';
import { MwxDevelopmentComponent } 
  from './mwx-development/mwx-development.component';

//  Operative Online
import { PvgdMenuComponent } from './pvgd-menu/pvgd-menu.component';
import { MwxProfileComponent } from './mwx-profile//mwx-profile.component';
import { MwxAuthService } from './mwx-auth/mwx-auth.service';
import { MwxTokenInterceptorService } 
  from './mwx-auth/mwx-token-interceptor.service';
import { MwxCatalogService } from './mwx-auth/mwx-catalog.service';
import { PvgdCharService } from './pvgd-char/pvgd-char.service';
import { PvgdCpgnService } from './pvgd-cpgn/pvgd-cpgn.service';

import { MwxSignComponent } from './mwx-sign/mwx-sign.component';
//  MWX -- shouldn't this be content?
import { MwxSignContent } from './mwx-sign/mwx-sign.component';
import { MwxSigninComponent } from './mwx-signin/mwx-signin.component';
import { MwxSignupComponent } from './mwx-signup/mwx-signup.component';

import { PvgdMainComponent } from './pvgd-main/pvgd-main.component';

import { PvgdXmplCharComponent } 
  from './pvgd-xmpl-char/pvgd-xmpl-char.component';
import { PvgdXmplCpgnComponent } 
  from './pvgd-xmpl-cpgn/pvgd-xmpl-cpgn.component';
import { PvgdXmplGameComponent } 
  from './pvgd-xmpl-game/pvgd-xmpl-game.component';
import { PvgdXmplVttComponent } 
  from './pvgd-xmpl-vtt/pvgd-xmpl-vtt.component';

import { PvgdListCharComponent } 
  from './pvgd-list-char/pvgd-list-char.component';
import { PvgdListCpgnComponent } 
  from './pvgd-list-cpgn/pvgd-list-cpgn.component';
import { PvgdListGameComponent } 
  from './pvgd-list-game/pvgd-list-game.component';
import { PvgdCharComponent } 
  from './pvgd-char/pvgd-char.component';
import { PvgdCpgnComponent } 
  from './pvgd-cpgn/pvgd-cpgn.component';

//  Game Systems
import { PvgdModule } from './pvgd/pvgd.module';
import { OrphModule } from './orph/orph.module';
import { RelmModule } from './relm/relm.module';
import { SfrnModule } from './sfrn/sfrn.module';
import { TyroModule } from './tyro/tyro.module';
import { UnivModule } from './univ/univ.module';

//  Environment Variables 
import { environment } from './../environments/environment';
import { DavnPastproComponent } from './davn-pastpro/davn-pastpro.component';

//  Socket Configuration
/*
const sockConfig: SocketIoConfig = { 
  url: 'http://localhost:3006',
  options: {
    // MWX -- This line became invalid with npm 8.0.0
    //withCredentials: true,
  }
};
*/

@NgModule({
    declarations: [
        AppComponent,
        MwxBannerComponent,
        MwxTabpaneComponent,
        MwxFooterComponent,
        MwxAboutComponent,
        MwxContactComponent,
        MwxDevelopmentComponent,
        MwxModalComponent,
        MwxModalContent,
        MwxMainComponent,
        MwxProfileComponent,
        MwxSignComponent,
        MwxSignContent,
        MwxSigninComponent,
        MwxSignupComponent,
        PvgdMainComponent,
        PvgdMenuComponent,
        PvgdXmplCharComponent,
        PvgdXmplCpgnComponent,
        PvgdXmplGameComponent,
        PvgdXmplVttComponent,
        PvgdListCharComponent,
        PvgdListCpgnComponent,
        PvgdListGameComponent,
        PvgdCharComponent,
        PvgdCpgnComponent,
        nl2brPipe,
        DavnPastproComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MwxTokenInterceptorService,
            multi: true
        },
        MwxContactService,
        MwxAuthService,
        MwxCatalogService,
        PvgdCharService,
        PvgdCpgnService
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        //SocketIoModule.forRoot(sockConfig),
        HttpClientModule,
        //AngularResizeEventModule,
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        AccordionModule.forRoot(),
        BsDropdownModule.forRoot(),
        SortableModule.forRoot(),
        TooltipModule.forRoot(),
        CollapseModule.forRoot(),
        ColorPickerModule,
        TreeNgxModule,
        FontAwesomeModule,
        PvgdModule,
        OrphModule,
        RelmModule,
        SfrnModule,
        TyroModule,
        UnivModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { 
  constructor() {
    library.add(fas, fab);
  }
}

