<div id="modal-header" class="modal-header" 
  [style.background-color]="ttlBkgClr"
  [style.color]="ttlTxtClr" > 

  <h4>
    <fa-icon *ngIf="isInfo" [icon]='faBook'></fa-icon>
    <fa-icon *ngIf="isNotice" [icon]='faInfoCircle'></fa-icon>
    <fa-icon *ngIf="isWarn" [icon]='faExclamationCircle'></fa-icon>
    <fa-icon *ngIf="isAlert" [icon]='faExclamationTriangle'></fa-icon>
    {{title}}
  </h4>
  <button class="close" aria-label="Close" 
    (click)="bsModalRef.hide()">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>

<div id="modal-body" class="modal-body"
  [style.background-color]="bdyBkgClr"
  [style.color]="bdyTxtClr" > 
  <div [innerHTML]="body"></div>
</div>

<div id="modal-footer" class="modal-footer"
  [style.background-color]="ttlBkgClr"
  [style.color]="ttlTxtClr" > 
  <button type="button" class="btn btn-dark btn-sm btn-default" 
    ngbAutofocus (click)="bsModalRef.hide()">
    <fa-icon [icon]="faCheckSquare"></fa-icon> Okay
  </button>
</div>

