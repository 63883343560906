const id = "mwx-pvgd//mwx-modal/";

import { Component, OnInit, Input } from '@angular/core';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageBus, Connection } from 'ngx-message-bus';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faWindowClose, faCheckSquare, faBook, faInfoCircle,
  faExclamationCircle, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';

@Component({
  selector: 'app-mwx-modal',
  templateUrl: './mwx-modal.component.html'
})
export class MwxModalComponent implements OnInit {
  @Input('message') message: string;
  @Input('messageType') messageType: string;

  bsModalRef: BsModalRef;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxModal",            //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    private mwxAuthService: MwxAuthService,
    private modalService: BsModalService,
    private messageBus: MessageBus) { 
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";

    //  Pop up the Modal window.
    this.openModal(data);
  }

  //  Displays a modal (popup) on the screen.  Input is:
  //  data.type = [ 'INFO', 'NOTICE', 'WARN', 'ALERT' ], 
  //  data.title, data.body. 
  openModal(data) {
    const fid = id + "openModal(): ";

    const initialState = {
      title: data.title,
      type: data.type,
      body: data.body
    };
    this.bsModalRef = this.modalService.show(MwxModalContent, {initialState} );
  }

}

@Component({
  selector: 'app-mwx-modal-content',
  templateUrl: './mwx-modal.content.html',
  styleUrls: ['./mwx-modal.content.css']
})
export class MwxModalContent implements OnInit {
  title: string;
  type: string;
  body: string;

  isInfo = false;
  isNotice = false;
  isWarn = false;
  isAlert = false;

  ttlBkgClr = "#202020";
  ttlTxtClr = "#EEEEEE";
  bdyTxtClr = "#AAAAAA";
  bdyBkgClr = "#333333";

  faWindowClose = faWindowClose;
  faCheckSquare = faCheckSquare;
  faBook = faBook;
  faInfoCircle = faInfoCircle;
  faExclamationCircle = faExclamationCircle;
  faExclamationTriangle = faExclamationTriangle;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Set the title icon plus the text and background colors.
    if(this.type == "INFO") {
      this.isInfo = true;
      //  Normal
      this.ttlTxtClr = "#20190E";
      this.ttlBkgClr = "#D1B68F";
      this.bdyTxtClr = "#20190E";
      this.bdyBkgClr = "#F3ECE2";
    } else if(this.type == "NOTICE") {
      this.isNotice = true; 
      //  Green
      this.ttlTxtClr = "#20190E";
      this.ttlBkgClr = "#0EB521";
      this.bdyTxtClr = "#20190E";
      this.bdyBkgClr = "#F3ECE2";
    } else if(this.type == "WARN") {
      this.isWarn = true;
      //  Yellow
      this.ttlTxtClr = "#20190E";
      this.ttlBkgClr = "#E0B521";
      this.bdyTxtClr = "#20190E";
      this.bdyBkgClr = "#F3ECE2";
    } else if(this.type == "ALERT") {
      this.isAlert = true;
      //  Red
      this.ttlTxtClr = "#20190E";
      this.ttlBkgClr = "#FC4142";
      this.bdyTxtClr = "#20190E";
      this.bdyBkgClr = "#F3ECE2";
    }

    //  Set the modal size.
    if(
      (this.title.length > 15)||
      (this.body.length > 200)) {
      this.setWidth("lg");
    } else if(
      (this.title.length < 10) &&
      (this.body.length < 50)) {
      this.setWidth("sm");
    } else {
      this.setWidth("md");
    }

  }

  setWidth(sz) {
    let modalWidth = 'modal-md';

    if(sz == "lg") {
      modalWidth = 'modal-lg';
    } else if(sz == "sm") {
      modalWidth = 'modal-sm';
    } else {
      modalWidth = 'modal-md';
    }

    this.bsModalRef.setClass(modalWidth);
  }

}

