<div style="padding: 13px;">
  <div class="mwx-text-box">
    <h3>General Information</h3>

    <p align="left">
      MarkWilx is the online presence for Mark Wilkinson.  Mark is an author 
      who developed <i>Universe, The Sci-Fi RPG</i> and published it with 
      Tower Ravens LLC.</p>

    <p align="left">
      Mark is presently writing science fiction stories, developing 
      additional tabletop role playing games, and is developing RPG 
      utilities on this website.
    </p>

    <p align="left">
      <button class="btn btn-dark btn-sm" style="float:right;"
        type="submit"
        onclick="window.open('/assets/downloads/Guide_to_Universe.pdf')">
        <fa-icon [icon]="faFilePdf"></fa-icon>
        Guide to Universe
      </button>
      This website will be opened pubicly at the beginning of beta
      test.  Until then, a "playtester code" is required to create
      an account.  If you want a code, read the document,
      <i>Guide to Universe.</i>  If you have questions, you may make 
      your inquiry via the "Contact" button on the "Home" tab, or you 
      may inquire on the 
      <a href="#" 
        onclick="window.open('https://www.facebook.com/groups/52208442579/')"
        target="_blank">
        <fa-icon [icon]="faFacebookSquare"></fa-icon> FaceBook
      </a> 
      page maintained by our supporters.  Once you're ready for a playtester 
      code, request one using the "Contact" form available on the "Home" tab.
    </p>

    <p>
      At present, no products are available for sale; they will become 
      available through 
      <a href="#"
        onclick="window.open('https://www.drivethrurpg.com/browse/' +
        'pub/10089/Tower-Ravens-LLC')"
        target="_blank">
        <fa-icon [icon]="faDiceD20"></fa-icon>
        DriveThru RPG
      </a> and DriveThru Fiction.
    </p>
  </div>

  <div class="mwx-text-box">
    <h3>Tower Ravens and MarkWilx</h3>

    <p>Tower Ravens was formed in 1995 and developed and published the
      Universe Primer in 2003.  MarkWilx was formed in 2018 to provide 
      the digital technologies to provide a web presence for Tower 
      Ravens' Universe RPG and Mark Wilkinson's other works.</p>
  </div>

  <div class="mwx-text-box">
    <h3>Contact</h3>

    <p>During the development of this website, MarkWilx and Tower
      Ravens may be contacted via the "Contact" form
      available at the "Home" tab, or by posting a query on
      the Universe <a href="#" onclick="window.open('https://' +
      'www.facebook.com/groups/52208442579/')" target="_blank">
      <fa-icon [icon]="faFacebookSquare"></fa-icon> FaceBook</a> 
      fan page.</p>
  </div>

  <div class="mwx-text-box">
    <h3>Legal & Privacy Policy</h3>

    <p>This website is in development.  During development, no 
      assurance can be made regarding the collection and protection 
      of private information.  <b>Do not</b> upload personal 
      information to this website.</p>

    <p>MarkWilx, which provides a variety of services, uses web tokens
      and a minimal amount of data storage on your machine in order to 
      facilitate the delivery of the services.  If you do not want 
      MarkWilx storing information on your machine, do not use this 
      service.</p>

    <p>As communications between users is not monitored, this 
      system should not be used by minors without the supervision
      of parents or guardians.</p>

    <p>MarkWilx believes strongly in privacy, and only requires
      minimal information from you.  In order to maintain an account 
      with MarkWilx, you must provide a "Screen Name" that is 
      viewable by other users; an alias or pseudonym is acceptable.  You 
      must provide a functioning email address to which you have access.  
      It will not be disclosed to third parties.  You agree to receive 
      a status email a maximum of once per month as well as essential 
      business emails; you cannot opt out of these.  All other messages 
      are configurable in your account.</p>

    <p>MarkWilx will collect network metadata in order to monitor
      and improve these services.  This information will not be 
      disclosed to third-parties, and it will not be used to 
      identify you.  MarkWilx will not take any actions to restrict nor
      limit the use of VPN services.</p>

    <p>MarkWilx may terminate your access to this service at any
      time for any lawful reason.  Failure to maintain a functioning
      email address will result in account termination.</p>

    <p>MarkWilx makes no guarantee regarding the availability or
      reliability of this service, and warranties nothing.  MarkWilx
      will not be held liable for data or financial loss resulting from 
      the use of their services.</p>

  </div>

<div class="mwx-text-box">
  <h3>Leadership</h3>

  <p><b>Mark Wilkinson.</b> Founder of MarkWilx and co-founder of Tower Ravens,
    co-developer of Universe, developer of Tyro Universe and Orphans' Realm, 
    lead software architect, author of <i>Maneuvers</i> and other fiction.</p>

  <p><b>Michael Wilkinson.</b> Co-founder of Tower Ravens, co-developer
    of Universe. </p>

  <p><b>William Pace.</b> Co-founder of Tower Ravens, art director for
    Universe.</p>

  <p><b>Michael Sorenson.</b> Art director for Tyro Universe and Orphans'
    Realm, artist for Universe.</p>

</div>
</div>
