const id = "mwx-pvgd//univ-util-ref-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnivUtilRefService {

  constructor(private http: HttpClient, 
    private mwxAuthService: MwxAuthService) { }

  public getRefItems(
    type: string,
    cat: string,
    iden:  string): Observable<any> {

    const fid = id + "getRefItems(): ";

    var uri = environment.MARKWILX_API_URI;
    uri += '/api/univ-util-ref';
    uri += '?type=' + type;
    uri += '&cat=' + cat;
    uri += '&iden=' + iden;

    //console.log(fid + "uri: " + uri);
    return this.http.get(uri);
  }
}

