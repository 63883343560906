<div>

  <div class="hdrRow" align="center">
    <div class="rounded hdrCol" align="center">
      <img class="hdrImg" src="/assets/logos/UniverseLogo-Light.svg">
    </div>
  </div>

  <div class="row">

    <div class="col" align="left">

      <h2 align="center">Universe Info</h2>
      <hr>

      <p><b>Universe, The Sci-Fi RPG</b> is a highly detailed science
      fiction role-playing game in public development by Tower Ravens 
      LLC.  For more information about Universe and the public development
      download the "Guide to Universe" PDF document available on this
      webpage.  Questions can be directed to our Facebook page.</p>

      <h5>Universe Stellar Cartography</h5>
      <p style="text-align: center;">
        <iframe src="https://www.youtube.com/embed/D2E1Rc2-1pk" 
          title="YouTube video player" frameborder="0" loop="0"
          allow="accelerometer; autoplay; clipboard-write; 
          encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
      </p>

    </div>
    <div class="col" align="left">
      <h2 align="center">Tools</h2>
      <hr>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('univUtilRef')">
        <fa-icon [icon]="faBook"></fa-icon>
        Universe Reference</button>
        The Universe Reference provide quick access to data commonly 
        needed from the <i>Universe Master Rulebook</i>.  It is provided
        here for quick reference.  The complete Universe rules are
        availble for purchase.  Refer to the Volumes I thru III.
      </p>
  
      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('univUtilStarcht')">
        <fa-icon [icon]="faSun"></fa-icon>
        Universe Star Chart</button>
        An interactive map showing the stars of the Vortis Massif of the
	Antwaris Galaxy.
      </p>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('univUtilPerftbl')">
        <fa-icon [icon]="faTh"></fa-icon>
        Performance Table</button>
        The Universe Performance Table is used to resolve attribute check,
        aptitude tests, and ability trials.  It compares a random roll 
        with the adjusted difficult of the task to determine the outcome.
        This online tool simplifies using the Performance Table.
      </p>
  
      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('univUtilTimeline')">
        <fa-icon [icon]="faClock"></fa-icon>
        Universe Timeline</button>
        Major events in the human expanse in the Vortis Massif of the 
        Antwaris Galaxy are provided on the Universe Timeline.  This
        online tool allows users to filter by the major human subspecies.
      </p>

    </div>

    <div class="col" align="left">

      <h2 align="center">Products</h2>
      <hr>

      <div *ngFor="let item of items">
        <h5>{{item.name}}</h5>
        <img class='prodImg' src='{{item.thumbURI}}'>
        <p>{{item.desc}}</p>
        <p align="right">
          <span class='{{item.priceSymbol}}'></span>
          <b>{{item.priceText}}</b> &nbsp;
          <button class="btn btn-dark btn-sm prod" type="submit"
            (click)="clickURI(item)" >
            <span class='{{item.btnSymbol}}'></span>
            {{item.btnText}}
          </button> 
        </p>
        <hr>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>"Universe, The Sci-Fi RPG" and the galaxy logo are registered 
        trademarks owned by Tower Ravens LLC</small>
    </div>
  </div>

</div>

