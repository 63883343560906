const id = "mwx-pvgd//univ-util-ref/";

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UnivUtilRefService } from './univ-util-ref.service';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';

@Component({
  selector: 'app-univ-util-ref',
  templateUrl: './univ-util-ref.component.html',
  styleUrls: ['./univ-util-ref.component.css']
})
export class UnivUtilRefComponent implements OnInit {

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "univUtilRef",         //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  public ref: any[] = [];
  private type: any = null;

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private messageBus: MessageBus,
    private univUtilRefService: UnivUtilRefService
  ) { }

  ngOnInit() {
    const fid = id + "ngOnInit() ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  private titleCase(str) {
    // Lowercase the string
    str = str.toLowerCase();

    // Split the string into an array of strings
    str = str.split(' ');

    // Create the FOR loop
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }

    // Return the output
    return str.join(' ');
  }

  private getEquipmentCategory(cat, subcat) {
    let rtnval = "Unidentified";
    let rtnval_cat = null;

    switch(cat) {
      case 1:
        rtnval = "Amenity";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Furnishing"; break;
          case 3: rtnval_cat = "Appliance"; break;
          case 4: rtnval_cat = "Services"; break;
          case 5: rtnval_cat = "Luxury"; break;
          default: break;
        }
        break;
      case 2:
        rtnval = "Clothing";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Underwear"; break;
          case 3: rtnval_cat = "Outerwear"; break;
          case 4: rtnval_cat = "Footwear"; break;
          case 5: rtnval_cat = "Adverse Weather"; break;
          default: break;
        }
        break;
      case 3:
        rtnval = "Container";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Pouch"; break;
          case 3: rtnval_cat = "Luggage"; break;
          case 4: rtnval_cat = "Backpack"; break;
          case 5: rtnval_cat = "Military Equipment System"; break;
          default: break;
        }
        break;
      case 4:
        rtnval = "Outdoors Gear";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Tent"; break;
          case 3: rtnval_cat = "Bedding"; break;
          case 4: rtnval_cat = "Foodstuff"; break;
          case 5: rtnval_cat = "Gear"; break;
          case 6: rtnval_cat = "Survival"; break;
          default: break;
        }
        break;
      case 5:
        rtnval = "Tack";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Small Animal"; break;
          case 3: rtnval_cat = "Beast of Burden"; break;
          default: break;
        }
        break;
      case 6:
        rtnval = "Extreme Environment";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Harsh Climate"; break;
          case 3: rtnval_cat = "Rough Terrain"; break;
          case 4: rtnval_cat = "Hostile Atmosphere"; break;
          case 5: rtnval_cat = "Aquatic"; break;
          case 6: rtnval_cat = "Emergency Egress"; break;
          default: break;
        }
        break;
      case 7:
        rtnval = "Specialized Gear";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Communications"; break;
          case 3: rtnval_cat = "Navigation"; break;
          case 4: rtnval_cat = "Optical"; break;
          case 5: rtnval_cat = "Instrument"; break;
          case 6: rtnval_cat = "Security"; break;
          default: break;
        }
        break;
      case 8:
        rtnval = "Tactical Gear";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Energy Pack"; break;
          default: break;
        }
        break;
      case 9:
        rtnval = "Instruments & Toolkits";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          default: break;
        }
        break;
      case 10:
        rtnval = "Armaments";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          case 2: rtnval_cat = "Archaic Defenses"; break;
          case 3: rtnval_cat = "Shields"; break;
          case 4: rtnval_cat = "Screens"; break;
          case 5: rtnval_cat = "Armor"; break;
          case 6: rtnval_cat = "Archaic Weapons & Ammo"; break;
          case 7: rtnval_cat = "Melee Weapons & Ammo"; break;
          case 8: rtnval_cat = "Photon Weapons & Ammo"; break;
          case 9: rtnval_cat = "Isotope Weapons & Ammo"; break;
          case 10: rtnval_cat = "Sonic Weapons & Ammo"; break;
          case 11: rtnval_cat = "Electric Weapons & Ammo"; break;
          case 12: rtnval_cat = "Microwave Weapons & Ammo"; break;
          case 13: rtnval_cat = "Ion Weapons & Ammo"; break;
          case 14: rtnval_cat = "Thermal Weapons & Ammo"; break;
          case 15: rtnval_cat = "Bullet Weapons & Ammo"; break;
          case 16: rtnval_cat = "Scatter Weapons & Ammo"; break;
          case 17: rtnval_cat = "Pneumatic Weapons & Ammo"; break;
          case 18: rtnval_cat = "Accelerator Weapons & Ammo"; break;
          case 19: rtnval_cat = "Gyrojet Weapons & Ammo"; break;
          case 20: rtnval_cat = "Rocket Weapons & Ammo"; break;
          case 21: rtnval_cat = "Fluid Weapons & Ammo"; break;
          case 22: rtnval_cat = "Napalm Weapons & Ammo"; break;
          case 23: rtnval_cat = "Plasma Weapons & Ammo"; break;
          case 24: rtnval_cat = "Grenades & Explosives"; break;
          default: break;
        }
        break;
      case 11:
        rtnval = "Prefabricated Equipment";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          default: break;
        }
        break;
      case 12:
        rtnval = "Cybernetics";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          default: break;
        }
        break;
      case 13:
        rtnval = "Bioware";
        switch(subcat) {
          case 1: rtnval_cat = "General"; break;
          default: break;
        }
        break;
      default:
        break;
    }

    if(rtnval_cat) rtnval += " > " + rtnval_cat;
    return rtnval;

  }

  public getRefItems(
    type:  string,          //  "group" or "detail"
    cat: string,            //  one of the accordion categories
    iden: string            //  one of queries (group) or the index (detail)
  ): void {
    const fid = id + "getRefItems(): ";

    this.univUtilRefService.getRefItems(type, cat, iden).subscribe(
      data => {
        this.type = data.result.shift();
        if(this.type.type == "group") {
          this.ref = data.result;
        } else if(this.type.type == "detail") {

          let titleContents = "None";
          let bodyContents = "None";

          if(this.type.cat == "abil") {

            titleContents = data.result[0].name;
            bodyContents = 
              "<p><b>Prerequisite:</b> " + data.result[0].catAttr + 
              " " + data.result[0].minAttr + "+</p>" +
              "<p><b>Experience:</b> " + data.result[0].xpCost + " XPs</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "attr") {

            //  Note: Abbreviation converted to lower case, because it is printed 
            //  in small caps.
            titleContents = 
              data.result[0].name + " (" + 
              data.result[0].abv.toLowerCase() + ")";
            bodyContents = 
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "apti") {

            titleContents = data.result[0].name;
            bodyContents =
              "<p><b>Modifier:</b> " + data.result[0].mod + "</p>" +
              "<p><b>Prerequisites:</b> " + data.result[0].prereq + "</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>" +
              "<p><b>Equipment:</b> " + data.result[0].equip + "</p>" +
              "<p><b>Execution Time:</b> " + data.result[0].extime + "</p>";

          } else if(this.type.cat == "lang") {

            let langType = "";
            if((data.result[0].isSpoken == true)&&
              (data.result[0].isWritten == true)&&
              (data.result[0].isSigned == false)) {
              langType = "Written & Spoken";
            } else if((data.result[0].isSpoken == true)&&
              (data.result[0].isWritten == false)&&
              (data.result[0].isSigned == false)) {
              langType = "Spoken Only";
            } else if((data.result[0].isSpoken == false)&&
              (data.result[0].isWritten == true)&&
              (data.result[0].isSigned == false)) {
              langType = "Written Only";
            } else if((data.result[0].isSpoken == false)&&
              (data.result[0].isWritten == false)&&
              (data.result[0].isSigned == true)) {
              langType = "Signed Only";
            } else  {
              langType = "Other";
            }

            titleContents = data.result[0].name;
            bodyContents =
              "<p><b>Type:</b> " + langType + "</p>" +
              "<p><b>Origin:</b> " + data.result[0].origin + "</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "equi") {

            let equiCat = this.getEquipmentCategory(data.result[0].cat,
              data.result[0].subcat);

            titleContents = data.result[0].name;
            bodyContents =
              "<p><b>Category:</b> " + equiCat + "</p>" +
              "<p><b>Mass/Length/Volume:</b> " + data.result[0].mass +
              "<b>" + data.result[0].len + "</b>" +
              data.result[0].vol + "</p>" +
              "<p><b>Durability:</b> " + 
              this.titleCase(data.result[0].dur) + "</p>";
            if(data.result[0].lgl) bodyContents +=
              "<p><b>Legal Code:</b> " + data.result[0].lgl + "</p>";
            bodyContents +=
              "<p><b>Description:</b> " + data.result[0].desc + "</p>" +
              "<p><b>Cost:</b> " + data.result[0].cost + " scrips</p>";

              /* MWX -- Append to bodyContents based on evaluation 
                 of booleans isCarry, isWeap, isDef, isRes.  */

          } else {

            titleContents = "Error";
            bodyContents = "<p>Data not recognized</p>";

          }

          //  Send a message to the modal
          const message:Message<any> = {
            recipientIds: ["mwxModal"],
            payload: { 
              title: titleContents,
              type: "INFO",
              body: bodyContents
            },
            groupId: this.msgq.groupId
          };
          this.msgq.msgqConnection.post(message);

        } else {
          console.log("ERROR: type is unknown");
        }
        this.chngDetRef.detectChanges();
      },
      err => {
        console.log(fid + "ERROR: " + err);
      }
    );
  }
}

