const id = "mwx-pvgd//mwx-sign/";

import { Component, OnInit, Input } from '@angular/core';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageBus, Connection } from 'ngx-message-bus';
import { faSignInAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';

@Component({
  selector: 'app-mwx-sign',
  templateUrl: './mwx-sign.component.html'
})
export class MwxSignComponent implements OnInit {
  @Input('message') message: string;
  @Input('messageType') messageType: string;

  bsModalRef: BsModalRef;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxSign",             //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    private mwxAuthService: MwxAuthService,
    private modalService: BsModalService,
    private messageBus: MessageBus) { 
    const fid = id + "constructor(): ";
  }

  ngOnInit(): void {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";

    //  Pop up the modal window.
    this.openModal();
  }

  openModal() {
    const fid = id + "openModal(): ";

    this.bsModalRef = this.modalService.show(MwxSignContent);
  }

}

@Component({
  selector: 'app-mwx-sign-content',
  templateUrl: './mwx-sign.content.html',
  styleUrls: ['./mwx-sign.content.css']
})
export class MwxSignContent implements OnInit {

  faSignInAlt = faSignInAlt;
  faWindowClose = faWindowClose;

  constructor(public bsModalRef: BsModalRef) {
    const fid = id + "[content]constructor(): ";
  }

  ngOnInit() {
    const fid = id + "[content]ngOnInit(): ";

    this.bsModalRef.setClass('modal-md');
  }

}

