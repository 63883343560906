const id = "mwx-pvgd//pvgd-list-char-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable , throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PvgdListCharService {

  constructor(private http: HttpClient) { }

  public getCharList() : Observable<any> {

    const fid = id + "getCharList(): ";

    let uri = environment.MARKWILX_API_URI;
    uri += '/api/pvgd-char';

    let tempinfo = this.http.get(uri).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

    return(tempinfo);

    //return [
    //  {"name": "Athos", "gamesys": "Orphans' Realm", "cpgn": "Musketeers"},
    //  {"name": "Porthos", "gamesys": "Orphans' Realm", "cpgn": "Musketeers"},
    //  {"name": "Aramis", "gamesys": "Orphans' Realm", "cpgn": "Musketeers"},
    //  {"name": "D'Artagnan", "gamesys": "Orphans' Realm",
    //    "cpgn": "Musketeers"}
    //];

  }

  private extractData(res: any) {
    let char = res;
    return char;
  }

  private handleError(err: any) {
    const fid = id + "handleError(): ";

    console.log(fid + "ERROR: " + err);
    return throwError(err);
  }
}
