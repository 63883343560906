<div class="mwx-app">

  <!--  HEADER - Used for the persistent logos and links.  -->
  <div class="mwx-navbar">
    <a href="#" (click)="selectHomeTab();" 
      style="margin:0; border:0; padding:0;">
  
      <!--  Tower Ravens Logo -->
      <img class="pvgd-btn-img" alt="Proving Ground" 
        src="assets/logos/TRZ_Banner-White.png">

      <!--  Proving Ground Logo -- >
      <img class="pvgd-btn-img" alt="Proving Ground" 
        src="assets/logos/pvgd-WordMk-Long-DkBkg.png">
      -->

      <!--  MarkWilx Logo  -- >
      <img class="mwx-btn-img" alt="MarkWilx" 
        src="assets/logos/MarkWilx-FlatLogo-WhiteBlue.png">
      -->

    </a>
    <div class="mwx-navbar-rgt">
      <div *ngIf="!mwxAuthService.isAuthenticated()">
        <span style="margin: 2px 5px 2px 5px;">
          <app-mwx-signup></app-mwx-signup>
        </span>
        <span style="margin: 2px 5px 2px 0px;">
          <app-mwx-signin></app-mwx-signin>
        </span>
      </div>
      <div *ngIf="mwxAuthService.isAuthenticated()">
        <div class="mwx-navbar-drop">
          <button class="mwx-btn-inv btn btn-dark btn-sm"
            style="margin: 2px 5px 2px 5px;">
            <fa-icon [icon]="faUserCircle"></fa-icon>
	    <span *ngIf="screenName"> {{ screenName }}</span> 
	    <span *ngIf="!screenName"> Loading ...</span> 
            <fa-icon [icon]="faCaretDown"></fa-icon>
          </button>
          <div class="mwx-drop-content">
            <a href="#" (click)="requestTab('mwxProfile')">User Profile</a>
            <hr>
            <a href="#" (click)="requestTab('pvgdListChar')">Characters</a>
            <a href="#" (click)="requestTab('pvgdListCpgn')">Campaigns</a>
            <a href="#" (click)="requestTab('pvgdListGame')">Games</a>
            <hr>
            <a href="#" (click)="signout();">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  MODAL - For popup messages from a variety of modals.  -->
  <app-mwx-modal></app-mwx-modal>
  <app-mwx-sign></app-mwx-sign>

  <!--  BANNER - Normally empty, used for imporant notices.  -->
  <div class="mwx-banner">
    <app-mwx-banner></app-mwx-banner>
  </div>

  <!--  TABPANE - The main section for the tabbed content.  -->
  <div class="mwx-tabpane">
    <app-mwx-tabpane></app-mwx-tabpane>
  </div>

  <!--  FOOTER - Contains logos and the copyright statement.  -->
  <div class="mwx-footer">
    <app-mwx-footer></app-mwx-footer>
  </div>

</div>
