<div class="container-fluid mwx-body-frame">

  <!--  At Bootstrap breakpoints lg and xl there will be two 
        columns contained within one row.  At Bootstrap
        breakpoints xs, sm, and md the two columns will have 
        a div breakpoint between the two rows.  -->

  <!--  This first in the container  -->
  <div class="row">

    <!--  Left column, containing the main menu  -->

    <!--  Bootstrap breakpoints xl, lg  -->
    <div class="d-none d-lg-block col-4 mwx-body-col-left" >

      <!--  Accordion menu  -->
      <p>
      <app-pvgd-menu></app-pvgd-menu>
      </p>

    </div>

    <!--  Bootstrap breakpoints xs, sm, md  -->
    <div class="d-block d-lg-none col mwx-body-col-left" >

      <!--  Accordion menu  -->
      <p>
      <app-pvgd-menu></app-pvgd-menu>
      </p>

    </div>

    <!--  Column stacking on Bootstrap breakpoints xs, sm, and md.  -->
    <div class="d-lg-none w-100"></div>

    <!--  Right column; currently reserved for ads.  -->
    <div class="col mwx-body-col-right"> 

      <!--  Walk Away ad.  -->
      <div>
        <img class="ad" src="/assets/ads/walk_away.jpg">
      </div>

    </div>

  </div>
</div>

