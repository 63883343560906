<button id="mwxSigninBtnModal" class="mwx-btn-inv btn btn-dark btn-sm"
  (click)="open(mwx_signin_template)">
  <fa-icon [icon]="faSignInAlt"></fa-icon>
  Sign In</button>

<!--  Modal: begin  -->
<ng-template #mwx_signin_template>

  <form [formGroup]="signinForm" (ngSubmit)="onSignin()">
    <div class="modal-header">
      <h4 class="modal-title" id="signinModalLabel">
        <fa-icon [icon]="faSignInAlt"></fa-icon>
        Sign In</h4>
      <button type="button" class="close" aria-label="Close"
        id="mwxSigninBtnClose" (click)="modalRef.hide()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group" align="center">
        <input id="mwxSigninEmail" class="modal-input"
          type="email" placeholder="Username or Email"
          formControlName="usernameOrEmail">
        <br>
        <input id="mwxSigninPassword" class="modal-input"
          type="password" placeholder="Password"
          formControlName="password">
          <p><small><i>Privacy information on the About page.
            </i></small></p>
          <!-- MWX -- Password recovery in development -->
          <p>To recover a password: enter the 'Username or
            Email' field, leave the 'Password' field blank, and
            click the 'Recover Password' button. [In 
            development] </p>
      </div>
    </div>

    <div class="modal-footer">
      <button id="mwxSigninBtnCancel"
        type="button"
        class="btn btn-secondary btn-sm"
        (click)="modalRef.hide()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
        Cancel</button> 
      <button id="mwxSigninBtnRecover" type="button"
        class="btn btn-secondary btn-sm"
        [disabled]="signinForm.valid">
        <fa-icon [icon]="faKey"></fa-icon>
        Recover Password</button>
      <button id="mwxSigninBtnSubmit" type="submit"
        class="btn btn-primary btn-dark btn-sm btn-default"
        [disabled]="!signinForm.valid">
        <fa-icon [icon]="faSignInAlt"></fa-icon>
        Sign In</button>
    </div>
  </form>

</ng-template>
<!--  Modal: end  -->

