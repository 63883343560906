const id = "mwx-pvgd//pvgd-xmpl-game";

import { Component, OnInit } from '@angular/core';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TreeNgxModule, TreeMode } from 'tree-ngx';

import { faDragon } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pvgd-xmpl-game',
  templateUrl: './pvgd-xmpl-game.component.html',
  styleUrls: ['./pvgd-xmpl-game.component.css']
})
export class PvgdXmplGameComponent implements OnInit {

  faDragon = faDragon;

  options = {
    mode: TreeMode.NoSelect,
    checkboxes: false,
    alwaysEmitSelected: false
  };

  vttRes = [
    {id: 0, isClpsd: true, resName: "Introduction", content: [
        { name: "Adventure Background", type: "Handout" }
      ] },
    {id: 1, isClpsd: true, resName: "Chapter 1", content: [
        { name: "Sothus Abbey Ruins", type: "Tabletop" },
        { name: "Forten Village", type: "Tabletop" },
        { name: "Catacomb Runes", type: "Handout" }
      ] },
    {id: 2, isClpsd: true, resName: "Chapter 2", content: [
        { name: "Lost Shrine of Mirethus", type: "Tabletop" },
        { name: "Shrine Runes", type: "Handout" },
        { name: "Secret Plans", type: "Handout" }
      ] },
    {id: 3, isClpsd: true, resName: "Chapter 3", content: null },
    {id: 4, isClpsd: true, resName: "Chapter 4", content: null },
    {id: 99, isClpsd: true, resName: "Resolution", content: [
        { name: "Ancient Map", type: "Handout" }
      ] }
  ];

  nodeItems = [
    { name: "X'ample's X'hibition", expanded: false, children: [
      { name: 'PC Tokens', expanded: false, children: [
        { name: 'Noble Gruzzen' },
        { name: 'Landrae' },
        { name: 'Mordith' },
        { name: 'Finieous Fingers' }]},
      { name: 'Maps', expanded: false, children: [
        { name: 'Inn of the Last Home' }]},
      ]
    },
    { name: "Orphans' Realm: Ancient Secrets", expanded: true, children: [
      { name: 'Tabletops', expanded: true, children: [
        { name: 'Sothus Abbey Ruins' },
        { name: 'Forten Village' },
        { name: 'Lost Shrine of Mirethus' }]},
      { name: 'Handouts', expanded: true, children: [
        { name: 'Adventure Background' },
        { name: 'Catacomb Runes' },
        { name: 'Shrine Runes' },
        { name: 'Secret Plans' },
        { name: 'Ancient Map' }]},
      { name: 'Tokens', expanded: false, children: [
        { name: 'Ellina' }]},
      ]
    },
    { name: 'Fantasy Scoundrel Box', expanded: false, children: [
      { name: 'Maps' },
      { name: 'Tokens' }
      ]},
    { name: 'Fantasy Town Box', expanded: false, children: [
      { name: 'Maps' },
      { name: 'Tokens' }
      ]},
    { name: 'Fantasy Forest Box', expanded: false, children: [
      { name: 'Maps' },
      { name: 'Tokens' }
      ]},
    { name: 'Fantasy Hills Box', expanded: false, children: [
      { name: 'Maps' },
      { name: 'Tokens' }
      ]},
    { name: 'Fantasy Cave Box', expanded: false, children: [
      { name: 'Maps' },
      { name: 'Tokens' }
      ]},
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
