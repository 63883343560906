
<div>

  <div class="hdrRow" align="center">
    <div class="col rounded hdrCol" align="center">
      <img class="hdrImg" src="/assets/logos/pvgd-WordMk-Long-DkBkg.png">
    </div>
  </div>

  <div class="row">

    <div class="col" align="left">

      <h2 align="center">Proving Ground Info</h2>
      <hr>

      <p><b>Proving Gound</b> is a virtual table top system for playing
      board games, war games, role-playing games online.  It provides a 
      light interface for maximum useability and optional toolsets for 
      enhanced gaming.
      </p>

    </div>

    <div class="col" align="left">
      <h2 align="center">Tools</h2>
      <hr>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('pvgdXmplChar')">
        <fa-icon [icon]="faUserShield"></fa-icon>
        X'ample the Redd</button>
        Open a tab displaying an example of a character tab.  A 
        character can be associated with a game system and will display
        the character's system traits as well.
      </p>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('pvgdXmplCpgn')">
        <fa-icon [icon]="faUsers"></fa-icon>
        X'ample & X'emplars</button>
        Open a tab displaying an example of a campaign tab.  This
        example displays the game master's view of the tab.  A player
        will have certain fields restricted from view.
      </p>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('pvgdXmplGame')">
        <fa-icon [icon]="faDragon"></fa-icon>
        X'ample's X'hibition</button>
        Open a tab displaying an example of a game box.  A game box
        provides resources that may be imported into a campaign.  A
        campaign can link several game boxes and draw from all their
        resources.
      </p>

      <p><button class="btn btn-dark btn-sm tool"
        (click)="requestTab('pvgdXmplVtt')">
        <fa-icon [icon]="faMapMarkedAlt"></fa-icon>
        Example Tabletop</button>
        Open a tab displaying an example virtual tabletop.  At present,
        this is a proof-of-concept and does not have the additional
        features that are planned for incorportaion.
      </p>

    </div>

    <div class="col" align="left">

      <h2 align="center">Products</h2>
      <hr>

      <!--
      <div *ngFor="let item of items">
        <h5>{{item.name}}</h5>
        <img class='prodImg' src='{{item.thumbURI}}'>
        <p>{{item.desc}}</p>
        <p align="right">
          <span class='{{item.priceSymbol}}'></span>
          <b>{{item.priceText}}</b> &nbsp;
          <button class="btn btn-dark btn-sm prod" type="submit"
            (click)="clickURI(item)" >
            <span class='{{item.btnSymbol}}'></span>
            {{item.btnText}}
          </button>
        </p>
        <hr>
      </div>
      -->

    </div>

  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>"Orphans' Realm, The Introductory Fantasy RPG" and the spear
        & banner logo are trademarks owned by Tower Ravens LLC</small>
    </div>
  </div>

</div>

