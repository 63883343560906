const id = "mwx-pvgd//tyro-util-ref/";

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TyroUtilRefService } from './tyro-util-ref.service';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';

@Component({
  selector: 'app-tyro-util-ref',
  templateUrl: './tyro-util-ref.component.html',
  styleUrls: ['./tyro-util-ref.component.css']
})
export class TyroUtilRefComponent implements OnInit {
 
  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "tyroUtilRef",         //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  public ref: any[] = [];
  private type: any = null;

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private messageBus: MessageBus,
    private tyroUtilRefService: TyroUtilRefService
  ) { }

  ngOnInit() {
    const fid = id + "ngOnInit() ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  private getEquipmentCategory(cat) {
    let rtnval = "Unidentified";

    switch(cat) {
      case '01': rtnval = "Personal"; break;
      case '02': rtnval = "Clothing"; break;
      case '03': rtnval = "Electronics"; break;
      case '04': rtnval = "Tool Kits"; break;
      case '05': rtnval = "Gear"; break;
      case '06': rtnval = "Foodstuff"; break;
      case '07': rtnval = "Space Gear"; break;
      case '08': rtnval = "Weapons"; break;
      default: break;
    }

    return rtnval;
  }

  public getRefItems(
    type:  string,          //  "group" or "detail"
    cat: string,            //  one of the accordion categories
    iden: string            //  one of queries (group) or the index (detail)
  ): void {
    const fid = id + "getRefItems(): ";

    this.tyroUtilRefService.getRefItems(type, cat, iden).subscribe(
      data => {
        this.type = data.result.shift();
        if(this.type.type == "group") {
          this.ref = data.result;
        } else if(this.type.type == "detail") {

          let titleContents = "None";
          let bodyContents = "None";

          if(this.type.cat == "fami") {

            titleContents = data.result[0].name;
            bodyContents =
              "<p><b>Parents:</b> " + data.result[0].parents + "</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "attr") {

            //  Abbreviation is in lower case, because it is rendered
	    //  in small caps.
            titleContents =
              data.result[0].name + " (" +
              data.result[0].abv.toLowerCase() + ")";
            bodyContents = 
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "pers") {

            titleContents = data.result[0].name;
            bodyContents = 
              "<p><b>Type:</b> " + data.result[0].skillTypes + "</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "skil") {

            titleContents = data.result[0].name;
            bodyContents = 
              "<p><b>Category:</b> " + data.result[0].cat + "</p>" +
              "<p><b>Prerequisites:</b> " + data.result[0].prereq + "</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>";

          } else if(this.type.cat == "equi") {

            titleContents = data.result[0].name;
            bodyContents = 
              "<p><b>Category:</b> " + 
                this.getEquipmentCategory(data.result[0].cat) + "</p>" +
              "<p><b>Description:</b> " + data.result[0].desc + "</p>" +
              "<p><b>Cost:</b> " + data.result[0].cost + "</p>";
          }

          //  Send a message to the modal
          const message:Message<any> = {
            recipientIds: ["mwxModal"],
            payload: {
              title: titleContents,
              type: "INFO",
              body: bodyContents
            },
            groupId: this.msgq.groupId
          };
          this.msgq.msgqConnection.post(message);

        } else {
          console.log(fid + "ERROR: reference item type is unknown");
        }
        this.chngDetRef.detectChanges();
      },
      err => {
        console.log(fid + "ERROR: " + err);
      }
    );
  }

}

