<div>

  <div class="hdrRow" align="center">
    <div class="col rounded hdrCol" align="center">
      <img class="hdrImg" src="/assets/logos/OrphansRealmLogo-Light.svg">
    </div>
  </div>

  <div class="row">

    <div class="col" align="left">

      <h2 align="center">Orphans' Realm Info</h2>
      <hr>

      <p><b>Orhpans' Realm, The Introductory Fantasy RPG</b> is a 
      rules-light fantasy adventure role playing game for
      families, new gamers, and casual gaming from Tower Ravens LLC.  
      It shares a simplified version of the Tyro Universe game system.  
      For more information about Orphans' Realm, questions can be 
      directed to our Facebook page.</p>

    </div>

    <div class="col" align="left">
      <h2 align="center">Tools</h2>
      <hr>

      <p>In development.</p>
    </div>

    <div class="col" align="left">

      <h2 align="center">Products</h2>
      <hr>

      <div *ngFor="let item of items">
        <h5>{{item.name}}</h5>
        <img class='prodImg' src='{{item.thumbURI}}'>
        <p>{{item.desc}}</p>
        <p align="right">
          <span class='{{item.priceSymbol}}'></span>
          <b>{{item.priceText}}</b> &nbsp;
          <button class="btn btn-dark btn-sm prod" type="submit"
            (click)="clickURI(item)" >
            <span class='{{item.btnSymbol}}'></span>
            {{item.btnText}}
          </button>
        </p>
        <hr>
      </div>

    </div>

  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>"Orphans' Realm, The Introductory Fantasy RPG" and the spear
        & banner logo are trademarks owned by Tower Ravens LLC</small>
    </div>
  </div>

</div>

