const id = "mwx-pvgd//mwx-signup/";

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, 
  Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { faUserPlus, faWindowClose, faCheckSquare } 
  from '@fortawesome/free-solid-svg-icons';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service'; 
import { NewUser } from '@app/mwx-auth/mwx-user.model';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';

@Component({
  selector: 'app-mwx-signup',
  templateUrl: './mwx-signup.component.html',
  styleUrls: ['./mwx-signup.component.css']
})
export class MwxSignupComponent implements OnInit {

  modalRef: BsModalRef;

  messageTxtIdBkgdColor = "#0EB521";

  /*  Font Icons */
  faUserPlus = faUserPlus;
  faWindowClose = faWindowClose;
  faCheckSquare = faCheckSquare;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxSignup",           //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  private signupForm: UntypedFormGroup;
  private signupLoading = false;
  private emailValidating = false;
  private signupResult: any;

  private closeResult: string;

  private message_txt: string = 'All fields required.';

  constructor(
    private mwxAuthService: MwxAuthService,
    private modalService: BsModalService,
    private messageBus: MessageBus,
    private fb: UntypedFormBuilder) { 
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);

    //  Create form.
    this.createForm();
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  open(template: TemplateRef<any>) {
    const fid = id + "open(): ";
    this.modalRef = this.modalService.show(template);
  }

  private createForm(): void {
    const fid = id + "createForm(): ";

    this.signupForm = this.fb.group({
      username: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new UntypedFormControl('', {
        validators: [Validators.required, Validators.minLength(6)]
      }),
      code: new UntypedFormControl('', {
        validators: [Validators.required, 
          Validators.pattern("[0-9A-F]{4}[-][0-9A-F]{4}")]
      })
    });
  }

  private clearAndSetInput(newUser): void {
    const fid = id + "clearAndSetInput(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxSignin"],
      payload: { cmd: 0x1101, data: newUser },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

    this.signupForm.get("username").setValue("");
    this.signupForm.get("email").setValue("");
    this.signupForm.get("password").setValue("");
    this.signupForm.get("code").setValue("");
  }

  private onSignup(): void {
    const fid = id + "onSignup(): ";

    if (this.signupForm.valid) {
      this.signupLoading = true;
      const { username, email, password, code } = this.signupForm.value;
      const encpass = "";
      const newUser: NewUser = {
        username,
        email,
        password,
        encpass,
        code
      };

      this.messageTxtIdBkgdColor = "#0EB521";
      this.message_txt = 'All fields required.';

      this.mwxAuthService.signup(newUser).subscribe(
        result => {
          this.signupLoading = false;
	  //  MWX - This needs to be substituted with proper data binding.
          document.getElementById("mwxSignupBtnClose").click();
          //  Clear text fields in signup, and copy them to login.
          this.clearAndSetInput(newUser);
        },
        err => {
          /*  Error checking includes:
              -- confirm email address is not existing (INVALID_EMAIL)
              -- confirm playtester code is valid (INVALID_PTEST)
              -- confirm playtester code is not in-use (INVALID_PTEST)
              -- confirm screen name is not existing (INVALID_SNAME)
              -- confirm password is at least 6 characters (INVALID_PWORD)
          */
          console.log(fid + "ERROR: " + JSON.stringify(err));
          this.signupLoading = false;
          this.message_txt = "Error (" + err.status +"): " + 
            err.statusText + " - " + err.error.message;
          this.messageTxtIdBkgdColor = "#FC4142";
        }
      ); 

    } else {
      console.log(fid + "ERROR: signupForm invalid");
    }
  }
}

