const id = "mwx-pvgd//pvgd-tools/";

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { faToolbox, faUser, faUserShield, faUsers, faUserPlus, faPencilAlt, 
  faCog, faDragon, faSkull, faMapMarkedAlt, faWindowClose, faCheckSquare }
  from '@fortawesome/free-solid-svg-icons';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-pvgd-tools',
  templateUrl: './pvgd-tools.component.html',
  styleUrls: ['./pvgd-tools.component.css']
})
export class PvgdToolsComponent implements OnInit {

  /*  Font Icons  */
  faUserShield = faUserShield;
  faUsers = faUsers;
  faDragon = faDragon;
  faMapMarkedAlt = faMapMarkedAlt;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdTools",           //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private messageBus: MessageBus
  ) { }

  ngOnInit(): void {
    //  Connect message queue.
    this.msgq.msgqConnection =
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

}

