const id = "mwx-pvgd//mwx-profile/";

import { Component, OnInit } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { jwtDecode } from 'jwt-decode';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { MwxJWT } from '@app/mwx-auth/mwx-jwt.model';
import { faAddressCard, faFilePdf, faClipboard
} from '@fortawesome/free-solid-svg-icons';
import { faPatreon
} from '@fortawesome/free-brands-svg-icons';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-mwx-profile',
  templateUrl: './mwx-profile.component.html',
  styleUrls: ['./mwx-profile.component.css']
})
export class MwxProfileComponent implements OnInit {

  /*  Font Icons  */
  faAddressCard = faAddressCard;
  faFilePdf = faFilePdf;
  faClipboard = faClipboard;
  faPatreon = faPatreon;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxProfile",          //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  /*  User Information  */
  screenName = "Loading ...";
  email = "Loading ...";
  lastLogin  = "Loading ...";

  constructor(
    private mwxAuthService: MwxAuthService,
    private messageBus: MessageBus) { 
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection =
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";
    const curTime = new Date().getTime() / 1000;
    const userdata = jwtDecode<MwxJWT>(localStorage.getItem('auth_token'));

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

    //  Check web token for expiration.
    if((userdata.exp - curTime) > 0) {
      //console.log(fid + "Web token has not expired.");
    } else {
      //console.log(fid + "Web token is expired; LOGOUT.");
      this.signout();
    }

    let d = new Date();
    const monthAbv = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    if(userdata.username) this.screenName = userdata.username;
    else this.screenName = "Unknown";
    if(userdata.email) this.email = userdata.email;
    else this.email = "Unknown";
    if(userdata.lastLogin) {
      d.setTime(Date.parse(userdata.lastLogin));
      this.lastLogin = 
        d.getUTCFullYear() + " " +
        monthAbv[d.getUTCMonth()] + " " +
        d.getUTCDate() + " - " +
        (d.getUTCHours() < 10? '0' : '') + d.getUTCHours() + ":" +
        (d.getUTCMinutes() < 10? '0' : '') + d.getUTCMinutes() + 
        " UTC";
    } else this.lastLogin = "Unknown";
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  private signout(): void {
    const fid = id + "signout(): ";
    console.log(fid);

    /*  MWX -- Close all tabs.  */

    this.mwxAuthService.signout();
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  public clickURI(uri) {
    const fid = id + "clickURI(): ";
    window.open(uri);
  }

  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

  }

}

