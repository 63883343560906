const id = "mwx-pvgd//nl2brPipe/";

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class nl2brPipe implements PipeTransform {

  transform(value: string): string {
    const fid = id + "transform(): ";
    return value.replace(/\n/g, '<br/>');
  }

}

