<div (click)="$event.preventDefault()" >

  <tabset #tabpaneTabs type="pills" >

    <!--  This loops through all the dynamics tabs (dynTabs) and 
          displays them.  Varying properties are set in the HTML
          tab tag.  -->
    <tab *ngFor="let tabz of dynTabs"
      [active]="tabz.active"
      (select)="tabz.active = true"
      (selectTab)="clickTab(tabz)"
      (deselect)="tabz.active = false"
      [disabled]="tabz.disabled"
      [removable]="tabz.removable"
      (removed)="closeTab(tabz)"
      customClass="customClass">

      <!--  In the tab heading, set the tab icon from the 
            tabz.icon property and the tab title from the 
            tabz.title property.  -->
      <div [ngSwitch]="tabz?.icon" >
        <ng-template tabHeading >
          <span style="color: {{tabz?.color}}">
            <fa-icon *ngSwitchCase="'faHome'" [icon]="faHome"></fa-icon> 
	    <fa-icon *ngSwitchCase="'faClipboard'" 
              [icon]="faClipboard"></fa-icon>
            <fa-icon *ngSwitchCase="'faQuestionCircle'" 
              [icon]="faQuestionCircle"></fa-icon> 
	    <fa-icon *ngSwitchCase="'faUser'" [icon]="faUser"></fa-icon>
	    <fa-icon *ngSwitchCase="'faUsers'" [icon]="faUsers"></fa-icon>
	    <fa-icon *ngSwitchCase="'faToolbox'" [icon]="faToolbox"></fa-icon>
	    <fa-icon *ngSwitchCase="'faBook'" [icon]="faBook"></fa-icon>
	    <fa-icon *ngSwitchCase="'faTh'" [icon]="faTh"></fa-icon>
	    <fa-icon *ngSwitchCase="'faClock'" [icon]="faClock"></fa-icon>
	    <fa-icon *ngSwitchCase="'faSun'" [icon]="faSun"></fa-icon>
	    <fa-icon *ngSwitchCase="'faWindowClose'" 
              [icon]="faWindowClose"></fa-icon>
	    <fa-icon *ngSwitchCase="'faDragon'" [icon]="faDragon"></fa-icon>
	    <fa-icon *ngSwitchCase="'faUserShield'" 
              [icon]="faUserShield"></fa-icon>
	    <fa-icon *ngSwitchCase="'faUserCircle'" 
              [icon]="faUserCircle"></fa-icon>
	    <fa-icon *ngSwitchCase="'faMapMarkedAlt'" 
              [icon]="faMapMarkedAlt"></fa-icon>
	    <fa-icon *ngSwitchCase="'faDiceD20'" [icon]="faDiceD20"></fa-icon>
	    <fa-icon *ngSwitchCase="'faDraftingCompass'" 
              [icon]="faDraftingCompass"></fa-icon>
	    <fa-icon *ngSwitchCase="'faCalendarDays'"
              [icon]="faCalendarDays"></fa-icon>
          </span>
          {{ tabz.title }} 
        </ng-template>
      </div>

      <!--  The body of the tab is selected by referencing the
            tabz.iden property.  -->
      <div [ngSwitch]="tabz.iden">
        <app-mwx-main *ngSwitchCase="'mwxHome'">
        </app-mwx-main>
        <app-mwx-about *ngSwitchCase="'mwxAbout'">
        </app-mwx-about>
        <app-mwx-development *ngSwitchCase="'mwxDevelopment'">
        </app-mwx-development>
        <app-mwx-profile *ngSwitchCase="'mwxProfile'">
        </app-mwx-profile>
        <app-davn-pastpro *ngSwitchCase="'davnPastpro'">
        </app-davn-pastpro>
        <app-pvgd-main *ngSwitchCase="'pvgdMain'">
        </app-pvgd-main>
        <app-pvgd-list-char *ngSwitchCase="'pvgdListChar'">
        </app-pvgd-list-char>
        <app-pvgd-list-cpgn *ngSwitchCase="'pvgdListCpgn'">
        </app-pvgd-list-cpgn>
        <app-pvgd-list-game *ngSwitchCase="'pvgdListGame'">
        </app-pvgd-list-game>
        <app-pvgd-char *ngSwitchCase="'pvgdChar'"
          [idxValue] = "tabz.idx">
        </app-pvgd-char>
        <app-pvgd-cpgn *ngSwitchCase="'pvgdCpgn'"
          [idxValue] = "tabz.idx">
        </app-pvgd-cpgn>
        <app-pvgd-xmpl-char *ngSwitchCase="'pvgdXmplChar'">
        </app-pvgd-xmpl-char>
        <app-pvgd-xmpl-cpgn *ngSwitchCase="'pvgdXmplCpgn'">
        </app-pvgd-xmpl-cpgn>
        <app-pvgd-xmpl-game *ngSwitchCase="'pvgdXmplGame'">
        </app-pvgd-xmpl-game>
        <app-pvgd-xmpl-vtt *ngSwitchCase="'pvgdXmplVtt'">
        </app-pvgd-xmpl-vtt>
        <app-pvgd-tools *ngSwitchCase="'pvgdTools'">
        </app-pvgd-tools>
        <app-univ-tools *ngSwitchCase="'univTools'">
        </app-univ-tools>
        <app-tyro-tools *ngSwitchCase="'tyroTools'">
        </app-tyro-tools>
        <app-orph-tools *ngSwitchCase="'orphTools'">
        </app-orph-tools>
        <app-sfrn-tools *ngSwitchCase="'sfrnTools'">
        </app-sfrn-tools>
        <app-univ-util-ref *ngSwitchCase="'univUtilRef'">
        </app-univ-util-ref>
        <app-univ-util-perftbl *ngSwitchCase="'univUtilPerftbl'">
        </app-univ-util-perftbl>
        <app-univ-util-timeline *ngSwitchCase="'univUtilTimeline'">
        </app-univ-util-timeline>
	<app-univ-util-starcht *ngSwitchCase="'univUtilStarcht'">
        </app-univ-util-starcht>
        <app-tyro-util-ref *ngSwitchCase="'tyroUtilRef'">
        </app-tyro-util-ref>
      </div>
    </tab>
  </tabset>

