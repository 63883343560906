<div>

  <div class="hdrRow" align="center">
    <div class="rounded hdrCol" align="center">
      <img class="hdrImg" src="/assets/logos/TyroUnivLogo-Light.svg">
    </div>
  </div>

  <div class="row">
    <div class="col" align="left">
      <div class="container" align="center">

        <accordion class="accrdn" [closeOthers]='true' [isAnimated]='true'>
          <accordion-group id="X1XX">
            <span accordion-heading>Families</span>
            <p><a href='#' (click)="getRefItems('group', 'fami', 
              'Alph')">Alphabetical</a></p>
          </accordion-group>

          <accordion-group id="X2XX">
            <span accordion-heading>Attributes</span>
            <p><a href='#' (click)="getRefItems('group', 'attr', 
              'Alph')">Categorical</a></p>
          </accordion-group>

          <accordion-group id="X3XX">
            <span accordion-heading>Personalities</span>
            <p><a href='#' (click)="getRefItems('group', 'pers', 
              'Alph')">Alphabetical</a></p>
          </accordion-group>

          <accordion-group id="X4XX">
            <span accordion-heading>Skills</span>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'CatCult')">Categorical: Cultural</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'CatMart')">Categorical: Martial</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'CatOutd')">Categorical: Outdoors</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'CatScie')">Categorical: Science</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'CatTech')">Categorical: Technology</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'AlphAI')">Alphabetical: A-I</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'AlphJR')">Alphabetical: J-R</a></p>
            <p><a href='#' (click)="getRefItems('group', 'skil',
              'AlphSZ')">Alphabetical: S-Z</a></p>
          </accordion-group>

          <accordion-group id="X5XX">
            <span accordion-heading>Equipment</span>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat01')">Categorical: Personal</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat02')">Categorical: Clothing</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat03')">Categorical: Electronics</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat04')">Categorical: Tool Kits</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat05')">Categorical: Gear</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat06')">Categorical: Foodstuff</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat07')">Categorical: Space Tech</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat08')">Categorical: Weapons</a></p>
          </accordion-group>

        </accordion>
      </div>

    </div>

    <div class="col" >

      <div class="container" align="center">
        <table class="tbl table-borderless" align="center">
          <thead>
            <tr>
              <th class="tbl-th">Item</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let refitem of ref" >
              <td class="tbl-td">
                <a href='#' (click)='getRefItems("detail", 
                  type.cat, refitem._id)'> {{ refitem.name }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>&copy;Tower Ravens, LLC; all rights reserved.  "Universe, 
        The Sci-Fi RPG" and the galaxy logo are registered 
        trademarks owned by Tower Ravens LLC</small>
    </div>
  </div>

</div>

