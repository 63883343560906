//  MarkWilx, Universe Operative Online Module

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//  Forms
import { FormsModule, ReactiveFormsModule, FormControl, FormGroup 
  } from '@angular/forms';

//  div resize
//import { AngularResizeEventModule } from 'angular-resize-event';

//  ngx-Bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';

//  Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

//  Three.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import SpriteText from 'three-spritetext';

//  MarkWilx
import { UnivToolsComponent } from './univ-tools/univ-tools.component';
import { UnivUtilPerftblComponent } from './univ-util-perftbl/univ-util-perftbl.component';
import { UnivUtilRefComponent } from './univ-util-ref/univ-util-ref.component';
import { UnivUtilTimelineComponent } from './univ-util-timeline/univ-util-timeline.component';
import { UnivUtilStarchtComponent } from './univ-util-starcht/univ-util-starcht.component';

@NgModule({
  declarations: [
    UnivToolsComponent,
    UnivUtilPerftblComponent,
    UnivUtilRefComponent,
    UnivUtilTimelineComponent,
    UnivUtilStarchtComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //AngularResizeEventModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    FontAwesomeModule
  ],
  exports: [
    UnivToolsComponent,
    UnivUtilPerftblComponent,
    UnivUtilRefComponent,
    UnivUtilTimelineComponent,
    UnivUtilStarchtComponent
  ]
})

export class UnivModule { }

