const id = "mwx-pvgd//mwx-tabpane/";

import { Component, AfterContentInit, ViewContainerRef, 
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { TabsetComponent, TabsModule } from 'ngx-bootstrap/tabs';
import { MessageBus, Connection } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTab } from '@app/mwx-data/mwx-tab';
import { faHome, faUser, faUsers, faQuestionCircle, faToolbox,
  faBook, faTh, faClock, faSun, faWindowClose, faClipboard, 
  faDragon, faUserCircle, faUserShield, faMapMarkedAlt, faDiceD20,
  faDraftingCompass, faCalendarDays
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mwx-tabpane',
  templateUrl: './mwx-tabpane.component.html',
  styleUrls: ['./mwx-tabpane.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwxTabpaneComponent implements AfterContentInit {

  //  Font Awesome Icons
  faHome = faHome;
  faUser = faUser;
  faUsers = faUsers;
  faQuestionCircle = faQuestionCircle;
  faToolbox = faToolbox;
  faBook = faBook;
  faTh = faTh; 
  faClock = faClock;
  faSun = faSun;
  faWindowClose = faWindowClose;
  faClipboard = faClipboard;
  faDragon = faDragon;
  faUserCircle = faUserCircle;
  faUserShield = faUserShield;
  faMapMarkedAlt = faMapMarkedAlt;
  faDiceD20 = faDiceD20;
  faDraftingCompass = faDraftingCompass;
  faCalendarDays = faCalendarDays;

  //  Array containing all the dynamic tabs.
  dynTabs: MwxTab[] = [ ];

  //  Array of the tab select order: length = current tab, 0 = last
  tabOrder: number[] = [ ];

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxTabpane",           //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private messageBus: MessageBus) {
    const fid = id + "constructor(): ";
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = this.messageBus.connect(this.msgq.msgqId, 
      this.msgq.procId);

  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    let newTab: MwxTab;

    //  Add tabs that appear on initialization, and default to
    //  the first one.

    //  MWX - I don't think idx is still needed here.
    newTab = { iden: "mwxHome", idx: 0x1001, title: "Home", icon: "faHome",
      removable: false, disabled: false, active: true };
    this.addTab(newTab);

    newTab = { iden: "davnPastpro", title: "Past is Prologue", 
      icon: "faCalendarDays",
      removable: true, disabled: false, active: true };
    this.addTab(newTab);

    this.selectTab(0);

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(pkt: any) {
    const fid = id + "handleMessage(): ";

    const cmd = pkt.cmd;
    const data = pkt.data;
    const title = (pkt.hdg) ? pkt.hdg.title : null;
    const idx = (pkt.hdg) ? pkt.hdg.idx : null;

    let newTab: MwxTab;

    if(cmd == 0x0003 ) {                   //  Signout
      this.closeAllTabs();
    } else if(cmd == 0x0004 ) {            //  Signin
    } else if(cmd == 0x1011) {             //  Open Tab
      if(data == 'mwxDevelopment') {
        newTab = { iden: data, title: "Development", 
          icon: "faClipboard", 
          removable: true, disabled: false, active: true };
      } else if(data == 'mwxAbout') {
        newTab = { iden: data, title: "About", 
          icon: "faQuestionCircle", 
          removable: true, disabled: false, active: true };
      } else if(data == 'mwxProfile') {
        newTab = { iden: data, title: "User Profile", 
          icon: "faUserCircle", 
          removable: true, disabled: false, active: true };
      } else if(data == 'davnPastpro') {
        newTab = { iden: data, title: "Past is Prologue", 
          icon: "faCalendarDays", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdMain') {
        newTab = { iden: data, title: "Proving Ground", 
          icon: "faDraftingCompass",
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdListChar') {
        newTab = { iden: data, title: "Characters", 
          icon: "faUserShield", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdListCpgn') {
        newTab = { iden: data, title: "Campaigns", icon: "faUsers", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdListGame') {
        newTab = { iden: data, title: "Game Chest", icon: "faDragon", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdChar') {
        newTab = { iden: data, idx: idx, title: title, 
          icon: "faUserShield", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdCpgn') {
        newTab = { iden: data, idx: idx, title: title, 
          icon: "faUsers", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdXmplChar') {
        newTab = { iden: data, title: "X'ample the Redd", 
          icon: "faUserShield", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdXmplCpgn') {
        newTab = { iden: data, title: "X'ample & X'emplars", 
          icon: "faUsers", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdXmplGame') {
        newTab = { iden: data, title: "X'ample's X'hibition", 
          icon: "faDragon", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdXmplVtt') {
        newTab = { iden: data, title: "Sothus Abbey Ruins", 
        icon: "faMapMarkedAlt", 
          removable: true, disabled: false, active: true };
      } else if(data == 'pvgdTools') {
        newTab = { iden: data, title: "Proving Ground Tools", 
          icon: "faToolbox", 
          removable: true, disabled: false, active: true };
      } else if(data == 'univTools') {
        newTab = { iden: data, title: "Universe Tools", 
        icon: "faToolbox", 
          removable: true, disabled: false, active: true };
      } else if(data == 'tyroTools') {
        newTab = { iden: data, title: "Tyro Universe Tools", 
          icon: "faToolbox", 
          removable: true, disabled: false, active: true };
      } else if(data == 'orphTools') {
        newTab = { iden: data, title: "Orphans' Realm Tools", 
        icon: "faToolbox", 
          removable: true, disabled: false, active: true };
      } else if(data == 'sfrnTools') {
        newTab = { iden: data, title: "Star Frontiers Tools", 
          icon: "faToolbox", 
          removable: true, disabled: false, active: true };
      } else if(data == 'univUtilRef') {
        newTab = { iden: data, title: "Universe Reference", 
          icon: "faBook", 
          removable: true, disabled: false, active: true };
      } else if(data == 'univUtilPerftbl') {
        newTab = { iden: data, title: "Performance Table", 
          icon: "faTh", 
          removable: true, disabled: false, active: true };
      } else if(data == 'univUtilTimeline') {
        newTab = { iden: data, title: "Universe Timeline", 
          icon: "faClock", 
          removable: true, disabled: false, active: true };
      } else if(data == 'univUtilStarcht') {
        newTab = { iden: data, title: "Universe Star Chart", 
          icon: "faSun", 
          removable: true, disabled: false, active: true };
      } else if(data == 'tyroUtilRef') {
        newTab = { iden: data, title: "Tyro Universe Reference", 
          icon: "faBook", 
          removable: true, disabled: false, active: true };
      } else {
        console.log(fid + "ERROR: tab identifier not recognized: " + data);
      }
      this.addTab(newTab);
    } else if(cmd == 0x1012) {       //  Close Tab
      console.log(fid + "ERROR: close tab not recognized.");
    } else if(cmd == 0x1013) {       //  Close All Tabs
      this.closeAllTabs();
    } else if(cmd == 0x1014) {       //  Select Home Tab
      this.selectTab(0);
    } else {
      console.log(fid + "ERROR: command not recognized:" + cmd);
    }
    //  Detect changes.  This is necessary, because of the change
    //  detection strategy.
    this.changeDetector.detectChanges();
  }

  private getTabIdx(tabData): number {
    const fid = id + "getTabIdx(): ";

    //  Set the return value to indicate the tab not found.
    let rtnval: number = -1;

    for(let ct = 0; ct < this.dynTabs.length; ct++) {
      if((this.dynTabs[ct].iden == tabData.iden) &&
        (this.dynTabs[ct].idx == tabData.idx)) {
        rtnval = ct; 
      }
    }

    //console.log(fid + rtnval);

    return rtnval;

  }

  clickTab(tabData) {
    const fid = id + "clickTab(): ";
    //console.log(fid + JSON.stringify(tabData));

    //  Find the current tab identifier.
    const tabId = this.getTabIdx(tabData);
    console.log(fid + tabId);
    console.log(fid + "tabOrder before: " + this.tabOrder.join(" "));

    if(this.dynTabs.length != this.tabOrder.length) {
      console.log(fid + "TAB DELETED");
    }

    //  Find the index of the tab in the tabOrder array.
    let tabOrderIdx = -1;
    for(let ct = 0; ct < this.tabOrder.length; ct++) {
      if(this.tabOrder[ct] == tabId) {
        tabOrderIdx = ct;
        break;
      }
    }

    //  Move the selected tab to the end of the list.
    this.tabOrder.splice(tabOrderIdx, 1);
    this.tabOrder.push(tabId);

    //console.log(fid + this.tabOrder.join(" "));

    console.log(fid + "tabOrder after: " + this.tabOrder.join(" "));

  }

  selectTab(tabId: number) {
    const fid = id + "selectTab(): ";

    console.log(fid + "tabId: " + tabId);
    console.log(fid + "tabOrder before: " + this.tabOrder.join(" "));

    //  Deactivate all tabs, except the current tab.
    for(let ct = 0; ct < this.dynTabs.length; ++ct) {
      if (ct != tabId) {
        let curTab: MwxTab = this.dynTabs[ct];
        if(curTab) curTab.active = false;
      } else {
      }
    }

    //  Activate the current tab.
    let curTab: MwxTab = this.dynTabs[tabId];
    if(curTab) curTab.active = true;

    console.log(fid + "tabOrder after: " + this.tabOrder.join(" "));

  }

  closeTab(tabData: MwxTab) {
    const fid = id + "closeTab(): ";

    console.log(fid + "dynTabs before splice: " + this.dynTabs.length);
    console.log(fid + "tabOrder before splice: " + this.tabOrder.length);
    console.log(fid + "tabOrder before: " + this.tabOrder.join(" "));

    //  MWX -- It appears the tab is removed from the DOM but remains
    //  MWX -- in the dynTabs array.  So the first thing we should do 
    //  MWX -- is splice it out of the dynTabs array.

    let dynTabsIdx = this.getTabIdx(tabData);

    //  Remove the tab from the dynamic tabs array.
    if(dynTabsIdx != -1) this.dynTabs.splice(dynTabsIdx, 1);


    //  Find the removed tab in the tabOrder array, and splice 
    //  it out.
    let tabOrderRemoveIdx = -1;
    for(let ct = 0; ct < this.tabOrder.length; ct++) {
      if(this.tabOrder[ct] == dynTabsIdx) {
        tabOrderRemoveIdx = ct;
      }
    }
    if(tabOrderRemoveIdx != 1) {
      this.tabOrder.splice(tabOrderRemoveIdx, 1);
    }

    //  Scan the tabOrder array; if any value is greater than
    //  the value of the removed item, reduce its value by 1.
    if(tabOrderRemoveIdx != 1) {
      for(let ct = 0; ct < this.tabOrder.length; ct++) {
        if(this.tabOrder[ct] > dynTabsIdx) {
          this.tabOrder[ct] = this.tabOrder[ct] - 1;
        }
      }
      
    }

    console.log(fid + "dynTabs after splice: " + this.dynTabs.length);
    console.log(fid + "tabOrder after splice: " + this.tabOrder.length);

    console.log(fid + "tabOrder after: " + this.tabOrder.join(" "));

    this.selectTab(this.tabOrder[this.tabOrder.length -1]);


/*
    if(dynTabsIdx != -1) {

      //  Find the index of the tab in the tabOrder array.
      let tabOrderIdx = -1;
      for(let ct = 0; ct < this.tabOrder.length; ct++) {
        if(this.tabOrder[ct] == dynTabsIdx) {
          tabOrderIdx = ct;
          break;
        }
      }

      //console.log(fid + "Remove tab #" + dynTabsIdx);


      //console.log(fid + "Remove order #" + tabOrderIdx);

      //  If the index is valid, remove the tab from the ordered index.
      if(tabOrderIdx >= 0) { 
        //console.log(fid + "splice: " + tabOrderIdx + ", value: " 
         // + this.tabOrder[tabOrderIdx]);
        //this.tabOrder.splice(tabOrderIdx, 1);
      }

      //  Reduce the tabOrder value by one for any values greater
      //  than the tab index being removed.
      for(let ct = 0; ct < this.tabOrder.length; ct++) {
        if(this.tabOrder[ct] > dynTabsIdx) this.tabOrder[ct]--;
      }

      //console.log(fid + this.tabOrder.join(" "));

      //  Select the tab which is last in tabOrder.
      //console.log(fid + "select tab: " 
        //+ this.tabOrder[this.tabOrder.length-1]);

      this.selectTab(this.tabOrder[this.tabOrder.length-1]);
    } 
*/
  }

  closeAllTabs() {
    const fid = id + "closeAllTabs(): ";

    //  Scan through all the tabs, closing all but the first
    //  one, Home.
    while(this.dynTabs.length != 1) {
      this.closeTab(this.dynTabs[this.dynTabs.length-1]);
    }

    //  Select the Home tab, the only one not closed.
    this.selectTab(0);

  }

  //  Add a new tab to the tabpane, or select it if it already exists.
  addTab(newTab: MwxTab) {
    const fid = id + "addTab(): ";

    let isCreated = this.getTabIdx(newTab);

    //  If the tab has already been created (not -1), then select the 
    //  existing tab; otherwise, create it.
    //  MWX - Creating the mwx-about tab generates an error related 
    //  MWX - to the value of "active"
    if(isCreated != -1) {
      this.selectTab(isCreated);
    } else {
      const newTabIdx = this.dynTabs.length;
      this.dynTabs.push(newTab);
      this.selectTab(newTabIdx);
      this.tabOrder.push(newTabIdx);
    }
  }
}

