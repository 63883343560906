const id = "mwx-pvgd//univ//univ-util-starcht/";

import { Component, OnInit, ElementRef, ViewChild, 
  AfterViewInit, Input } from '@angular/core';
//import { ResizedEvent } from 'angular-resize-event';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import SpriteText from 'three-spritetext';

@Component({
  selector: 'app-univ-util-starcht',
  templateUrl: './univ-util-starcht.component.html',
  styleUrls: ['./univ-util-starcht.component.css']
})
export class UnivUtilStarchtComponent implements OnInit {

  @ViewChild('canvas')
  private canvasRef: ElementRef;

  //  Canvas Dimension Constraints
  div_width: number = 0;
  div_height: number = 0;

  //  Cube Properties
  //  MWX -- The cube (monolith) and all its functionality can be deleted.
  @Input() private rotationSpeedX: number = 0.001;
  @Input() private rotationSpeedY: number = 0.01;

  //  Stage Properties
  @Input() private cameraX: number = 5200;
  @Input() private cameraY: number = 5200;
  @Input() private cameraZ: number = 5200;
  @Input() private fieldOfView: number = 1;
  @Input('nearClipping') private nearClippingPlane: number = 5;
  @Input('farClipping') private farClippingPlane: number = 20000;

  //  Helper Properties (Private Properties)
  private camera!: THREE.PerspectiveCamera;
  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  //  Cube Construction
  private loader = new THREE.TextureLoader();
  private geometry = new THREE.BoxGeometry(0.4, 0.9, 0.1);
  private cube_material = new THREE.MeshBasicMaterial({ 
    color: 0x222222 });
  private cube: THREE.Mesh = new THREE.Mesh(this.geometry, this.cube_material);

  //  Plane Grids
  private xyGrid = new THREE.GridHelper(100, 10);
  private xzGrid = new THREE.GridHelper(100, 10);
  private yzGrid = new THREE.GridHelper(100, 10);

  //  Render, Scene, and Controls
  private renderer!: THREE.WebGLRenderer;
  private scene!: THREE.Scene;
  private controls!: OrbitControls;

  constructor() { 
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.createScene();
    this.startRenderingLoop();
  }

  /*  Create the Scene  */
  private createScene() {
    //  Scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);

    //  Adjust and add the Monolith
    this.cube.translateX(50);
    this.cube.translateY(50);
    this.cube.translateZ(50);
    this.scene.add(this.cube);

    //  Adjust and add the three plane grids
    this.xyGrid.rotateX(Math.PI/2);
    this.xyGrid.translateX(50);
    this.xyGrid.translateZ(-50);
    this.scene.add(this.xyGrid);

    this.xzGrid.translateX(50);
    this.xzGrid.translateZ(50);
    this.scene.add(this.xzGrid);

    this.yzGrid.rotateZ(Math.PI/2);
    this.yzGrid.translateX(50);
    this.yzGrid.translateZ(50);
    this.scene.add(this.yzGrid);

    //  CEPTANIN LEAGUE (yellow)
    //  25540240 - Tarnogin
    this.createStar("Tarnogin", 1340.4, 1215.7, 186.1, 0xEFE145 );
    //  25541300 - Ceptanin
    this.createStar("Ceptanin", 1341.0, 1203.1, 184.1, 0xEFE145 );
    //  25540345 - Quanpal
    this.createStar("Quanpal", 1344.5, 1231.2, 183.4, 0xEFE145 );
    //  25541334 - Yaltar
    this.createStar("Yaltar", 1344.4, 1202.9, 172.9, 0xEFE145 );
    //  25540266 - Blahncal
    this.createStar("Blahncal", 1332.4, 1210.2, 169.8, 0xEFE145 );
    //  25541167 - Edgonus
    this.createStar("Edgonus", 1346.8, 1221.4, 163.6, 0xEFE145 );
    //  25541129 - Planthal
    this.createStar("Planthal", 1316.0, 1206.2, 177.2, 0xEFE145 );
    //  25541321 - Tsaval
    this.createStar("Tsaval", 1314.8, 1214.9, 169.7, 0xEFE145 );
    //  25540817 - Yidvin
    this.createStar("Yidvin", 1335.1, 1217.9, 153.9, 0xEFE145 );
    //  25541762 - Winpegit
    this.createStar("Winpegit", 1315.6, 1209.9, 165.3, 0xEFE145 );
    //  25541474 - Edgonus
    this.createStar("Edgonus", 1356.8, 1214.3, 177.5, 0xEFE145 );
    //  25542009 - Whanstal
    this.createStar("Whanstal", 1340.3, 1237.5, 148.7, 0xEFE145 );
    //  35540082 - Algenon
    this.createStar("Algenon", 1338.9, 1220.6, 206.5, 0xEFE145 );

    //  DOMINION OF TREDURIK (orange)
    //  25541568 - Naakdus
    this.createStar("Naakdus", 1366.7, 1282.4, 141.9, 0xFFC09B );
    //  25541011 - Bronsar
    this.createStar("Bronsar", 1369.0, 1283.0, 145.5, 0xFFC09B );
    //  25540970 - Telles
    this.createStar("Telles", 1363.2, 1273.1, 138.7, 0xFFC09B );
    //  25541068 - Neptal'yee
    this.createStar("Neptal'yee", 1355.9, 1285.2, 133.5, 0xFFC09B );
    //  25540083 - Johalitz
    this.createStar("Johalitz", 1345.6, 1271.8, 138.9, 0xFFC09B );
    //  25540047 - Renteki
    this.createStar("Renteki", 1343.9, 1276.0, 135.9, 0xFFC09B );
    //  XXXXXXXX - Heborgon, Mententalus
    //  XXXXXXXX - Vengorus, Lebawus

    //  SUPREMACY OF BHELGARD (purple)
    //  25541674 - Jacinalath
    this.createStar("Jacinalath", 1323.2, 1266.9, 148.8, 0xF0B2FF );
    //  25541346 - Blanotius
    this.createStar("Blanotius", 1325.0, 1273.2, 158.8, 0xF0B2FF );
    //  25540844 - Wendaak
    this.createStar("Wendaak", 1329.9, 1282.6, 153.3, 0xF0B2FF );
    //  25540425 - Fulnazital
    this.createStar("Fulnazital", 1318.0, 1278.0, 140.3, 0xF0B2FF );

    //  GRAND CONSORTIUM
    //  25540068 - Cholwegren
    this.createStar("Cholwegren", 1392.0, 1256.0, 146.8, 0xB9FF9B );
    //  25540180 - Dranogreth
    this.createStar("Dranogreth", 1389.2, 1247.6, 132.6, 0xB9FF9B );
    //  25540700 - Virelzen
    this.createStar("Virelzen", 1395.4, 1252.5, 165.2, 0xB9FF9B );
    //  25540270 - Ontagorath
    this.createStar("Ontagorath", 1391.7, 1266.5, 161.1, 0xB9FF9B );
    //  25540774 - Herplantus
    this.createStar("Herplantus", 1376.6, 1258.6, 145.4, 0xB9FF9B );
    //  25541086 - Stolahkana
    this.createStar("Stolahkana", 1374.1, 1261.9, 149.2, 0xB9FF9B );

    //  INDEPENDENTS
    //  25540307 - Thuwasik
    this.createStar("Thuwasik", 1349.8, 1249.5, 144.7, 0x9BE7FF );
    //  25540555 - Kalaat
    this.createStar("Kalaat", 1333.9, 1244.9, 142.7, 0x9BE7FF );
    //  XXXXXXXX - Orgabanep, Sepei

    //  Camera
    let aspectRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    )
    this.camera.up.set(0, 0, 1);
    this.camera.position.x = this.cameraX;
    this.camera.position.y = this.cameraY;
    this.camera.position.z = this.cameraZ;
  }

  /*  Resize Event Handler.  */
/*  MWX - This was commented out to avoid using the ResizedEvent, which was not
    MWX - compatible with Angular 15 and prevented building the Docker container. 
  onResized(event: ResizedEvent) {
    //  Proportion kept to 16:9 (same as widescreen display)
    this.div_width = event.newRect.width;
    this.div_height = this.div_width * 9.0 / 16.0;

    //  MWX -- If included, the following lines produce this error:
    //  MWX -- ResizeObserver loop completed with undelivered notifications.
    //  MWX -- See: https://github.com/juggle/resize-observer/blob/master/README.md#resize-loop-detection
    //this.camera.aspect = this.div_width / this.div_height;
    //this.camera.updateProjectionMatrix();
    //this.renderer.setSize( this.div_width, this.div_height );
  }
*/

  /*  Start the Rendering Loop  */
  private startRenderingLoop() {
    //  Renderer
    //  Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    //  Controls
    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.controls.listenToKeyEvents(window);

    let component: UnivUtilStarchtComponent = this;
    (function render() {
      requestAnimationFrame(render);
      component.animateCube();
      component.renderer.render(component.scene, component.camera);
    }());
  }

  private getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  /*  Animate the Cube  */
  private animateCube() {
    this.cube.rotation.x += this.rotationSpeedX;
    this.cube.rotation.y += this.rotationSpeedY;
  }

  createStar(name, x, y, z, color) {
    const fid = id + "createStar(): ";

    const adjX = -1300.0;
    const adjY = -1200.0;
    const adjZ = -100.0;

    //  Sphere, representing the star.
    const sphGeometry = new THREE.SphereGeometry( 0.5, 16, 8);
    const sphMaterial = new THREE.MeshBasicMaterial({color: color});
    const sphere = new THREE.Mesh(sphGeometry, sphMaterial);
    sphere.translateX(x + adjX);
    sphere.translateY(y + adjY);
    sphere.translateZ(z + adjZ);
    this.scene.add(sphere);

    //  Line, from star to xy plane to show z axis to user.
    const lineMaterial = new THREE.LineBasicMaterial({color: 0x555555});
    const linePoints: THREE.Vector3 = [
      new THREE.Vector3(x+adjX, y+adjY, z+adjZ),
      new THREE.Vector3(x+adjX, y+adjY, 0)];
    const lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints);
    const dropLine = new THREE.Line(lineGeometry, lineMaterial);
    this.scene.add(dropLine);

    //  Name, a sprite label.
    const nameText = new SpriteText(name, 1, 'darkgrey');
    nameText['position']['x'] = x + adjX;
    nameText['position']['y'] = y + adjY;
    nameText['position']['z'] = z + adjZ + 1.2;
    this.scene.add(nameText);
  }
  
}

