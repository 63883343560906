const id = "mwx-pvgd//app/";

import { Component, OnInit } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { faHome, faDiceD20, faUserCircle, faCaretDown, faUser, 
  faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { jwtDecode } from'jwt-decode';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { MwxSignComponent } from '@app/mwx-sign/mwx-sign.component';
import { MwxSigninComponent } from '@app/mwx-signin/mwx-signin.component';
import { MwxSignupComponent } from '@app/mwx-signup/mwx-signup.component';
import { MwxJWT } from '@app/mwx-auth/mwx-jwt.model';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'markwilx';
  faHome = faHome;
  faDiceD20 = faDiceD20;
  faFacebookSquare = faFacebookSquare;
  faUserCircle = faUserCircle;
  faCaretDown = faCaretDown;
  faUser = faUser;
  faClipboard = faClipboard;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "app",                 //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  screenName = null;

  /*  MWX - I need to have a variable that detects changes in the login 
      MWX - and updates the screen name accordingly.  See this website:
      MWX - https://angular.io/api/core/OnChanges
  */

  constructor(
    private location: LocationStrategy,
    public mwxAuthService: MwxAuthService,
    private messageBus: MessageBus) {

    // This code disables the browser back button, an important 
    // feature of SPA (single page application) websites.
    history.pushState(null, null, window.location.href);  
      this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Connect to the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
    

    //  Check if an auth_token is defined and valid.  If valid,
    //  set the screen name.
    const jwt = this.getJWT();
    if(jwt) {
      this.screenName = jwt.username;
    } else {
      this.screenName = null;
    }
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(pkt:any) {
    const fid = id + "handleMesssage(): ";

    const cmd = pkt.cmd;
    const data = pkt.data;
    const idx = pkt.idx;

    if(cmd == 0x0004) {                   //  Signin
      this.screenName = this.getJWT().username;
    } else {
      console.log(fid + "ERROR: command not recognized: " + cmd);
    }

  }

  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message over the message queue.
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid,  hdg: hdg},
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

  }

  public selectHomeTab(): boolean {
    const fid = id + "selectHomeTab(): ";

    const homeTabId = 0;

    //  Send a message over the message queue.
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1014, data: homeTabId },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

    //  If this function does not return false, the href will be executed
    //  rather than the javascript.
    return(false);
  }

  private getJWT(): MwxJWT {
    const fid = id + "getJWT(): ";

    //  Get the current time in seconds.
    const currentTime = Math.floor(Date.now() / 1000);

    let jwt: MwxJWT = null;

    try {
      //  Get the current token and compare its expiration time to
      //  the current time; if expired, signout.
      jwt = jwtDecode<MwxJWT>(localStorage.getItem('auth_token'));
      if((jwt.exp - currentTime) < 0) this.signout();
    }
    catch(err) {
      jwt = null;
      //  Do not report the error if it is an InvalidTokenError, this
      //  simply means the user is not logged in.
      if(err != "InvalidTokenError: Invalid token specified") {
        console.log(fid + "ERROR: ('" + err + 
          "') no auth_token in localStorage");
      }
    };

    return(jwt);
  }

  public signout(): void {
    const fid = id + "signout(): ";
    this.mwxAuthService.signout();
    this.screenName = null;
  }

}

