const id = "mwx-pvgd//davn-pastpro/";

import { Component, OnInit } from '@angular/core';

import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-davn-pastpro',
  templateUrl: './davn-pastpro.component.html',
  styleUrls: ['./davn-pastpro.component.css']
})
export class DavnPastproComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
