const id = "mwx-pvgd//mwx-token-interceptor/";

import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, 
  HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { MwxAuthService } from './mwx-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MwxTokenInterceptorService implements HttpInterceptor {

  constructor( private mwxAuthService: MwxAuthService,
    private injector: Injector) { }

  intercept( 
    request: HttpRequest<any>, 
    next:  HttpHandler
  ): Observable<HttpEvent<any>> {

    const fid = id + "intercept(): ";
    //console.log(fid);

    request = request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${this.mwxAuthService.getAuthToken()}`
      }
    });
    return next.handle(request).pipe(tap(
      (response: HttpEvent<any>) => {},
      (err: any) => {
        if(err instanceof HttpErrorResponse) {
          this.mwxAuthService.signout();
        }
      }
    ));
  } 
}

