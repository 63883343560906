const id = "mwx-pvgd//pvgd-xmpl-vtt/";

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SVG, Svg, Container as SVGContainer, Element as SVGElement, 
  Image as SVGImage, Circle as SVGCircle, Line as SVGLine, Text as SVGText } 
  from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';
import '@svgdotjs/svg.draggable.js';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

import { PvgdToken } from '@app/mwx-data/pvgd-token';

@Component({
  selector: 'app-pvgd-xmpl-vtt',
  templateUrl: './pvgd-xmpl-vtt.component.html',
  styleUrls: ['./pvgd-xmpl-vtt.component.css']
})
export class PvgdXmplVttComponent implements OnInit {

  vtt: Svg|null = null; 
  testmap: SVGImage = null;
  testline: SVGLine = null;
  testlinex: number = null;
  testliney: number = null;
  testtext: SVGText = null;

  //  Font Awesome Variables
  faMapMarkedAlt = faMapMarkedAlt;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    const fid = id + "ngOnInit(): ";
  }

  ngAfterContentInit(): void {
    const fid = id + "ngAfterContentInit(): ";
  }

  ngAfterViewInit(): void {
    const fid = id + "ngAfterViewInit(): ";
    console.log(fid);

    this.vtt = SVG()
      .addTo('#vttSvg')
      .size('100%', '600px');
      //.panZoom({ zoomMin: 0.5, zoomMax: 20 });
    this.vtt.on('click', (e) => this.vttZoom(e));

    this.testmap = this.vtt.image('./assets/images/AbbeyOfTheGoldenLeaf.jpg');
    this.testmap.size(600,600).move(0,0);

    var token01: SVGCircle = this.vtt.circle(10).fill('#FF0000').move(450,580);
    token01.data('key', {id: 't01'} );
    token01.draggable();
    token01.on('mousedown', (e) => this.liftToken(e, token01));
    token01.on('mousemove', (e) => this.moveToken(e, token01));
    token01.on('mouseup', (e) => this.dropToken(e, token01));
    //token01.on('mousedown', function(evt) {
      //console.log(fid + "token01: mousedown; x: " + evt.clientX + " y: " + evt.clientY);
    //});
    //token01.on('mousedrag', function(evt) {
      //console.log(fid + "token01: mousedrag; x: " + evt.clientX + " y: " + evt.clientY);
    //});
    //token01.on('mouseup', function(evt) {
      //console.log(fid + "token01: mouseup; x: " + evt.clientX + " y: " + evt.clientY);
    //});

/*
    var token02 = this.vtt.circle(10).fill('#00FF00').move(420,575);
    token02.click(function() {
      console.log(fid + "token02: click");
    });
    token02.draggable();

    var token03 = this.vtt.circle(10).fill('#00FFFF').move(430,560);
    token03.click(function() {
      console.log(fid + "token03: click");
    });
    token03.draggable();

    var token04 = this.vtt.circle(10).fill('#FF00FF').move(410,550);
    token04.click(function() {
      console.log(fid + "token04: click");
    });
    token04.draggable();

    var token05 = this.vtt.circle(10).fill('#0000FF').move(450,555);
    token05.click(function() {
      console.log(fid + "token05: click");
    });
    token05.draggable();
*/

  }

  ngOnDestroy() {
  }

  addTests() {
    const fid = id + "addTests(): ";
    console.log(fid);
    var templine = this.vtt.line(455, 585, 355,385);
    templine.stroke( { color: '#FF0000', width: 1, linecap: 'round' } );
    var tempcirc = this.vtt.circle(10).fill('#FF0000').move(350,380);
  }

  vttZoom(evt) {
    const fid = id + "vttZoom(): ";
    console.log(fid, "x: " + evt.clientX + " y: " + evt.clientY);

    //this.vtt.zoom(1).animate().zoom(2, { x:100, y:100 });
    //this.vtt.zoom(1);
  }

  liftToken(evt, token) {
    const fid = id + "liftToken(): ";
    console.log(fid, "x: " + evt.clientX + " y: " + evt.clientY);

    this.testlinex = token.cx();
    this.testliney = token.cy();
    this.testline = this.vtt.line(this.testlinex, this.testliney, this.testlinex, this.testliney);
    this.testline.stroke( { color: '#FF0000', width: 1, linecap: 'round' } );

    this.testtext = this.vtt.plain("0").move(this.testlinex -10, this.testliney -10).font( { fill: '#FF0000', family: 'Helvetica' });

  }

  moveToken(evt, token) {
    const fid = id + "moveToken(): ";
    //console.log(fid, "x: " + evt.clientX + " y: " + evt.clientY);

    var length = 0;

    if(this.testline) {
      length = Math.sqrt( Math.pow(this.testlinex - token.cx(), 2) + Math.pow( this.testliney - token.cy(), 2) ) / 4 ;
      this.testline.plot(this.testlinex, this.testliney, token.cx(), token.cy());
      this.testtext.text( length.toFixed(0) );
      this.testtext.x(this.testline.cx() -10);
      this.testtext.y(this.testline.cy() -10);
    }
  }

  dropToken(evt, token)  {
    const fid = id + "dropToken(): ";
    console.log(fid, "x: " + evt.clientX + " y: " + evt.clientY);

    this.testline.plot(0,0,0,0);
    this.testline = null;

    this.testtext.text("");

  }

}
