<button id="mwxContactBtnModal" class="btn btn-dark btn-sm"
  (click)="open(mwx_contact_template)">
  <fa-icon [icon]="faEnvelope"></fa-icon>
  Contact</button>

<!-- Modal: begin -->
<ng-template #mwx_contact_template>

  <form [formGroup]="contactForm" (ngSubmit)="onContact()" >
    <div class="modal-header" >
      <h4 class="modal-title" id="contactModalLabel">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        Contact</h4>
      <button type="button" class="close" aria-label="Close"
        id="mwxContactBtnClose"
        (click)="modalRef.hide()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <div class="modal-body" >
        <div class="form-group" align="center">
          <div>
            <p id="message_txt_id"
              [style.background]="messageTxtIdBkgdColor">
              Your message will be answered by a person, not a bot.
              We endeavor to answer all questions, but cannot prevent
              our provider from  eliminating your message as spam.
            </p>
          </div>

          <input id="mwxContactEmail"
            class="modal-input input" type="email" style="width:363px"
            placeholder="Your reply-to email address"
            formControlName="email">
          <br>
          <textarea class="modal-input" rows="8" cols="35" 
            name="mwxContactMsg" formControlName="msg"
            placeholder="Comments and questions.">
          </textarea>
          <p><small><i>Privacy information at the About
            page.</i></small></p>
        </div>
    </div>

    <div class="modal-footer" >
      <button id="mwxContactBtnCancel"
        type="button"
        class="btn btn-secondary btn-sm"
        (click)="modalRef.hide()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
        Cancel</button>
      <button id="mwxContactBtnSubmit"
        class="btn btn-primary btn-dark btn-sm btn-default"
        [disabled]="!contactForm.valid" >
        <fa-icon [icon]="faCheckSquare"></fa-icon>
        Send</button>
    </div>
  </form>

</ng-template>
<!-- Modal: end -->

