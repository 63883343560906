<div class="mwx-text-box">
  <h3><fa-icon [icon]="faExclamationCircle"></fa-icon> Notices</h3>

  <ul>
    <li>The back-end API will enter a rest state after a period of disuse
      and may require up to 15 seconds to reawaken from the sleep state.
      Thus, you may see a 15 second delay when first signing in.</li>
    <li>During development, user accounts are not available to the public.
      Free user accounts will become available during beta test and, of
      course, when the website becomes operational.  To request a playtester
      code, to create an account during development, contact us on our 
      <a href="https://www.facebook.com/groups/52208442579/" target="_blank">
      Facebook</a> page.</li>
    <li>This website is in <b>development</b>.  It is not in beta test, nor
      alpha test, nor any other test.  It is in development.  This means 
      features are not guaranteed to work, nor continue working, as their
      capabilities are being developed.  During development <b>no assurance</b> 
      is given that data will be retained.  Store any important information
      on a location other than this website.  During development, not all
      security features are functional.  Aside from your email account, 
      <b>do not</b> store any private data on this website.  <b>Do not</b>
      use a password that you have used on another website.</li>
  </ul>
</div>

<div class="mwx-text-box">
  <h3>Technology</h3>
  <p>
    The staff of MarkWilx would like acknowledge and express their
    collective gratitude to the technology providers that have made this
    website possible.
  </p>

  <!--
    //  Open Source
    DarwinOS
    GNU
    Git
    Vim
    Kubernetes
    Docker
    Node.js
    Express
    Angular
    mongoDB
    HTML/CSS/JS
    TypeScript
    Bootstrap

    //  Services
    Bitbucket
    Digital Ocean
    name.com
    Let's Encrypt
    Swagger
    Meteor Modeler
    Slack
    Mongoose

    //  Deleted
    ERD+
    Robo3T
    Heroku
    mLab
  -->

  <div class="container">

    <div class="row">

      <div class="col">
        <img class="logo-tall"
          style="height:80px;"
          src="assets/logos/hexley_2k_vector.svg"
          alt="DarwinOS">
        <p><a href="#"
          onclick="window.open('https://opensource.apple.com')" 
          target="_blank">
          DarwinOS</a>, development platform.</p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/Heckert_GNU_white.svg"
          alt="GNU">
        <p><a href="#"
          onclick="window.open('https://www.gnu.org')" 
          target="_blank">
          GNU</a>, free operating system software.</p>
      </div>
  
      <div class="d-md-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/Git-Logo-2Color.svg"
          alt="Git">
        <p><a href="#"
          onclick="window.open('https://git-scm.com')" 
          target="_blank">
          Git</a>, version control system.</p>
      </div>
  
      <div class="d-sm-none d-md-block d-lg-none w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/Vimlogo.svg"
          alt="Vim">
        <p><a href="#"
          onclick="window.open('https://www.vim.org')" 
          target="_blank">
          Vim</a>, code editor.</p>
      </div>
  
      <div class="d-md-none d-lg-block w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/Kubernetes_logo_without_workmark.svg"
          alt="Kubernetes">
        <p><a href="#"
          onclick="window.open('https://kubernetes.io/')" 
          target="_blank">
          Kubernetes</a>, container orchestration and deployment system.</p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/Docker-logo.png"
          alt="Docker">
        <p><a href="#"
          onclick="window.open('https://www.docker.com/')" 
          target="_blank">
          Docker</a>, containerization system.</p>
      </div>
  
      <div class="d-lg-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/Node_js_logo.svg"
          alt="Node.js">
        <p><a href="#"
          onclick="window.open('https://nodejs.org')" 
          target="_blank">
          Node.js</a>, run-time environment.</p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/Expressjs.png"
          alt="Express">
        <p><a href="#"
          onclick="window.open('https://expressjs.com')" 
          target="_blank">
          Express</a>, web application framework.</p>
      </div>
  
      <div class="d-md-none d-lg-block w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/angular.svg"
          alt="Angular">
        <p><a href="#"
          onclick="window.open('https://angular.io')" 
          target="_blank">
          Angular</a>, web application platform.</p>
      </div>
  
      <div class="d-sm-none d-md-block d-lg-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/MongoDB_Gray_Logo_FullColor_RGB.svg"
          alt="MongoDB">
        <p><a href="#"
          onclick="window.open('https://www.mongodb.com')" 
          target="_blank">
          MongoDB</a>, database.</p>
      </div>
  
      <div class="d-md-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/html5-css-javascript-logos.png"
          alt="HTML5, CSS, JavaScript">
        <p>
          <a href="#"
            onclick="window.open('https://www.w3.org')" 
            target="_blank">HTML 5</a>
          markup language,
          <a href="#"
            onclick="window.open('https://www.w3.org')" 
            target="_blank">CSS 3</a> 
          style sheet, and 
          <a href="#"
            onclick="window.open('http://www.ecma-international.org')" 
            target="_blank">
            JavaScript</a> 
          script language.
        </p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/TypeScript_Logo.svg"
          alt="TypeScript">
        <p><a href="#"
          onclick="window.open('https://www.typescriptlang.org')" 
          target="_blank">
          TypeScript</a>, program language.</p>
      </div>
  
      <div class="w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/ngx-bootstrap-logo.svg"
          alt="Bootstrap">
        <p><a href="#"
          onclick="window.open('https://valor-software.com/ngx-bootstrap')" 
          target="_blank">
          NGX-Bootstrap</a>, front-end component library.</p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/Bitbucket-blue.svg"
          alt="Bitbucket">
        <p><a href="#"
          onclick="window.open('https://bitbucket.org')" 
          target="_blank">
          Bitbucket</a>, version control repository.</p>
      </div>
  
      <div class="d-md-none w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/DigitalOcean-logo.svg"
          alt="DigitalOcean">
        <p><a href="#"
          onclick="window.open('https://www.digitalocean.com/')" 
          target="_blank">
          DigitalOcean</a>, cloud infrastructure provider.</p>
      </div>
  
      <div class="d-sm-none d-md-block d-lg-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/Name-com_logo.svg"
          alt="name.com">
        <p><a href="#"
          onclick="window.open('https://name.com/')" 
          target="_blank">
          Name.com</a>, domain name registrar.</p>
      </div>
  
      <div class="d-md-none d-lg-block w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/letsencrypt-logo-horizontal.svg"
          alt="Let's Encrypt">
        <p><a href="#"
          onclick="window.open('https://letsencrypt.org')" 
          target="_blank">
          Let's Encrypt</a>, transport layer encryption.</p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/swagger_logo.svg"
          alt="Swagger Editor">
        <p><a href="#"
          onclick="window.open('https://editor.swagger.io')" 
          target="_blank">
          Swagger Editor</a>, API design tool.</p>
      </div>
  
      <div class="d-lg-none w-100"></div>
  
      <div class="col">
        <img class="logo-tall"
          src="assets/logos/meteor-modeler-colored-logo.svg"
          alt="Meteor Modeler">
        <p><a href="#" onclick="window.open('https://www.datensen.com/' + 
          'orm-design-tool/meteor-modeler-for-orm.html')" 
          target="_blank">
          Meteor Modeler</a>, database modeling tool.</p>
      </div>
  
      <div class="d-sm-none w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/slack_logo.svg"
          alt="Slack">
        <p><a href="#"
          onclick="window.open('https://slack.com')" 
          target="_blank">
          Slack</a>, collaboration hub for development.</p>
      </div>
  
      <div class="d-md-none d-lg-block w-100"></div>
  
      <div class="col">
        <img class="logo-wide"
          src="assets/logos/mongoose_logo.png"
          alt="Mongoose">
        <p><a href="#"
          onclick="window.open('https://mongoosejs.com')" 
          target="_blank">
          Mongoose</a>, object modeling for MongoDB.</p>
      </div>
  
      <!-- x  -->
  
      <!--
      <div class="col">
        <img class="logo-XXXX"
          src="XX.svg"
          alt="XX">
        <p><a href="#"
          onclick="window.open('https://XX.com')" 
          target="_blank">
          XX</a>, XX.</p>
      </div>
      -->
  
  
      <!--  Unused services
        <img class="logo-wide"
          src="assets/logos/erdplus-logo.png"
          alt="ERD+">
        <p><a href="#"
          onclick="window.open('https://erdplus.com')" 
          target="_blank">
          ERD+</a>, database modeling tool.</p>
  
        <img class="logo-XXXX"
          src="assets/logos/robo_3t.png"
          alt="Robo 3T">
        <p><a href="https://robomongo.org" target="_blank">
          Robo 3T</a>, GUI shell for MongoDB.</p>
  
        <img class="logo-tall"
          src="assets/logos/heroku-logotype-vertical-purple.svg"
          alt="Heroku">
        <p><a href="http://heroku.com/" target="_blank">
          Heroku</a>, platform-as-a-service.</p>
  
        <img class="logo-wide"
          src="assets/logos/MLab_company_logo.svg"
          alt="mLab">
        <p><a href="https://mlab.com/welcome/" target="_blank">
          mLab</a>, database-as-a-service.</p>
      -->
  
    </div>
  
  </div>
</div>

<div class="mwx-text-box">
  <h3><fa-icon [icon]="faTasks"></fa-icon> Next Steps</h3>

  <ul>

    <li>MarkWilx.com
    <ul>
      <li><s>Data messaging and dynamic tab infrastrucutres</s></li>
      <li><s>Create user schema in database</s></li>
      <li><s>Credentialing system (username, password, email addr.)</s></li>
      <li><s>Socket communications for campaign communications.</s></li>
      <li>Character creation and maintenance - beginning with Tyro 
        Universe, because of its simplicity.</li>
      <li>Campaign creation and maintenance.</li>
    </ul>
    <li>Game Development
    <ul>
      <li><s>Upload game system reference material to Operative Online on
        MarkWilx.com</s></li>
      <li>Universe &#8212; Revise the table of contents for the 
        <i>Universe, Master Rulebook, Volumes I thru III</i> and
        evaluate possibility of moving from Adobe InDesign to 
        Affinity Publisher</li>
      <li>Universe &#8212; Construction Rules</li>
      <li>Orphans' Realm &#8212; Rulebook introductory adventure</li>
      <li>Universe &#8212; Revisit timeline to correct inconsistencies and 
        develop detail</li>
    </ul>
    <li>Product Availability
    <ul>
      <li>Update profiles and ref screens, upload to DTRPG</li>
      <li>Review printed proof copies of rulebooks from DriveThruRPG.</li>
      <li>Get MarkWilx.com working then go live on DriveThruRPG.</li>
      <li>Complete <i>Maneuvers</i> editing and evaluate options for
        availability, such as Kindle, iBooks, and DriveThru Fiction</li>
    </ul>

  </ul>
</div>
