const id = "mwx-pvgd//pvgd-menu-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PvgdMenuService {

  constructor(private http: HttpClient) { }

  public getMenuList() : Observable<any> {

    const fid = id + "getMenuList(): ";

    let uri = environment.MARKWILX_API_URI;
    uri += '/api/pvgd-menu';

    let tempinfo = this.http.get(uri).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

    return(tempinfo);

  }

  private extractData(res: any) {
    let char = res;
    return char;
  }

  private handleError(err: any) {
    const fid = id + "handleError(): ";

    console.log(fid + "ERROR: " + err);
    return throwError(err);
  }

}

