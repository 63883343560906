<div class="mwx_foot">
  <a class="mwx_foot_lft">
    <img src="assets/logos/TRZ_Logo-WhiteRed.png" alt="Tower Ravens">
  </a>
  <span><small>&copy; 2019 Tower Ravens LLC and MarkWilx</small></span>
  <a class="mwx_foot_rgt">
    <img src="assets/logos/MarkWilx-Logo-WhiteBlue.png" alt="MarkWilx">
  </a>
</div>

