const id = "mwx-pvgd//mwx-development/";

import { Component, OnInit } from '@angular/core';
import { faExclamationCircle, faTasks } 
from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mwx-development',
  templateUrl: './mwx-development.component.html',
  styleUrls: ['./mwx-development.component.css']
})
export class MwxDevelopmentComponent implements OnInit {

  faExclamationCircle = faExclamationCircle;
  faTasks = faTasks;

  constructor() { }

  ngOnInit() {
  }

}
