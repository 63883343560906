import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { TyroToolsComponent } from './tyro-tools/tyro-tools.component';
import { TyroUtilRefComponent } from './tyro-util-ref/tyro-util-ref.component';

@NgModule({
  declarations: [
    TyroToolsComponent,
    TyroUtilRefComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    FontAwesomeModule
  ],
  exports: [
    TyroToolsComponent,
    TyroUtilRefComponent
  ]
})

export class TyroModule { }

