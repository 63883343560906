const id = "mwx-pvgd//pvgd-list-char/";

import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';
import { faUserShield, faPencilAlt, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { PvgdListCharService } from './pvgd-list-char.service';

interface Char {
  _id: string,
  name: string,
  gamesys: string,
  cpgn: string
}

@Component({
  selector: 'app-pvgd-list-char',
  templateUrl: './pvgd-list-char.component.html',
  styleUrls: ['./pvgd-list-char.component.css']
})
export class PvgdListCharComponent implements OnInit {

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdListChar",        //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  faUserShield = faUserShield;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;

  public charlist: Char[] = [];

  constructor(
    private messageBus: MessageBus,
    private charListSvc: PvgdListCharService,
    private chngDetRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection =
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Listen for messages from the message queue
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

    //  Listen for API responses.
    this.charListSvc.getCharList()
      .subscribe(data => {
        for (const d of (data as any)) {
          this.charlist.push({
            _id: (d._id),
            name: (d.charName) ? d.charName : "None",
            gamesys: (d.gamesys) ? d.gamesys : "Agnostic",
            cpgn: (d.cpgn) ? d.cpgn : "Unassigned"
          });
        }
        this.chngDetRef.detectChanges();
      });
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: " + data);
  }

  /**  Send a message over the message queue that requests
       a specific tab be opened.  */
  private requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

  public createChar() {
    const fid = id + "createChar(): ";

    //  Send a message to open Create Character modal.
    const message:Message<any> = {
      recipientIds: ["pvgdMenu"],
      payload: { cmd: 0x1205 },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

  }

  public deleteChar(char_id:string) {
    const fid = id + "deleteChar(): ";
    console.log(fid + char_id);
  }

}
