const id = "mwx-pvgd//univ-util-timeline-service/";
 
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnivUtilTimelineService {

  constructor(private http:HttpClient) { }

  public getTimelineEvents(
    isGalacticCaucus: boolean,
    isChalmon: boolean,
    isGraeth: boolean,
    isLhaknaar: boolean,
    isSluve: boolean,
    isGovit: boolean,
    isGenoff: boolean
  ): Observable<any> {
    const fid = id + "getTimelineEvents(): ";
    console.log(fid);

    var uri = environment.MARKWILX_API_URI;
    uri += '/api/univ-util-timeline';
    uri += '?isGalacticCaucus=' + isGalacticCaucus;
    uri += '&isChalmon=' + isChalmon;
    uri += '&isGraeth=' + isGraeth;
    uri += '&isLhaknaar=' + isLhaknaar;
    uri += '&isSluve=' + isSluve;
    uri += '&isGovit=' + isGovit;
    uri += '&isGenoff=' + isGenoff;

    console.log(fid + "uri: " + uri);

    return this.http.get(uri);
  }

}
