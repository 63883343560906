
<div class="row">
  <div class="col">
    <h2><fa-icon [icon]="faUsers"></fa-icon> X'ample & X'emplars</h2>
  </div>
</div>

<div class="row">

  <div class="col" align="center">
    <hr>
    <div tooltip="The game system can be left undefined or specified.  If specified,
      additional campaign tools become available." container="body">
      <h4 align="left">Game System</h4>
      <h5>Orphans' Realm</h5>
    </div>

    <div
      tooltip="Players and their characters who are members of the campaign or have requested an invite, 
      are displayed here.  The Game Master can eject players who are members or accept players who have 
      requested membership." container="body">
      <hr>
      <h4 align="left">Players</h4>

      <div *ngFor="let char of chars" style="padding:5px 10px 0px 10px;">  
        <button type="button" class="btn btn-dark btn-block btn-sm" (click)="char.isClpsd = !char.isClpsd"
          [attr.aria-expanded]="!char.isClpsd" aria-controls="collapseBasic"
          >{{char.charName}}
          <svg width="20px" height="20px" style="float:left">
            <circle cx="10px" cy="10px" r="10px" stroke-width="0px" [attr.fill]="char.color" />
          </svg>
        </button>
        <div id="charAttr" [collapse]="char.isClpsd" [isAnimated]="true"
          style="margin:0px; padding:10px; background-color:rgba(128,128,128,0.5);">
          <button *ngIf="char.isJoined"
            class="btn btn-dark btn-sm" style="margin:5px 0px 0px 0px; padding:2px; float:right;">Eject</button>
          <button *ngIf="!char.isJoined"
            class="btn btn-dark btn-sm" style="margin:5px 0px 0px 0px; padding:2px; float:right;">Accept</button>
          <button *ngIf="!char.isJoined"
            class="btn btn-dark btn-sm" style="margin:5px 0px 0px 0px; padding:2px; float:right;">Reject</button>
          <p *ngIf="char.isJoined" style="margin: 0px; padding: 0px;"
            >Player:  {{char.plyrName}}<br>
            Status:  Joined</p>
          <p *ngIf="!char.isJoined" style="margin: 0px; padding: 0px;"
            >Player:  {{char.plyrName}}<br>
            Status:  Invited</p>
        </div>
      </div>

    </div>

    <div tooltip="Tools for inviting players to join the campaign." container="body">
      <div style="padding:5px 10px 0px 10px;">  
        <br>
        <p><button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
          >Set Invite Code</button></p>
        <p><button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
          >Send Invite Email</button></p>
        <p><button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
          >Copy Invite Text</button></p>
      </div>
    </div>

    <div
      tooltip="Email all players, send game date/time reminders, and send messages during your games."
      container="body">
      <hr>
      <h4 align="left">Communications</h4>

      <div style="padding:5px 10px 0px 10px;">  
        <div style="margin:0px; padding:10px; background-color:rgba(128,128,128,0.5);">
          <p>In development.</p>
          <p>Check back later for additional details.</p>
        </div>
      </div>
    </div>

  </div>

  <div class="col" align="center">

    <div
      tooltip="Tabletops, handouts, and other game resources which have been imported from user files or
      adventure libraries are imported here.  From here, the GM can edit them and control whether the players
      are able to view them." container="body">
      <hr>
      <h4 align="left">Game Resources</h4>

      <div *ngFor="let res of vttRes" style="padding:5px 10px 0px 10px;">  
        <button type="button" class="btn btn-dark btn-block btn-sm" (click)="res.isClpsd = !res.isClpsd"
          [attr.aria-expanded]="!res.isClpsd" aria-controls="collapseBasic">{{res.resName}}</button>
        <div id="resAttr" [collapse]="res.isClpsd" [isAnimated]="true"
          style="margin:0px; padding:10px; background-color:rgba(128,128,128,0.5);">
          <p *ngIf="res.isMap" style="margin: 0; font-variant: small-caps; font-weight: bold" 
            align="center">[ Tabletop ]</p>
          <p *ngIf="res.isHandout" style="margin: 0; font-variant: small-caps; font-weight: bold" 
            align="center">[ Handout ]</p>
          <p align="left">{{res.desc}}</p>
          <button class="btn btn-dark btn-sm" style="margin: 0px 5px 5px 0px; padding:2px">View</button>
          <button *ngIf="!res.isVis"
            class="btn btn-dark btn-sm" style="margin:0px 5px 5px 0px; padding:2px">Show to Players</button>
          <button *ngIf="res.isVis"
            class="btn btn-dark btn-sm" style="margin:0px 5px 5px 0px; padding:2px">Push to View</button>
          <button *ngIf="res.isVis"
            class="btn btn-dark btn-sm" style="margin:0px 5px 5px 0px; padding:2px">Hide from Players</button>
        </div>
      </div>

    </div>

    <div tooltip="Tools for loading new resources into the campaign." container="body">
      <br>
      <div style="padding:5px 10px 0px 10px;">  
        <p><button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
          >Import Resource from Game Box</button></p>
        <p><button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
          >Import Resource from Local File</button></p>
      </div>
    </div>

  </div>


  <div class="col" align="center">
    <div
      tooltip="The campaign's current setting, visible to the players." container="body">
      <hr>
      <h4 align="left">Game Setting</h4>
      <div style="padding:10px;">
        <div align="left" style="padding:10px; background-color:rgba(128,128,128,0.5);"
          >{{setting}}
          <p align="center"><button style="margin:0px; padding:5px;" type="button" class="btn btn-dark btn-sm"
            align="right">Edit</button></p>
        </div>
      </div>
    </div>

    <div>
      <hr>
      <div
        tooltip="Each library is a volume of game resources that can be added to the campaign."
        container="body">
        <h4 align="left">Linked Game Boxes</h4>

        <div *ngFor="let lib of vttLib" style="padding:5px 10px 0px 10px;">  
          <button type="button" class="btn btn-dark btn-block btn-sm" (click)="lib.isClpsd = !lib.isClpsd"
            [attr.aria-expanded]="!lib.isClpsd" aria-controls="collapseBasic">{{lib.libName}}</button>
          <div id="libAttr" [collapse]="lib.isClpsd" [isAnimated]="true"
            style="margin:0px; padding:10px; background-color:rgba(128,128,128,0.5);">
            <p align="left">{{lib.desc}}</p>
            <button class="btn btn-dark btn-sm" style="margin: 0px 5px 5px 0px; padding:2px">Open</button>
            <button class="btn btn-dark btn-sm" style="margin: 0px 5px 5px 0px; padding:2px">Remove</button>
          </div>
        </div>
      </div>

      <div align=center style="padding: 0px 10px 0px 10px;">
        <div tooltip="Tools for adding additional resource libraries into the campaign." 
          container="body">

          <br>
          <p><button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
            >Link Game Box</button></p>
        </div>

      </div>
      <div align=center style="padding: 0px 10px 0px 10px;">
        <hr>
        <h4 align="left">References</h4>
        <p>
          <button class="btn btn-dark btn-sm" containerClass="customClass" 
            tooltip="Open a new tab containing online game resources." >
            <img class="imgOrph" src="/assets/logos/OrphansRealmLogo-Light.svg">
          </button>
        </p>
      </div>

    </div>
  </div>

