const id = "mwx-pvgd//pvgd-list-cpgn/";

import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { faUsers, faPencilAlt, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import { PvgdListCpgnService } from './pvgd-list-cpgn.service';

interface Cpgn {
  _id: string,
  name: string,
  status: string,
  gamesys: string
}
@Component({
  selector: 'app-pvgd-list-cpgn',
  templateUrl: './pvgd-list-cpgn.component.html',
  styleUrls: ['./pvgd-list-cpgn.component.css']
})
export class PvgdListCpgnComponent implements OnInit {

  faUsers = faUsers;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;

  public cpgnlist: Cpgn[] = [];

  constructor(
    private cpgnListSvc: PvgdListCpgnService,
    private chngDetRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";
    this.cpgnListSvc.getCpgnList()
      .subscribe(data => {
        for (const d of (data as any)) {
          this.cpgnlist.push({
            _id: (d._id),
            name: (d.cpgnName),
            status: (d.status) ? d.status : "Unknown",
            gamesys: (d.gamesys) ? d.gamesys : "Agnostic"
          });
        }
        this.chngDetRef.detectChanges();
      });
  }

  editCpgn(cpgn_id:string) {
    const fid = id + "editCpgn(): ";
    console.log(fid + cpgn_id);
  }

  deleteCpgn(cpgn_id:string) {
    const fid = id + "deleteCpgn(): ";
    console.log(fid + cpgn_id);
  }

}

