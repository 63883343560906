const id = "mwx-pvgd//tyro-util-ref-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TyroUtilRefService {

  constructor(private http: HttpClient) { }

  public getRefItems(
    type: string, 
    cat: string, 
    iden: string): Observable<any> {

    const fid = id + "getRefItems(): ";

    var uri = environment.MARKWILX_API_URI;
    uri += '/api/tyro-util-ref';
    uri += '?type=' + type;
    uri += '&cat=' + cat;
    uri += '&iden=' + iden;

    return this.http.get(uri);
  }

}
