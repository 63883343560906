import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//  Modal
import { ModalModule } from 'ngx-bootstrap/modal';

//  Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

//  MarkWilx
import { PvgdToolsComponent } from './pvgd-tools/pvgd-tools.component';

@NgModule({
  declarations: [
    PvgdToolsComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    FontAwesomeModule
  ],
  exports: [
    PvgdToolsComponent
  ]
})

export class PvgdModule { }

