const id = "mwx-pvgd//pvgd-cpgn-service/";

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { Socket } from 'ngx-socket-io';
import { SockPkt } from './sockpkt';

@Injectable({
  providedIn: 'root'
})
export class PvgdCpgnService {

  constructor() { }
  //constructor(private socket: Socket) { }

  //transmitMessage(sendPkt: SockPkt) {
    //this.socket.emit("msg_chat", sendPkt);
  //}

  //receiveMessage(): Observable<SockPkt> {
    //return this.socket.fromEvent("msg_chat");
  //}

}
