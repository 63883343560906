const id = "mwx-pvgd//pvgd-list-game/";

import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { faDragon, faPencilAlt, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

//import { PvgdListGameService } from './pvgd-list-game.service';

interface Game {
  _id: string,
  name: string,
  gamesys: string,
  status: string
}

@Component({
  selector: 'app-pvgd-list-game',
  templateUrl: './pvgd-list-game.component.html',
  styleUrls: ['./pvgd-list-game.component.css']
})
export class PvgdListGameComponent implements OnInit {

  /*  Font Icons  */
  faDragon = faDragon;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdListGame",        //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  public gamelist: Game[] = [];

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private messageBus: MessageBus,
    //private cpgnListSvc: PvgdListCpgnService
  ) { 
  }

  ngOnInit(): void {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";
    /*
    this.gameListSvc.getgameList()
      .subscribe(data => {
        for (const d of (data as any)) {
          this.gamelist.push({
            _id: (d._id),
            name: (d.gameName),
            gamesys: (d.gamesys) ? d.gamesys : "Agnostic",
            status: (d.status) ? d.status : "Unknown"
          });
        }
        this.chngDetRef.detectChanges();
      });
    */
    this.gamelist.push({ _id: "1", name: "Placeholder's Revenge",
      gamesys: "Universe", status: "Private"});
    this.gamelist.push({ _id: "2", name: "Crash on Volturnus",
      gamesys: "Star Frontiers", status: "Public"});
    this.gamelist.push({ _id: "3", name: "Volturnus, Planet of Mystery",
      gamesys: "Star Frontiers", status: "Public"});
    this.gamelist.push({ _id: "4", name: "Starspawn of Volturnus",
      gamesys: "Star Frontiers", status: "Public"});
  }

  ngAfterConnectionInit() {
    const fid = id +"ngAfterConnectionInit(): ";

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

  editGame(game_id:string) {
    const fid = id + "editGame(): ";
    console.log(fid + game_id);
  }

  deleteGame(game_id:string) {
    const fid = id + "deleteGame(): ";
    console.log(fid + game_id);
  }
}

