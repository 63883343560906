const id = "mwx-pvgd//pvgd-cpgn/";

import { Component, OnInit } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { PvgdCpgnService } from './pvgd-cpgn.service';

//  MWX -- https://www.digitalocean.com/community/tutorials/angular-socket-io
//import * as io from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';

import { SockPkt } from './sockpkt';

@Component({
  selector: 'app-pvgd-cpgn',
  templateUrl: './pvgd-cpgn.component.html',
  styleUrls: ['./pvgd-cpgn.component.css']
})
export class PvgdCpgnComponent implements OnInit {

  userdata;

  messages = [
    { user: 'admin', text: 'This is a test of the chat application' }
  ];

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdCpgn",            //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  //  MWX - Note that this needs to happen when the user logs into 
  //  MWX - a particular campaign.  Or maybe it needs to happen when
  //  MWX - a user logs in, period.  It all depends on how messaging
  //  MWX - will happen.
  //  MWX - Becasue this is where the campaign socket will connect.
  constructor(
    private messageBus: MessageBus,
    private pvgdCpgnService: PvgdCpgnService) { 
    const fid = id + "constructor(): ";
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Subscription to get socket message.
    /* MWX
    this.pvgdCpgnService.receiveMessage()
      .subscribe((recvPkt: SockPkt) => { 
      this.messages.push(recvPkt);
      console.log(fid + "echo - " + recvPkt.text);
    });
    */

    this.userdata = jwtDecode(localStorage.getItem('auth_token'));

  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    // MWX -- Likely need to handle destruction of above subscription.
  }

  sendMessage(sendMsg: string) {
    const fid = id + "sendMessage(): ";
    let sendPkt = new SockPkt();

    console.log(fid + sendMsg);

    //if(this.userdata.username) sendPkt.user = this.userdata.username;
    //else sendPkt.user = "Unknown";

    //sendPkt.text = sendMsg;

    //  Send the message for tranmission via socket.
    //this.pvgdCpgnService.transmitMessage(sendPkt);

  }

}

