<div>

  <div class="hdrRow" align="center">
    <div class="hdrCol rounded" align="center">
    </div>
  </div>

  <div class="row">

    <div class="col" align="left">
      <h2 align="center">Campaign Info</h2>
      <hr>
      <p>In development.</p>
      <div id="msgStream" style="height:25em">
      </div>
    </div>

    <div class="col" style="col" align="left">
      <div id="comms">

        <div id="messages">
          <li *ngFor="let message of messages">
            <b>{{message.user}}</b> - {{ message.text }}
          </li>
        </div>

        <form action="">
          <input id="sendMsg" autocomplete="off" #sendMsg />
          <button type="submit" id="sendBtn"
            (click)="sendMessage(sendMsg.value); sendMsg.value=''" >
	    Send</button>
        </form>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>Operative Online is a trademark of Tower Ravens LLC.</small>
    </div>
  </div>

</div>

