const id = "mwx-pvgd//univ-tools/";

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxCatalogService } from '@app/mwx-auth/mwx-catalog.service';
import { faBook, faTh, faClock, faSun } from '@fortawesome/free-solid-svg-icons';
import { environment } from '@environments/environment';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-univ-tools',
  templateUrl: './univ-tools.component.html',
  styleUrls: ['./univ-tools.component.css']
})
export class UnivToolsComponent implements OnInit {

  /*  Font Icons  */
  faBook = faBook;
  faTh = faTh;
  faClock = faClock;
  faSun = faSun;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "univTools",           //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  /** Catalog items received fromthe OpOl catalog.  */
  items = [ ];

  /** Build the component with the catalog service (which accesses items
      out of the OpOl catalog) and the message queue bus.  */
  constructor(
    private chngDetRef: ChangeDetectorRef,
    private mwxCatalogService: MwxCatalogService,
    private messageBus: MessageBus) {
  }

  /** Initialize the component with the message bus.  */
  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  /** After initializing the compoenent, connect to the message queue and 
      request the OpOl catalog listings.  */
  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

    //  Load catalog items.
    this.getCatalogItems();
  }

  /** When the component is destroyed, terminate the message queue connection.  */
  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  /** Enent handler for received messages fromthe message queue.
      This function should never be called, as messages should not
      be sent to this component.  */
  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  /** Open a new browser window or tab with the the provided URI link.
      @param {objectid} item - Contains the destination URI.  */
  private clickURI(item) {
    const fid = id + "clickURI: ";
    window.open(item.linkURI);
  }

  /** Open a new tab for a requested tool.
      @param {string} tabid - Tab identifier indicating the tab to 
        create and open.  
      @param {string} hdg - For non-unique tabs, this optional 
        index discriminates between multiple tabs, as in the case
        of characters, campaigns, and game boxes.  */
  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg:hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

  /** Get the items fromthe OpOl catalog for Universe.
      @returns {array} items - Array of OpOl catalog objects.  */
  private getCatalogItems(): void {
    const fid = id + "getItems(): ";

    this.mwxCatalogService.getCatalogItems('univ').subscribe(
      data => {
        this.items = data.result;
        this.chngDetRef.detectChanges();
      },
      err => {
        console.log(fid + "ERROR: " + JSON.stringify(err));
      }
    );
  }

}

