<div class="container-fluid" 
  style="margins: 0px; padding: 0px;"
  align="center">

  <h1><fa-icon [icon]="faDragon"></fa-icon> Game Chest</h1>
  <hr>


  <!--  At Bootstrap breakpoints lg and xl there will be two
        columns contained within one row.  At Bootstrap
        breakpoints xs, sm, and md the two columns will have
        a div breakpoint between the two rows.  -->

  <div class="row">

    <!--  Left column, containing the Game Boxes  -->

    <!--  Bootstrap breakpoints xl, lg  -->
    <div class="d-none d-lg-block col-8" >

      <div class="row">
        <div class="col">
          <h4>Game Boxes</h4>
          <hr>
        </div>
      </div>

      <div class="row justify-content-center">
      <div *ngFor="let game of gamelist" class="card-game">
        <div class="row">
          <div class="col">
            <h5>{{ game.name }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>System: {{ game.gamesys }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>Status: {{ game.status }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col"></div>
          <div class="col">
            <button class="btn btn-dark btn-sm"
              (click)="editGame(char._id)">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
          <div class="col"></div>
          <div class="col">
            <button class="btn btn-dark btn-sm"
              (click)="deleteGame(char._id)">
              <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
          </div>
          <div class="col"></div>
        </div>
      </div>
      </div>

      <div class="row">
        <div class="col" style="margin:10px 10px 0px 10px; padding:10px">
          <button class="btn btn-dark btn-sm">Load Game Box</button>
        </div>
      </div>

      <div class="row">
        <div class="col" style="margin:0px 10px 10px 10px; padding:10px">
          <button class="btn btn-dark btn-sm">Create Game Box</button>
        </div>
      </div>

    </div>

    <!--  Bootstrap breakpoints xs, sm, md  -->
    <div class="d-block d-lg-none col mwx-body-col-left" >

      <div class="row">
        <div class="col">
          <h4>Game Boxes</h4>
          <hr>
        </div>
      </div>

      <div class="row justify-content-center">
      <div *ngFor="let game of gamelist" class="card-game">
        <div class="row">
          <div class="col">
            <h5>{{ game.name }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>System: {{ game.gamesys }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>Status: {{ game.status }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col"></div>
          <div class="col">
            <button class="btn btn-dark btn-sm"
              (click)="editGame(char._id)">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
          <div class="col"></div>
          <div class="col">
            <button class="btn btn-dark btn-sm"
              (click)="deleteGame(char._id)">
              <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
          </div>
          <div class="col"></div>
        </div>
      </div>
      </div>

      <div class="row">
        <div class="col" style="margin:10px 10px 0px 10px; padding:10px">
          <button class="btn btn-dark btn-sm">Load Game Box</button>
        </div>
      </div>

      <div class="row">
        <div class="col" style="margin:0px 10px 10px 10px; padding:10px">
          <button class="btn btn-dark btn-sm">Create Game Box</button>
        </div>
      </div>

    </div>

    <!--  Column stacking on Bootstrap breakpoints xs, sm, and md.  -->
    <div class="d-lg-none w-100">
      <hr>
    </div>

    <!--  Right column, containing the Bookshelf  -->
    <div class="col" >

      <h4>Bookshelf</h4>
      <hr>

      <p><button class="btn btn-dark btn-sm"
        (click)="requestTab('pvgdTools')">
        <img class="imgPvgd" src="/assets/logos/pvgd-WordMk-Box-DkBkg.png">
      </button></p>

      <p><button class="btn btn-dark btn-sm"
        (click)="requestTab('univTools')">
        <img class="imgUniv" src="/assets/logos/UniverseLogo-Light.svg">
      </button></p>

      <p><button class="btn btn-dark btn-sm"
        (click)="requestTab('tyroTools')">
        <img class="imgTyro" src="/assets/logos/TyroUnivLogo-Light.svg">
      </button></p>

      <p><button class="btn btn-dark btn-sm"
        (click)="requestTab('orphTools')">
        <img class="imgOrph" src="/assets/logos/OrphansRealmLogo-Light.svg">
      </button></p>

      <p><button class="btn btn-dark btn-sm"
        (click)="requestTab('sfrnTools')">
          <img class="imgSfrn" src="/assets/logos/StarFron_Logo_Light.svg">
      </button></p>

    </div>

</div>

