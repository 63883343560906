const id = "mwx-pvgd//mwx-about/";

import { Component, OnInit } from '@angular/core';
import { faFilePdf, faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-mwx-about',
  templateUrl: './mwx-about.component.html',
  styleUrls: ['./mwx-about.component.css']
})
export class MwxAboutComponent implements OnInit {

  faFilePdf = faFilePdf;
  faDiceD20 = faDiceD20;
  faFacebookSquare = faFacebookSquare;

  constructor() { }

  ngOnInit() {
  }

}

