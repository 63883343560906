<div class="row">
  <div class="col">
    <h2><fa-icon [icon]="faUserShield"></fa-icon> {{ charName }}</h2>
  </div>
</div>

<div class="row">

  <div class="col" align="center">
    <hr>

    <div container="body">
      <h4 align="left">Game System</h4>
        <h5>{{ gameSystem }}</h5>
      <hr>
    </div>

    <div container="body">
      <h4 align="left">Campaign</h4>
        <h5>{{ cpgnMember }}
          <button style="margin: 5px 5px 0px 20px;" type="button"
            class="btn btn-dark btn-sm">Open</button>
          <button style="margin: 5px 0px 0px 5px;" type="button"
            class="btn btn-dark btn-sm">Quit</button>
        </h5>
      <hr>
    </div>

    <div>
      <h4 align="left">Token</h4>
      <div style="padding: 5px 10px 0px 10px;">



        <div class="row">

          <div class="col char boxTokenMelee" >
            <div class="vertical-center">
              <svg 
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="72" height="72"
                container="body">
                <defs>
                  <radialGradient id="radGrad" cx="50%" cy="50%" r="50%" 
                    fx="50%" fy="50%">
                    <stop offset="0%" 
                      [style.stop-color]="charColor"
                      [style.stop-opacity]="'1.0'" />
                    <stop offset="100%" 
                      [style.stop-color]="charColor"
                      [style.stop-opacity]="'0.0'" />
                  </radialGradient>
                </defs>
                <circle cx="34" cy="34" r="35" 
                  [attr.stroke]="charColor" 
                  fill="url(#radGrad)"
                  stroke-width="0" 
                  fill-opacity="1"  />
                <image width="70" height="70" [attr.xlink:href]=imgTop />
              </svg>
            </div>
          </div>

          <div class="col char boxTokenMelee" >
            <div class="vertical-center">
              <svg 
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="37" height="37"
                container="body">
                <image width="35" height="35" [attr.xlink:href]=imgIcon />
                <circle cx="18" cy="18" r="17" [attr.stroke]="charColor" 
                stroke-width="3" fill-opacity="0"  />
              </svg>
            </div>
          </div>

          <div class="col char boxTokenExplore" >
            <div class="vertical-center">
              <svg width="16" height="25">
                <circle cx="8" cy="15" r="6" [attr.fill]="charColor" />
              </svg>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col">
            <p align="left">Token Color</p>
            <div class="col" >
              <input 
                readonly
                type="type" 
                style="height:30px; width:50px"
                [(colorPicker)]="charColor" 
                [style.background]="charColor" 
                cpAlphaChannel="disabled"
                cpCancelButton=true
                cpOKButton=true
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p align="left">Token Text</p>
            <textarea id="charHover" name="charHover" cols="35" rows="8" 
              readonly style="resize: both; overflow: auto; width:100%;"
              >{{ tokenNotes }}</textarea>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col" align="center">
    <div container="body">
      <hr>
      <h4 align="left">Character Stats</h4>
      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdAttr = !isClpsdAttr"
          [attr.aria-expanded]="!isClpsdAttr" 
          aria-controls="collapseBasic">Stats & Attributes</button>
        <div id="charAttr" [collapse]="isClpsdAttr" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_attr | nl2br">
        </div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdSkls = !isClpsdSkls"
          [attr.aria-expanded]="!isClpsdSkls" 
          aria-controls="collapseBasic">Skills</button>
        <div id="charSkls" [collapse]="isClpsdSkls" [isAnimated]="true" 
          align="left"
          style="background-color:rgba(128,128,128,0.5); margin:0px; 
          padding:10px; line-height:130%" 
          [innerHTML]="char_skls | nl2br">
        </div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdEqip = !isClpsdEqip"
          [attr.aria-expanded]="!isClpsdEqip" 
          aria-controls="collapseBasic">Treasure & Equipment</button>
        <div id="charEqip" [collapse]="isClpsdEqip" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_eqip | nl2br"></div>

      </div>

      <div>
        <button style="margin: 5px 0px 0px 0px;" type="button" 
          class="btn btn-dark btn-sm">Edit</button>
      </div>

    </div>

    <hr>
    <h4 align="left">References</h4>
    <p>
      None.
    </p>

  </div>

  <div class="col" align="center">
    <hr>
    <h4 align="left">Notes</h4>
    <div style="padding: 5px 10px 0px 10px;">
      <div align=center>

        <img class="imgPortrait" [style.color]="charColor" 
          [src]="imgPortrait" >

      </div>
      <h5 align="left">Public Description & History</h5>
      <textarea id="charPubBkgd" name="charPubBkgd" cols="50" rows="10" 
        readonly
        style="resize: both; overflow: auto; width:100%;"
        >{{char_pub_bkgd}}</textarea>
      <p> </p>
      <h5 align="left">Confidential Notes</h5>
      <textarea id="charPrvBkgd" name="charPrvBkgd" cols="50" rows="10" 
        readonly
        style="resize: both; overflow: auto; width:100%;"
        >{{char_prv_bkgd}}</textarea>
    </div>
  </div>
</div>
