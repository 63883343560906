const id = "mwx-pvgd//pvgd-char-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PvgdCharService {

  /*  CORS Options  */
  httpOptions = {
    withCredentials: true,
    Headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private http: HttpClient,
    private mwxAuthService: MwxAuthService
  ) { }

  public createChar(charName: string, gameSystem: string):
    Observable<any> {
    const fid = id + "createChar(): ";
    const uri = environment.MARKWILX_API_URI + '/api/pvgd-char';

    let createResult;
    createResult = this.http.post(uri, 
      {charName, gameSystem}, this.httpOptions);

    return createResult;

  }

  public getChar(_id: string): Observable<any> {
    const fid = id + "getChar(): ";

    const uri = environment.MARKWILX_API_URI 
      + '/api/pvgd-char'
      + '/' + _id;

    return this.http.get(uri);

  }

}

