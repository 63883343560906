<div class="container">
  <div class="row">
    <h1>Performance Table</h1>
  </div>
  <form #perfForm="ngForm">
    <div class="row">
      <div class="col-100">
        <label for="row">Skill Row</label>
      </div>
      <div class="col-150">
        <select class="form-control" style="width: 150px" 
          id="skill_row" required
          [(ngModel)]="this.skill_row" name="skill_row"
          (change)="calcResult()"
          >
          <option *ngFor="let form_row of skill_rows" 
            [value]="form_row" [selected]="form_row === 'N/-1'"
            >
            {{form_row}}</option>
        </select>
      </div>
      <div class="col-150">
        <input type="text" class="form-control" id="form_max_dmg"
          [(ngModel)]="this.max_dmg" name="form_max_dmg"
          >
      </div>
      <div class="col-100">
        <label for="form_max_dmg">Max. Damage</label>
      </div>
    </div>
    <div class="row">
      <div class="col-100">
        <label for="form_roll">Roll</label>
      </div>
      <div class="col-150">
        <input type="text" class="form-control" id="form_roll" required
          [(ngModel)]="this.roll" name="form_roll"
          (input)="calcResult()"
        >
      </div>
      <div class="col-150">
        <button type="button" class="btn btn-success"i
          (click)="randomRoll();calcResult()">ROLL</button>
      </div>
    </div>
    <div class="row">
      <div class="col-100">
        <label for="form_result_skill">Result</label>
      </div>
      <div class="col-150">
        <output type="text" class="form-control" id="form_result_skill" 
          disabled>
        {{this.result_txt}}
        </output>
      </div>
      <div class="col-150">
        <output type="text" class="form-control" id="form_result_dmg"
          disabled>
        {{this.final_dmg}}
        </output>
      </div>
    </div>
 
    <!--
    <div class="row">
      <p>Selected is:<br/>
        Skill Row:  {{this.skill_row}}<br/>
        Max Dmg:  {{this.max_dmg}}<br/>
        Roll: {{this.roll}}<br/>
        Success:  {{this.skill_min_success}}<br/>
        Result: {{this.roll_result}}<br/>
      </p>
    </div>
    -->

    <div>
      <img src="assets/images/10507-PerformanceTable.svg" 
        style="
          display: block;
          padding: 20px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          max-width: 650px;
        "
        alt="Performance Table">
    </div>

    <div>
      <i>Brought to you by Frank Olson and Mark Wilkinson, version 0.3.0</i>
    </div>
 
  </form>

</div>

