<div style="padding: 13px;">
  <div class="mwx-text-box">
    <h3>What's Past is Prologue</h3>
    <p><i>The Tempest, Act 2, Scene 1</i></p>
  </div>

  <div class="mwx-text-box">
    <p>Coming soon...</p>
  </div>
</div>
