const id = "mwx-pvgd//pvgd-contact-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MwxContactService {

  /*  CORS Options  */
  httpOptions = {
    withCredentials: true,
    Headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(private http: HttpClient) { }

  public sendMessage(email: string, message: string):
    Observable<any> {
    const fid = id + "sendMessage(): ";
    const uri = environment.MARKWILX_API_URI + '/api/mwx-contact';

    let createResult;
    createResult = this.http.post(uri,
      {email, message}, this.httpOptions);

    return createResult;
  }
}
