// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  Select one of the API URI's per the usage.

  //  Development platform for server
  //MARKWILX_API_URI: 'http://localhost:3006',

  //  Production platform for Heroku
  MARKWILX_API_URI: 'https://mwx-api.herokuapp.com',

  //  Production platform for DigitalOcean
  //MARKWILX_API_URI: 'https://api.markwilx.tech',

  MARKWILX_PGP_KEY: `
-----BEGIN PGP PUBLIC KEY BLOCK-----

mQENBF6N/WkBCAC6LkhTCzDpg8spRb4qVRHJu3e+sg1vrnIV7Tvoz0ccSWpM2BW5
bBM4S2GVeG3kSH50lYb4ZR4Tll6vmK6MN/8K3vEpQufrP4wfrsIWKttmoMSYA4mz
uKtuiRctaMF9ePROG53I5DQ+KFyZB6wuTKbCPVnvZVjn0WonN2Z3NptKayl3nohO
cK4yJHb6tdVIBATVST9bcBR34kygPs+0ZcKPFiS9rACDFhPf4KANsn5fyT0cYhyg
Ni3758UrN76DNH18lIOg4bhG2DVpwapW02wKf2L+CWIEpkuHXvpWVVOW5V0UI7RS
3t6WAOBrZtT5Th7XSb43KeeW9jFYuWAr+YzTABEBAAG0Hk1hcmtXaWx4IDxwdWJr
ZXlAbWFya3dpbHguY29tPokBTgQTAQgAOBYhBCLBTmSlFS9RzOh8fpG7kIKGHoFJ
BQJejf1pAhsDBQsJCAcCBhUKCQgLAgQWAgMBAh4BAheAAAoJEJG7kIKGHoFJHtwH
/1Q9BqPmHkiMViOswlH7kXW/FJMQ1Edu85PhoASbyPEawS3tfZsTg9y1Nf2N33HT
lMjQikgz+ATdDWDC+R+NaLJgACEYeMi5tTUYcziq3mz4473FkmyDBxf8SlY3ii43
SVXD8A6vYftdGfiPqfDDWGm903CUBBewcboCnkOZ3itMnTRSo+jfM2qfd7YSREj5
DQ3ZhSeXktX9c08BMCpHRegJVlwTI1o3Rpxnjyls7YHGjqssNkI1zYvJPV+sWDAn
hSJVuNOF8sz4uxIsYzolYdqGcJBi4pGHJrRfZ6gaayAnJAaxNAINVURP+SiFJ2Mp
ntTqtXeUM9hGjp6uJnvF+QG5AQ0EXo39aQEIALDuTMjdv0Afy07slOToi7mABG5g
sgJx7B4v8wnBS/LBjuskYD4SDaIds6D0rX8dsfWDL5CZcLgztqyiz3/en8z32TL1
3Qsal9spjEYZ9gS/aAIcO2g77TmRyxmkEnJ4rbyqt0kswmniOfDRui8gEabC3S/a
RXAljYyCcKACaWeN/X7jZd1P9zOrJYf1NiSgnQv4RVN2gIeVvPh32KA4GhI28GEH
SborRdzRHM3GruUC/T5xcUo+p+UOOutmGVYXnZeZbNZBU7jYIzabGFMqGyGOigqy
kQnULV346ET87lzN6FHZO4A1CL8mTro4ssl3txeIUZfd36Q+jzf7DinPvUsAEQEA
AYkBNgQYAQgAIBYhBCLBTmSlFS9RzOh8fpG7kIKGHoFJBQJejf1pAhsMAAoJEJG7
kIKGHoFJMcgH/3RrMz2lISpAAT8iMGLGUKUxEo/DYEbRFehcprmQtpKs0D8TPEzE
hTGSXw15aVCNHZ7KMiDYXVQqvHg+3Qo+RnKEiDUV1e46CIvxRcYOlOsYAixYwrS9
dYNxyY548QsBT3wAdKBsE0QWnyDh8Cydey3rDzd48P/5zmGBtLe8I9eVcYnDbg4k
19avSnrEdT3+K1UcroFieER0ylUytPMpYVql9nQt4ODqRqAx3Ot2L1s1wo6Xw+nI
xGt9dWQZt7QCmalQejH9lzMFlSFDMm7H5zPkoR9BaLuqxCneNuR1dI0k6dFfDrLm
T0KnlEe057zxkPf8UkN2vev5FxW4QwJYay8=
=VsQp

-----END PGP PUBLIC KEY BLOCK-----`,
  MARKWILX_PGP_PASSPHRASE: `null`,
  MARKWILX_PGP_PRIVKEY: `
-----BEGIN PGP PRIVATE KEY BLOCK-----

null

-----END PGP PRIVATE KEY BLOCK-----`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
