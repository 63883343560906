const id = "mwx-pvgd//pvgd-char/";

import { Component, ViewEncapsulation, OnInit, Input,
  ChangeDetectorRef
} from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { PvgdCharService } from './pvgd-char.service';
import { PvgdChar } from '@app/mwx-data/pvgd-char';

@Component({
  selector: 'app-pvgd-char',
  templateUrl: './pvgd-char.component.html',
  styleUrls: ['./pvgd-char.component.css']
})
export class PvgdCharComponent implements OnInit {

  @Input() idxValue: string;

  charName:string = "Loading...";
  gameSystem:string = "Loading...";
  cpgnMember:string = "Loading...";
  charColor:string = '#FFFFFF';
  tokenNotes:string = "";
  tokenScale:number = 0.01;
  imgTop:string = "";
  imgIcon:string = "";
  imgPortrait:string = "";
  pubNotes:string = "";
  privNotes:string = "";

  faUserShield = faUserShield;

  userdata;

  isClpsdAttr = true;
  isClpsdSkls = true;
  isClpsdEqip = true;

  char_attr:string = ``;
  char_skls: string = ``;
  char_eqip:string = ``;

  char_pub_bkgd:string = `[ Visible to all campaign members. ]`;
  char_prv_bkgd:string = `[ Visible to game master. ]`;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdChar",            //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private pvgdCharSvc: PvgdCharService,
    private messageBus: MessageBus) { 
    const fid = id + "constructor(): ";
  }

  ngOnInit() {
    const fid = id + "ngOnInit(): ";
    this.userdata = jwtDecode(localStorage.getItem('auth_token'));
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //console.log(fid + "id: " + this.idxValue);

    this.pvgdCharSvc.getChar(this.idxValue).subscribe(
      data => { 
        this.charName = data.charName;
        this.gameSystem = "TBD";
        this.cpgnMember = "TBD";
        this.charColor = data.charColor;
        this.tokenNotes = data.tokenNotes;
        this.tokenScale = data.tokenScale;
        this.imgTop = this.convertToImage(data.imgTop.data);
        this.imgIcon = this.convertToImage(data.imgIcon.data);
        this.imgPortrait = this.convertToImage(data.imgPortrait.data);
        this.pubNotes = data.pubNotes;
        this.privNotes = data.privNotes;

        this.chngDetRef.detectChanges();
      }
    );

  }

  ngOnDestroy() {
  }

  private convertToImage(imgData) {
    let typedArray = new Uint8Array(imgData);
    const stringChar = String.fromCharCode.apply(null, typedArray);
    return( "data:image/png;base64," + btoa(stringChar));
  }

}


