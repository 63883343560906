<!--  Contains the "Home" tab  -->
<div class="container-fluid mwx-body-frame">

  <!--  At Bootstrap breakpoints lg and xl there will be two 
        columns contained within one row.  At Bootstrap
        breakpoints xs, sm, and md the two columns will have 
        a div breakpoint between the two rows.  -->

  <!--  This first in the container  -->
  <div class="row">

    <!--  Left column, containing the main menu  -->

    <!--  Bootstrap breakpoints xl, lg  -->
    <div class="d-none d-lg-block col-4 mwx-body-col-left" >

      <!--  Header Logo  -->
      <br />
      <img class="imgTowerRavens" 
        src="/assets/logos/TRZ_Logo-BlackRed.png">
      <br />

      <!--  Buttons  -->
      <p style="margin:20px;">

        <!--  Contact button  -->
        <app-mwx-contact></app-mwx-contact>
        &#8226;

        <!--  Discord button  -->
        <button class="btn btn-dark btn-sm"
          (click)="openWindow('https://discord.gg/VBzmjHet3y')">
          <fa-icon [icon]="faDiscord"></fa-icon> Discord
        </button>
        &#8226;

        <!--  About button  -->
        <button class="btn btn-dark btn-sm"
          (click)="requestTab('mwxAbout')">
          <fa-icon [icon]="faQuestionCircle"></fa-icon> About
        </button>

      </p>

      <!--  Projects header  -->
      <hr />
      <h2><fa-icon [icon]="faProjectDiagram"></fa-icon> Projects</h2>
      <hr /><br />

      <table border="0" >

        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="openWindow(
                  'https://www.speculativefictionillustrated.com/')">
                <img class="imgRoyR" src="/assets/logos/SFI_Banner-White.png">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            A showcase magazine for amateur writers and artists.
          </td>
        </tr>

        <!--
        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="openWindow(
                  'https://www.royalroad.com/profile/78888/fictions')">
                <img class="imgRoyR" src="/assets/logos/Royal_Road_mod.png">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            Science fiction and fantasy short stories.
          </td>
        </tr>
        -->

        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="requestTab('pvgdMain')">
                <img class="imgPvgd" 
                  src="/assets/logos/pvgd-WordMk-Box-DkBkg.png">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            Virtual tabletop experimentation and development.
          </td>
        </tr>

        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="popupModal()">
                <img class="imgUniv" 
                  src="/assets/logos/UniverseLogo-Light.svg">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            Development of an advanced science fiction role playing game.
          </td>
        </tr>

        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="popupModal()">
                <img class="imgTyro" 
                  src="/assets/logos/TyroUnivLogo-Light.svg">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            Development of an introductory science fiction role playing game.
          </td>
        </tr>

        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="popupModal()">
                <img class="imgOrph" 
                  src="/assets/logos/OrphansRealmLogo-Light.svg">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            Development of an introductory fantasy role playing game.
          </td>
        </tr>

        <tr>
          <td style="width:50%;">
            <p style="margin:5px; padding:5px">
              <button class="btn btn-dark btn-sm"
                (click)="popupModal()">
                <img class="imgSfrn" 
                  src="/assets/logos/StarFron_Logo_Light.svg">
              </button>
            </p>
          </td>

          <td align="center" valign="center">
            Development of tools for TSR's defunct science fiction 
            role playing game.
          </td>
        </tr>

      </table>

      <hr />

    </div>

    <!--  Bootstrap breakpoints xs, sm, md  -->
    <div class="d-block d-lg-none col mwx-body-col-left" >

      <!--  Header Logo  -->
      <br />
      <img class="imgTowerRavens" 
        src="/assets/logos/TRZ_Logo-BlackRed.png">
      <br />

      <!--  Buttons  -->
      <p style="margin:20px;">

        <!--  Contact button  -->
        <app-mwx-contact></app-mwx-contact>
        &#8226;

        <!--  Discord button  -->
        <button class="btn btn-dark btn-sm"
          (click)="openWindow('https://discord.gg/VBzmjHet3y')">
          <fa-icon [icon]="faDiscord"></fa-icon> Discord
        </button>
        &#8226;

        <!--  About button  -->
        <button class="btn btn-dark btn-sm"
          (click)="requestTab('mwxAbout')">
          <fa-icon [icon]="faQuestionCircle"></fa-icon> About
        </button>

      </p>

      <!--  Projects header  -->
      <hr />
      <h2><fa-icon [icon]="faProjectDiagram"></fa-icon> Projects</h2>
      <hr /><br />

      <!--  Speculative Fiction Illustrated  -->
      <p><button class="btn btn-dark btn-sm"
        (click)="openWindow('https://www.speculativefictionillustrated.com/')">
        <img class="imgRoyR" src="/assets/logos/SFI_Banner-White.png">
      </button></p>

      <!--  Royal Road  -->
      <!--
      <p><button class="btn btn-dark btn-sm"
        (click)=
        "openWindow('https://www.royalroad.com/profile/78888/fictions')">
        <img class="imgRoyR" src="/assets/logos/Royal_Road_mod.png">
      </button></p>
      -->

      <!--  Proving Ground Games  -->
      <p><button class="btn btn-dark btn-sm"
        (click)="requestTab('pvgdMain')">
        <img class="imgPvgd" src="/assets/logos/pvgd-WordMk-Box-DkBkg.png">
      </button></p>

      <!--  Universe, The Sci-Fi RPG  -->
      <p><button class="btn btn-dark btn-sm"
        (click)="popupModal()">
        <img class="imgUniv" src="/assets/logos/UniverseLogo-Light.svg">
      </button></p>

      <!--  Tyro Universe, The Introductory Sci-Fi RPG  -->
      <p><button class="btn btn-dark btn-sm"
        (click)="popupModal()">
        <img class="imgTyro" src="/assets/logos/TyroUnivLogo-Light.svg">
      </button></p>

      <!--  Orphans' Reals, The Introductory Fantasy RPG  -->
      <p><button class="btn btn-dark btn-sm"
        (click)="popupModal()">
        <img class="imgOrph" src="/assets/logos/OrphansRealmLogo-Light.svg">
      </button></p>

      <!--  Star Frontiers  -->
      <p><button class="btn btn-dark btn-sm"
        (click)="popupModal()">
          <img class="imgSfrn" src="/assets/logos/StarFron_Logo_Light.svg">
      </button></p>

      <hr />

    </div>

    <!--  Column stacking on Bootstrap breakpoints xs, sm, and md.  -->
    <div class="d-lg-none w-100"></div>

    <!--  Right column; currently reserved for ads.  -->
    <div class="col mwx-body-col-right"> 

      <!--  Display when the user is not logged in.  -->
      <div *ngIf="!mwxAuthService.isAuthenticated()">

        <!--  Maneuvers ad.  -->
        <!--
        <div>
          <div class="mwx-adtitle-box mwx-text-box">
            <h5><u>Available Now</u></h5>
            <h4>Maneuvers</h4>
            <h5><i>A Universe Histories Novel</i></h5>
          </div>

            <img class="ad" alt="Maneuvers" 
              src="/assets/ads/Maneuvers-Cover.png">

          <div class="mwx-adbody-box mwx-text-box">
            <p style="text-align:left">
              As an international student, Jake Dawson finds himself in the 
              middle of a political overthrow.  The status quo is challenged, 
              and the seditionists take control of the economic engines of 
              society.  Jake defies the shifting landscape, ignores the 
              cancellation of his visa, and remains with his friends to stop 
              the transformation of the foreign culture he’s come to love.
            </p>
            <p style="text-align:left">
              A man of power and influence, with whom Jake shares a common 
              interest, clashes with his ideology.  Their crossing paths 
              force Jake to challenge his moral values and decide how far 
              into the dark conflict he will travel.  He knows his lone 
              journey will change the lives of those around him.  Does he 
              have the right to do so?
            </p>
            <p style="text-align:left">
              While he's aware his action may help his friends, he fails 
              to see the drastic change he'll force upon of his own life.  
              His maneuvering will shift not only the landscape of the 
              country, but the history of their entire world.
            </p>
          </div>

          <div class="mwx-adbody-box mwx-text-box">
            <p>
              <b>12 USD (6x9 softcover OR ePub).</b><br />
              <b>13 USD (6x9 softcover AND ePub).</b>  
            </p>

            <div style="width:75%; margin:auto">
              <hr />
              <p>
                Purchase print <i><b>and/or</b></i> ePub from this retailer.
              </p>
              <a href="#" onclick="window.open(
                'https://www.drivethrufiction.com/' +
                'product/424547/Maneuvers'
                )" target="_blank">
                <img src="/assets/badges/DTFiction_Badge.png"
                width=150px alt="DriveThru Fiction"></a>
            </div>

            <div style="width:75%; margin:auto">
              <hr />
              <p>
                Purchase print <i><b>or</b></i> ePub from this retailer.
              </p>
              <a href="#" onclick="window.open(
                'https://www.amazon.com/' +
                'Maneuvers-Universe-Histories-Book-1-ebook/' +
                'dp/B0CLZ2MWB7'
                )" target="_blank">
                <img src="/assets/badges/Amazon_Badge.png"
                width=150px alt="Amazon Kindle"></a>
            </div>

            <div style="width:75%; margin:auto">
              <hr />
              <p>
                Purchase ePub from these retailers.
              </p>
              <a href="#" onclick="window.open(
                'https://books.apple.com/us/book/maneuvers/id6470688872'
                )" target="_self">
                <img src="/assets/badges/Apple_Badge.png"
                width=150px alt="Apple Books"></a>
              <a href="#" onclick="window.open(
                'https://www.barnesandnoble.com/w/' +
                'maneuvers-mark-wilkinson/1144313292'
                )" target="_blank">
                <img src="/assets/badges/BarnesNoble_Badge.png"
                width=150px alt="Barnes & Noble"></a>
              <a href="#" onclick="window.open(
                'https://play.google.com/store/books/details/' +
                'Mark_Wilkinson_Maneuvers?id=HsvfEAAAQBAJ'
                )" target="_blank">
                <img src="/assets/badges/Google_Badge.png"
                width=150px alt="Google Play"></a>
              <a href="#" onclick="window.open(
                'https://www.kobo.com/us/en/ebook/maneuvers-3'
                )" target="_blank">
                <img src="/assets/badges/RakutenKobo_Badge.png"
                width=150px alt="Rakuten Kobo"></a>
            </div>

          </div>
        </div>

        <br /><hr /><br />
        -->

        <!--  Universe Master Rulebook, Volume I ad.  -->
        <div>
          <div class="mwx-adtitle-box mwx-text-box">
            <h5><u>Available Now</u></h5>
            <h4>Universe Master Rulebook</h4>
            <h5>Volume I</h5>
          </div>

          <img class="ad" alt="Universe MRB, Vol. I" 
            src="/assets/ads/Universe_Vol1_Cover.png">

          <div class="mwx-adbody-box mwx-text-box">
            <p style="text-align:left">
              Return to old-school gaming with Universe, The Sci-Fi RPG.  
              Pilot starships, explore alien worlds, destroy adversaries, 
              establish outposts, and build your own enterprises.
            </p>
            <p style="text-align:left">
              Whether you enjoy boarding actions against starships, 
              storming planets from orbit, smuggling dangerous goods, 
              designing technological gadgets, or maneuvering political 
              obstacles — there’s something in Universe for you.  Chain 
              guns, laser rifles, battle mechs, formidable robots, armed 
              starships, alien sentients, and ethereal reality in a 
              massive scale space opera characterize this science fiction 
              world.
            </p>
            <p style="text-align:left">
              Join the the team.  Add your own flavor as we flesh out the 
              core rules with setting and game flavor.  This is the public 
              development version of Universe, The Sci-Fi RRG, for 
              developers and gamers alike.
            </p>
            <p style="text-align:left">
              Download the free “<a href="#" onclick="window.open(
              'https://www.dropbox.com/s/6x3hb0nv667ktd0/' +
              'GuideToUniverse_v7.pdf?dl=0'
              )" target="_blank">Guide to Universe</a>” for an overview of 
              the Universe Public Development project.

            </p>
          </div>

          <div class="mwx-adbody-box mwx-text-box">
            <p>
              Purchase from DriveThru RPG.
            </p>
            <p>
              <b>On sale:  <s>30 USD</s> 13 USD</b>  
            </p>

            <div style="width:75%; margin:auto">
              <a href="#" onclick="window.open(
                'https://www.drivethrurpg.com/' + 
                'product/207263/Universe-Master-Rulebook-Vol-I'
                )" target="_blank">
                <img src="/assets/badges/DTRPG_Badge.png"
                width=150px alt="DriveThru"></a>
            </div>

          </div>

        </div>

      </div>

      <!--  Display when user is signed in.  -->
      <div *ngIf="mwxAuthService.isAuthenticated()">

        <!--  Walk Away ad.  -->
        <div>
          <img class="ad" src="/assets/ads/walk_away.jpg">
        </div>

      </div>

    </div>

  </div>
</div>

