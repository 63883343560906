<div class="container-fluid"
  style="margins: 0px; padding: 0px;"
  align="center">

  <h1><fa-icon [icon]="faUsers"></fa-icon> Campaigns</h1>
  <hr>

  <div class="row justify-content-center">
    <div *ngFor="let cpgn of cpgnlist" class="card-cpgn">
      <div class="row">
        <div class="col">
          <h5>{{ cpgn.name }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>Status: {{ cpgn.status }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>System: {{ cpgn.gamesys }}</p>
          <hr>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col"></div>
        <div class="col">
          <button class="btn btn-dark btn-sm"
            (click)="editCpgn(cpgn._id)">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
          </button>
        </div>
        <div class="col"></div>
        <div class="col">
          <button class="btn btn-dark btn-sm"
            (click)="deleteCpgn(cpgn._id)">
            <fa-icon [icon]="faTrashAlt"></fa-icon>
          </button>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col" style="margin:10px; padding:10px">
      <button class="btn btn-dark btn-sm">Create Campaign</button>
    </div>
  </div>

</div>

