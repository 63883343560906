const id = "mwx-pvgd//pvgd-menu/";

import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators,
  AbstractControl
} from '@angular/forms';
import { BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { PvgdCharService
} from '@app/pvgd-char/pvgd-char.service';
import { PvgdMenuService } from './pvgd-menu.service';
import { faToolbox, faUser, faUserShield, faUsers, faUserPlus, faPencilAlt,
  faCog, faDragon, faSkull, faMapMarkedAlt, faWindowClose, faCheckSquare
} from '@fortawesome/free-solid-svg-icons';

//  Strucutre for character data.
interface Char {
  _id: string,
  name: string,
};

//  Strucutre for campaign data.
interface Cpgn {
  _id: string,
  name: string,
};

//  Strucutre for game box data.
interface Game {
  _id: string,
  name: string,
};

@Component({
  selector: 'app-pvgd-menu',
  templateUrl: './pvgd-menu.component.html',
  styleUrls: ['./pvgd-menu.component.css']
})
export class PvgdMenuComponent implements OnInit {

  //  Links between the HTML DOM and the typescript.
  @ViewChild('newChar') newChar: TemplateRef<any>;
  @ViewChild('newCpgn') newCpgn: TemplateRef<any>;
  @ViewChild('newGame') newGame: TemplateRef<any>;

  //  Forms in the modals that create new user objects.
  private newCharForm: UntypedFormGroup;
  private newCpgnForm: UntypedFormGroup;
  private newGameForm: UntypedFormGroup;

  //  Counts and lists of user objects pulled from the API.
  private charct = 0;
  private cpgnct = 0;
  private gamect = 0;
  private charlist: Char[] = [];
  private cpgnlist: Cpgn[] = [];
  private gamelist: Game[] = [];

  //  Font Icons
  faToolbox = faToolbox;
  faUser = faUser;
  faUserShield = faUserShield;
  faUsers = faUsers;
  faUserPlus = faUserPlus;
  faPencilAlt = faPencilAlt;
  faCog = faCog;
  faDragon = faDragon;
  faSkull = faSkull;
  faMapMarkedAlt = faMapMarkedAlt;
  faWindowClose = faWindowClose;
  faCheckSquare = faCheckSquare;

  //  Message Queue
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdMenu",            //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  //  MWX -- This is the worst possible place and way to handle
  //  MWX -- this.  It needs to be handled better.
  private gameSystems = [
    "Select Game System...",
    "System Agnostic",
    "Universe, The Sci-Fi RPG",
    "Tyro Universe, The Introductory Sci-Fi RPG",
    "Realm, The Fantasy RPG",
    "Orphans' Realm, The Introductory Fantasy RPG"
  ];

  //  Default text message on the modal.
  private message_txt: string = 'All fields required.';

  constructor(
    public mwxAuthSvc: MwxAuthService,
    private modalSvc: BsModalService,
    private pvgdMenuSvc: PvgdMenuService,
    private pvgdCharSvc: PvgdCharService,
    private messageBus: MessageBus,
    private formBldr: UntypedFormBuilder,
    private chngDetRef: ChangeDetectorRef
  ) { }

  /**  On initialization, creates the new character, campaign,
       and game box forms.  Connects to the message queue.  */
  ngOnInit() {
    const fid = id + "ngOnInit(): ";

    //  Create New Character form.
    this.createNewCharForm();
    this.newCharForm.controls['gameSys'].setValue(this.gameSystems[0],
      {onlySelf:true});

    //  Create New Campaign form.
    this.createNewCpgnForm();
    this.newCpgnForm.controls['gameSys'].setValue(this.gameSystems[0],
      {onlySelf:true});

    //  Create New Game Box form.
    this.createNewGameForm();
    this.newGameForm.controls['gameSys'].setValue(this.gameSystems[0],
      {onlySelf:true});

    //  Connect message queue.
    this.msgq.msgqConnection =
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  /**  After the initialization of the menu components, sets the
       handler for received messages received via the message queue.  */
  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

    if(this.mwxAuthSvc.isAuthenticated()) {
      this.queryDBforUserAssets();
    }
  }

  /**  On the tab destruction, disconnect from the message queue.  */
  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  /**  Query the database via the API for the user's top characters,
       campaigns, and game boxes.  */
  queryDBforUserAssets(): void {
    const fid = id + "queryDBforUserAssets(): ";

    //  Listen for API responses.
    this.pvgdMenuSvc.getMenuList()
      .subscribe(data => {

        if(data.charList) {
          this.charct = 0;
          this.charlist.length = 0;
          for (const d of (data.charList as any)) {
            this.charct += 1;
            this.charlist.push({
              _id: (d._id),
              name: (d.charName) ? d.charName : "None"
            });
          }
        } else {
          this.charlist = [ ];
        }

        if(data.cpgnList) {
          this.cpgnct = 0;
          this.cpgnlist.length = 0;
          for (const d of (data.cpgnList as any)) {
            this.cpgnct += 1;
            this.cpgnlist.push({
              _id: (d._id),
              name: (d.cpgnName) ? d.cpgnName : "None"
            });
          }
        } else {
          this.cpgnlist = [ ];
        }

        if(data.gameList) {
          this.gamect = 0;
          this.gamelist.length = 0;
          for (const d of (data.gameList as any)) {
            this.gamect += 1;
            this.gamelist.push({
              _id: (d._id),
              name: (d.gameName) ? d.gameName : "None"
            });
          }
        } else {
          this.gamelist = [ ];
        }

        this.refreshMenu();

      });
  }

  /**  Handle messages received via the message queue.  Currently used
       to open the modals for creating a new character, campaign, or
       game box.
       @param {any} data Contains the message's command identifier.  */
  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";

    //  Parse the received command.
    if(data.cmd == 0x0004) {
      //  User logged in.
      this.queryDBforUserAssets();
    } else if(data.cmd == 0x1205) {
      //  Open modal for creating a new Character
      this.openNewChar();
    } else if(data.cmd == 0x1206) {
      //  Open modal for creating a new Campaign
      this.openNewCpgn();
    } else if(data.cmd == 0x1207) {
      //  Open modal for creating a new Game Box
      this.openNewGame();
    } else {
      console.log(fid + "ERROR: " + JSON.stringify(data));
    }

  }

  public refreshMenu() {
    const fid = id + "refreshMenu(): ";

    this.chngDetRef.detectChanges();
  }

  /**  Send a message over the message queue that requests
       a specific tab be opened.  */
  private requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

  /**  Build the character form for the new character modal.  */
  private createNewCharForm(): void {
    const fid = id + "createNewCharForm(): ";

    this.newCharForm = this.formBldr.group({
      charName: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      gameSys: new UntypedFormControl('', {
        validators: [Validators.required, this.validateGameSys]
      })
    });
  }

  /**  Build the campaign form for the new campaign modal.  */
  private createNewCpgnForm(): void {
    const fid = id + "createNewCpgnForm(): ";

    this.newCpgnForm = this.formBldr.group({
      cpgnName: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      gameSys: new UntypedFormControl('', {
        validators: [Validators.required, this.validateGameSys]
      })
    });
  }

  /**  Build the game box form for the new game box modal.  */
  private createNewGameForm(): void {
    const fid = id + "createNewGameForm(): ";

    this.newGameForm = this.formBldr.group({
      gameName: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      gameSys: new UntypedFormControl('', {
        validators: [Validators.required, this.validateGameSys]
      })
    });
  }

  /**  Open the modal for creating a new character.  */
  private openNewChar() {
    const fid = id + "openNewChar(): ";
    this.modalSvc.show(this.newChar, { class: 'modal-sm' });
  }

  /*  Open the modal for creating a new campaign.  */
  private openNewCpgn() {
    const fid = id + "openNewCpgn(): ";
    this.modalSvc.show(this.newCpgn, { class: 'modal-sm' });
  }

  /*  Open the modal for creating a new game box.  */
  private openNewGame() {
    const fid = id + "openNewGame(): ";
    this.modalSvc.show(this.newGame, { class: 'modal-sm' });
  }

  /**  Hide the new character modal.  */
  private hideNewChar() {
    this.modalSvc.hide();
  }

  /**  Hide the new campaign modal.  */
  private hideNewCpgn() {
    this.modalSvc.hide();
  }

  /**  Hide the new game box modal.  */
  private hideNewGame() {
    this.modalSvc.hide();
  }

  /**  Execute on the creation of a new character.  */
  private onNewChar(): void {
    const fid = id + "onNewChar(): ";

    if(this.newCharForm.valid) {
      const { charName, gameSys } = this.newCharForm.value

      let gameSystem = "";
      if(gameSys == this.gameSystems[1]) {
        gameSystem = "univ";
      } else if(gameSys == this.gameSystems[2]) {
        gameSystem = "tyro";
      } else if(gameSys == this.gameSystems[3]) {
        gameSystem = "relm";
      } else if(gameSys == this.gameSystems[4]) {
        gameSystem = "orph";
      } else if(gameSys == "Star Frontiers") {
        gameSystem = "sfrn";
      } else if(gameSys == this.gameSystems[5]) {
        gameSystem = "agns";
      } else {
        gameSystem = "";
      }
      console.log(fid + "name: " + charName + ", sys: " + gameSystem);

      this.pvgdCharSvc
        .createChar(charName, gameSystem)
        .subscribe(
          response => console.log(fid + "response: " + response),
          err => console.log(fid + "ERROR: " + err)
        );
    } else {
      console.log(fid + "ERROR: newCharForm invalid");
    }
    this.modalSvc.hide();
    this.newCharForm.patchValue({charName: "", gameSys: undefined});
  }

  /**  Execute on the creation of a new campaign.  */
  private onNewCpgn(): void {
    const fid = id + "onNewCpgn(): ";

    if(this.newCpgnForm.valid) {
      const { cpgnname, gamesys } = this.newCpgnForm.value;

    }
    this.modalSvc.hide();

  }

  /**  Execute on the creation of a new game box.  */
  private onNewGame(): void {
    const fid = id + "onNewGame(): ";

    if(this.newGameForm.valid) {
      const { gamename, gamesys } = this.newGameForm.value;

    }
    this.modalSvc.hide();

  }

  /**  Open the modal that prompts the character to signup for
       a new user account, or to sign in to an existing user
       account.  */
  private openSign(): boolean {
    const fid = id + "openSign(): ";

    //  Send a message to MwxSignComponent to open the signup/in modal.
    const message:Message<any> = {
      recipientIds: ["mwxSign"],
      payload: { },
      groupId: null
    };
    this.msgq.msgqConnection.post(message);

    //  If the function does not return false, the href will be executed
    //  rather than the javascript.
    return(false);
  }

  /**  Confirm that the indicated game system is a valid game
       within Proving Ground
       @param {AbstractControl} gamesys The indicated game system.
       @returns An error message if failed, or null if successful.  */
  private validateGameSys(gamesys: AbstractControl) {
    const fid = id + "validateGameSys(): ";

    if(!(gamesys.value == "Universe, The Sci-Fi RPG")&&
      !(gamesys.value == "Tyro Universe, The Introductory Sci-Fi RPG")&&
      !(gamesys.value == "Orphans' Realm, The Introductory Fantasy RPG")&&
      !(gamesys.value == "System Agnostic")
    ) {
      return {message: "Must be a supported game system"};
    } else {
      return null;
    }
  }

}

