<div>

  <div class="hdrRow" align="center">
    <div class="hdrCol rounded" align="center">
      <img class="hdrImg" src="/assets/logos/StarFron_Logo_Light.svg">
    </div>
  </div>

  <div class="row">

    <div class="col" align="left">

      <h2 align="center">Star Frontiers Info</h2>
      <hr>

      <p><b>Star Frontiers</b> is a science fiction role-playing game
      developed by TSR, Inc. in 1982.  It is no longer in production.
      Downloads of the original Star Frontiers game can be obtained
      lawfully at this <a href="http://starfrontiers.com/" target="_blank">
      link.</a></p>
      
    </div>
    <div class="col" align="left">

      <h2 align="center">Tools</h2>
      <hr>

      <p>In development.</p>
    </div>

    <div class="col" style="col" align="left">

      <h2 align="center">Products</h2>
      <hr>

      <div *ngFor="let item of items">
        <h5>{{item.name}}</h5>
        <img class='prodImg' src='{{item.thumbURI}}'>
        <p>{{item.desc}}</p>
        <p align="right">
          <span class='{{item.priceSymbol}}'></span>
          <b>{{item.priceText}}</b> &nbsp;
          <button class="btn btn-dark btn-sm prod" type="submit"
            (click)="clickURI(item)" >
            <span class='{{item.btnSymbol}}'></span>
            {{item.btnText}}
          </button>
        </p>
        <hr>
      </div>

    </div>

  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>The "Star Frontiers" trademark was cancelled in 
        April 2004.</small>
    </div>
  </div>

</div>

