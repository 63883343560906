const id = "mwx-pvgd//mwx-auth/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import * as openpgp from 'openpgp';
import { environment } from '@environments/environment';
import * as stream from '@openpgp/web-stream-tools';

import { NewUser } from './mwx-user.model';
import { Credentials } from './credentials.model';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';


/*  Use of OpenPGP is given in the examples on this webpage:
    https://github.com/openpgpjs/openpgpjs/blob/main/README.md#encrypt-and-decrypt-string-data-with-pgp-keys
*/

@Injectable({
  providedIn: 'root'
})
export class MwxAuthService {

  /*  CORS Options  */

  /**  Sets the HTTP options for Cross Origin Resource Sharing (CORS).  */
  httpOptions = {
    withCredentials: true,
    Headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "mwxAuth",             //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  /**  Instantiate the service.
       @param {HttpClient} http Required for HTTP communications.
       @param {MessageBus} messageBus Required for message queue communications.
  */
  constructor(private http: HttpClient, 
    private messageBus: MessageBus) { 
    const fid = id + "constructor(): ";
    this.initialize();
  }

  /*  As this is a servive, neither ngOnInit nor ngAfterContentInit 
      are called.  This fills that same purpose.  */
  initialize() {
    const fid = id + "initialize(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";
    console.log(fid);

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  /**  Handle message queue event form constructor subscription.  At 
       present, no events are handled; events are only output to the 
       console.
       @param {any} data
  */
  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  /**  Encryption function for passwords.  
       @param {string} user_password The user's indicated password to 
       be encrypted.
       @returns {} The encrypted version of the user's password.
  */
  async encryptFunction(user_password:string) {
    const fid = id + "encryptFunction(): ";

    const publicKeyArmored = environment.MARKWILX_PGP_KEY;
    const publicKey = await openpgp.readKey({armoredKey: publicKeyArmored});

    const encryptedData = await openpgp.encrypt({
      message: await openpgp.createMessage({text: user_password}),
      encryptionKeys: publicKey
    });

    return(encryptedData);
  }

  /** Decrypts the data passed to the function.
      @param {} encryptedData The data to be decrypted.
      @returns {} The decrypted data
  */
  async decryptFunction(encryptedData) {
    const fid = id + "decryptFunction(): ";

    const publicKeyArmored = environment.MARKWILX_PGP_KEY;
    const privateKeyArmored = environment.MARKWILX_PGP_PRIVKEY;
    const passphrase = environment.MARKWILX_PGP_PASSPHRASE;

    const publicKey = await openpgp.readKey({armoredKey: publicKeyArmored});
    const privateKey = await openpgp.decryptKey({ 
      privateKey: await openpgp.readPrivateKey( {armoredKey: privateKeyArmored }),
      passphrase
    });

    const message = await openpgp.readMessage({
      armoredMessage: encryptedData
    });

    const { data: decryptedData, signatures } = await openpgp.decrypt({
      message,
      verificationKeys: publicKey,
      decryptionKeys: privateKey
    });

    //  Return decrypted data.
    return(decryptedData);
  }

  /** Encrypt the password used for signup using the MARKWILX_PGP_KEY.
      @param {NewUser} newUser User object containing the data for the 
        new user; newUser.password will be encrypted.
      @returns {NewUser} Returns the structure with the encrypted 
        password stored in newUser.encpass.  The newUser.password value 
        is cleared.
  */
  async encryptSignupPwd(newUser: NewUser) {
    const fid = id + "encryptPasswd(): ";

    const publicKeyArmored = environment.MARKWILX_PGP_KEY;
    const publicKey = await openpgp.readKey({armoredKey: publicKeyArmored});

    const encryptedPass = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: newUser.password}),
      encryptionKeys: publicKey
    });

    newUser.password = "";
    newUser.encpass = encryptedPass as string;

    return(newUser);
  }

  /** Encrypt the sign in password of an existing user using 
      the MARKWILX_PGP_KEY.
      @param {Credentials} credentials An object containing the 
        sign in password in credentials.password.
      @returns {Credentials} The object is returned containing the 
        encrypted password in credentials.encpass.  The 
        credentials.password value is cleared.
  */
  async encryptSigninPwd(credentials: Credentials) {
    const fid = id + "encryptPasswd(): ";

    const publicKeyArmored = environment.MARKWILX_PGP_KEY;
    const publicKey = await openpgp.readKey({armoredKey: publicKeyArmored});

    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: credentials.password }),
      encryptionKeys: publicKey,
      format: 'armored'
    });

    //  Clear the unencryped password, and insert the encrypted password.
    credentials.password = "";
    credentials.encpass = encrypted as string;

    //  Return the credentials.
    return(credentials);
  }

  /** Decrypt the user's password.
      @param {Credentials} credentials Pass the password in the 
        credentials.encpass field.
      @returns {} The decrypted password.
  */
  async decryptPasswd(credentials: Credentials) {
    const fid = id + "decryptPasswd(): ";
    const publicKeyArmored = environment.MARKWILX_PGP_KEY;
    const privateKeyArmored = environment.MARKWILX_PGP_PRIVKEY;
    const passphrase = environment.MARKWILX_PGP_PASSPHRASE;

    const publicKey = await openpgp.readKey({armoredKey: publicKeyArmored});

    const privateKey = await openpgp.decryptKey({ 
      privateKey: await openpgp.readPrivateKey( {armoredKey: privateKeyArmored }),
      passphrase
    });

    const message = await openpgp.readMessage({
      armoredMessage: credentials.encpass
    });

    const { data: decrypted, signatures } = await openpgp.decrypt({
      message,
      verificationKeys: publicKey,
      decryptionKeys: privateKey
    });

    //  Return decrypted password.
    return(decrypted);
  }

  /** Post a new user's data to the API to create a new user.  Forward 
      the returned results.
      @param {NewUser} newUser Object containing the data for creating 
        the new user.
      @returns {Observable} Result of the API call.
  */
  public signup(newUser: NewUser): Observable<any> {
    const fid = id + "signup(): ";

    var uri = environment.MARKWILX_API_URI;
    uri += '/api/mwx-user';

    let signupResult;
    return from(
      this.encryptSignupPwd(newUser)
    ).pipe(mergeMap( newUser => {
      signupResult = 
        this.http.post<NewUser>( uri, { ...newUser }, this.httpOptions);
      return signupResult;
    }));
  }

  /** Query if the current user is authenticated.  This validates 
        against the JWT to determine if the session is still valid.
      @returns {boolean} Returns 'true' if the user's JWT is valid; 
        'false' otherwise.
  */
  public isAuthenticated(): boolean {
    const fid = id + "isAuthenticated(): ";

    let isAuthenticated = false;
    if(this.getAuthToken()) isAuthenticated = true;
    return isAuthenticated;

  }

  /** Passed the signin username and password, builds the credentials 
      object, encrypts the password, and accesses the API to transmit 
      the credentials.
      @param {string} usernameOrEmail The screen name or email address 
        of the user.
      @param {string} password The unencrypted user password, as entered 
        by the user.
      @returns {Observable} Results of the API call.
  */
  public login(usernameOrEmail: string, password: string): Observable<any> {
    const fid = id + "login(): ";

    /*  MWX -- This needs to be updated to mwx-auth.  */
    var uri = environment.MARKWILX_API_URI;
    uri += '/api/mwx-auth';

    let encpass = "begin";
    const credentials: Credentials = {
      usernameOrEmail,
      password,
      encpass
    };

    let loginResult;
    return from(
      this.encryptSigninPwd(credentials)
    ).pipe(mergeMap( credentials => {
      loginResult = 
        this.http.post(uri, { ...credentials });
      return loginResult;
    }));

  }

  /** Set the user's JWT authentication token by saving it in local storage.
      @param {string} token The user's JWT authentication token.
  */
  public setAuthToken(token: string): void {
    const fid = id + "setAuthToken(): ";
    localStorage.setItem('auth_token', token);
  }

  /** Get the user's JWT authentication token from local storage.
      @returns {string} The user's JWT authentiation token.
  */
  public getAuthToken(): string {
    const fid = id + "getAuthToken(): ";

    const auth_token = localStorage.getItem('auth_token');
    if(auth_token) return auth_token;
    else return null;
  }

  /**  Terminate the user's session by closing all open tabs, terminating 
       socket connections, deleting the JWT authentication token from 
       local storage, and sending a signout message to the API.
       @returns {Observable} Results of the API call.
  */
  public signout(): Observable<any> {
    const fid = id + "signout(): ";

    //  Remove JWT authentication token from local storage.
    localStorage.removeItem('auth_token');

    //  Send a message indicating signout, which will 
    //  close all tabs.
    const message_signout:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x0003, data: null, hdg: null },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message_signout);

    return this.http.post('/api/signout', null, this.httpOptions);
  } 
}
