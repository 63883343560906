const id = "mwx-pvgd//tyro-tools/";

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MwxCatalogService } from '@app/mwx-auth/mwx-catalog.service';

@Component({
  selector: 'app-sfrn-tools',
  templateUrl: './sfrn-tools.component.html',
  styleUrls: ['./sfrn-tools.component.css']
})
export class SfrnToolsComponent implements OnInit {

  items = [ ];

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private mwxCatalogService: MwxCatalogService) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    //  Load catalog items.
    this.getCatalogItems();
  }

  private getCatalogItems(): void {
    const fid = id + "getItems(): ";

    this.mwxCatalogService.getCatalogItems('sfrn').subscribe(
      data => {
        this.items = data.result;
        this.chngDetRef.detectChanges();
      },
      err => {
        console.log(fid + "ERROR: " + JSON.stringify(err));
      }
    );
  }

  private clickURI(item) {
    const fid = id + "clickURI: ";
    //console.log(fid + "Click: " + item.linkURI);
    window.open(item.linkURI);
  }

}
