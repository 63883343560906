<div class="container-fluid"
  style="margins: 0px; padding: 0px;"
  align=center>

  <h1><fa-icon [icon]="faAddressCard"></fa-icon> User Profile</h1>
  <hr>

  <div class="row justify-content-center">

    <div class="col">
      <h3>User Toolbox</h3>
      <hr>

      <p style="
        border-radius: 15px;
        background: #000000;
        margin: 25px; 
        border: 0px;
        padding: 20px;">
        <a href="#" 
          onclick="window.open('https://www.patreon.com/MarkWilx')" 
          target="_blank">
          <img style="width: 180px;" 
            src="assets/logos/Digital-Patreon-Wordmark_FieryCoral.png"
            alt="Patreon">
        </a>
      </p>

      <p>Our goal is to provide a free gaming experience for all, 
        unburdened by advertisements and subscriptions.  To achieve
        this goal, we are soliciting game companies for sponsorships 
        and gaming enthusiasts for patronships.</p>

      <p>If you're in a situation to provide a free experience to new
        gamers or those less fortunate, we encourage you to support this
        Proving Ground Game's virtual tabletop through our page on 
        <a href="#" 
          onclick="window.open('https://www.patreon.com/MarkWilx')" 
          target="_blank">Patreon</a>.

      <p>
        <button class="btn btn-dark btn-sm" 
          (click)="clickURI('https://www.patreon.com/MarkWilx')">
          <fa-icon [icon]="faPatreon"></fa-icon> Patreon
        </button>
      </p>

      <hr>

      <p>
        <button class="btn btn-dark btn-sm" disabled
          (click)="requestTab('')">
          <fa-icon [icon]="faClipboard"></fa-icon> Patron
        </button>
      </p>

      <p>
        <button class="btn btn-dark btn-sm" disabled
          (click)="requestTab('')">
          <fa-icon [icon]="faClipboard"></fa-icon> Administrator
        </button>
      </p>

      <p>
        <button class="btn btn-dark btn-sm"
          (click)="requestTab('mwxDevelopment')">
          <fa-icon [icon]="faClipboard"></fa-icon> Developer
        </button>
      </p>

      <p>
        <button class="btn btn-dark btn-sm" disabled
          (click)="requestTab('')">
          <fa-icon [icon]="faClipboard"></fa-icon> Playtester
        </button>
      </p>

    </div>

    <div class="col">
      <h3>Information</h3>
      <hr>
      <table class="table">
        <tbody>
          <tr>
            <th>Screen Name</th>
            <td>{{ screenName }}</td>
          </tr>
          <tr>
            <th>Username</th>
            <td>{{ screenName }}</td>
          </tr>
          <tr>
            <th>Patron</th>
            <td>No</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ email }}</td>
          </tr>
          <tr>
            <th>Verified</th>
            <td>No</td>
          </tr>
          <tr>
            <th>Last Login</th>
            <td>{{ lastLogin }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col">
      <h3>Account Utilities</h3>
      <hr>

      <h5>Change Screen Name</h5>
      <p><i>In development</i></p>
      <p>Need input for new screen name, and save button.</p>
      <hr>

      <h5>Change Email Address</h5>
      <p><i>In development</i></p>
      <p>Need input for password, new email address, and
        save button. Need note indicating email verification 
        will be invalidated.</p>
      <hr>

      <h5>Change Password</h5>
      <p><i>In development</i></p>
      <p>Need input for current password, new password, and 
        save button.  Need note indicating the user will be 
        auto-logout when they hit the save button and will need
        to log back in with new credentials.</p>
      <hr>

    </div>

  </div>
</div>

