const id = "mwx-pvgd//mwx-catalog-service/";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
  
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MwxCatalogService {

  constructor(private http: HttpClient,
    private mwxAuthService: MwxAuthService) { }

  public getCatalogItems(line: string): Observable<any> {

    /*  API Information  */
    const uri = environment.MARKWILX_API_URI +
      '/api/opol-catalog' +
      '?line=' + line;

    return this.http.get(uri);
  }
}
