const id = "mwx-pvgd//univ-util-timeline/";

import { Component, OnInit, NgModule } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, FormControl,
  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnivUtilTimelineService } from './univ-util-timeline.service';

// MWX -- Design of checkboxes:
// MWX -- https://www.positronx.io/angular-checkbox-tutorial/

@Component({
  selector: 'app-univ-util-timeline',
  templateUrl: './univ-util-timeline.component.html',
  styleUrls: ['./univ-util-timeline.component.css']
})
export class UnivUtilTimelineComponent implements OnInit {

  public timeline: any[] = [];

  public filterListForm: UntypedFormGroup;

  public isChecked: boolean = false;

  public filterCategories = [
    { id: "galc", name: 'Galactic Caucus', tag: 'isGalacticCaucus' },
    { id: "chal", name: 'The Grand Consortium (Chalmon)', tag: 'isChalmon' },
    { id: "grae", name: 'Dominion of Tredurik Graeth)', tag: 'isGraeth' },
    { id: "lhak", name: 'Ceptanin League (Lhaknaar)', tag: 'isLhaknaar' },
    { id: "sluv", name: 'Supremacy of Bhelgard (Sluve)', tag: 'isSluve' },
    { id: "govi", name: 'Govit', tag: 'isGovit' },
    { id: "geno", name: 'Sentimar League (Genoff)', tag: 'isGenoff' },
    { id: "mech", name: 'The Mechanoid', tag: 'isMechanoid' }
  ];

  constructor(
    private univUtilTimelineService: UnivUtilTimelineService,
    private formBuilder: UntypedFormBuilder
  ) { 
    this.filterListForm = this.formBuilder.group({
      //checkArray: this.formBuilder.array([])
      //filterCategories: this.formBuilder.array([])
      galc: [true],
      chal: [false],
      grae: [true],
      lhak: [false],
      sluv: [true],
      govi: [false],
      geno: [true],
      mech: [false],
      a_test: [false]
    });
  }

  ngOnInit() {
    /*  MWX -- This needs to be updated to pass the search parameters
        MWX -- for the initial search of the Timeline Events.  These
        MWX -- will be the same variables used for the user to enter 
        MWX -- their own search.  */
    const fid = id + "ngOnInit(): "; 
    //console.log(fid); 

    //this.getTimelineEvents(true, false, false, false, false, false, false);
    this.getTimelineEvents();
  }

  public doCheck(checked: boolean) {
    const fid = id + "doCheck(): ";
    this.isChecked = !this.isChecked;
    console.log(fid + "event is " + checked);
    console.log(fid + "isChecked equals " + this.isChecked);
/*
    console.log(fid + "FormGroup before is " + 
      this.filterListForm.get('a_test').value)
    this.filterListForm.patchValue({'a_test': this.isChecked});
    console.log(fid + "FormGroup after is " + 
      this.filterListForm.get('a_test').value)
*/
    //this.filterListForm.patchValue({'a_test': this.isChecked});
    this.filterListForm.patchValue({'a_test': true});
    //this.filterListForm.reset();
  }

  public getTimelineEvents(
    //isGalacticCaucus: boolean,
    //isChalmon: boolean,
    //isGraeth: boolean,
    //isLhaknaar: boolean,
    //isSluve: boolean,
    //isGovit: boolean,
    //isGenoff: boolean
  ): void {
    const fid = id + "getTimelineEvents(): "; 
    //console.log(fid);
    //console.log(fid + "isGalacticCaucus " + isGalacticCaucus);
    //console.log(fid + "isChalmon " + isChalmon);
    //console.log(fid + "isGraeth " + isGraeth);
    //console.log(fid + "isLhaknaar " + isLhaknaar);
    //console.log(fid + "isSluve " + isSluve);
    //console.log(fid + "isGovit " + isGovit);
    //console.log(fid + "isGenoff " + isGenoff);
    this.univUtilTimelineService.getTimelineEvents(
      true, false, false, false, false, false, true
      //isGalacticCaucus, isChalmon, isGraeth, isLhaknaar, 
      //isSluve, isGovit, isGenoff
    ).subscribe(
      data => {
        this.timeline = data.result;
      },
      err => {
        console.log(fid + err);
      }
    );
  }
}

