import { Component, OnInit } from '@angular/core';

import { faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pvgd-xmpl-cpgn',
  templateUrl: './pvgd-xmpl-cpgn.component.html',
  styleUrls: ['./pvgd-xmpl-cpgn.component.css']
})
export class PvgdXmplCpgnComponent implements OnInit {

  faUsers = faUsers;

  setting = "After escaping the attack in Malatah and rescuing Ellina from the orcs, you've settled in Forten Village to await the arrival of the Long Caravan, which can safely take you to Grandmala.  You're certain your passage down the river and across the ocean to Fleche will be expensive.  Any coins your earn here in Forten will serve you well.";

  vttLib = [
    {id: 1, isClpsd: true, libName: "X'ample & X'emplars", 
      desc: "Resources available only to this campaign." },
    {id: 2, isClpsd: true, libName: "Fantasy Scoundrel Pack", 
      desc: "This pack contains a number of tokens for brigands, pirates, outlaws, and other villany.  It " + 
      "additionally contains maps for a tavern with hidden rooms, a pirate sloop, a wilderness" +
      "bandit camp, and a villian dedoubt." },
    {id: 3, isClpsd: true, libName: "Fantasy Town Pack", 
      desc: "The town pack includes tokens for civil members of society including, clergy, elven travelers, " +
      "dwarven travelers, craftsmen, men-at-arms, merchants, noblemen & women, peasant, trademen, " +
      "and animals including dogs, horses, donkies, and rats.  Maps include a tavern, blacksmith, grain " +
      "mill, street market, peasant home, nobleman home, and barracks." },
    {id: 4, isClpsd: true, libName: "Fantasy Forest Pack", 
      desc: "Fey and foul denizen tokens in this pack include imps, sprites, arachus, bears, boars, " +
      "falcon, ravens, and simarids.  Maps include forest trails, an arachus nest, and a simarid nidus." },
    {id: 5, isClpsd: true, libName: "Fantasy Hills Pack", 
      desc: "This pack contains tokens for residents of hilly wilderness, including goblins, orgres, " +
      "orcs, drakes, and wolves.  Maps include wilderness locations and an orc village." },
    {id: 6, isClpsd: true, libName: "Fantasy Cave Pack", 
      desc: "Tokens for denizens of the deep including feral ogres, feral goblins, rats, " +
      "skeletons, zombies are included in this pack.  Maps include natural and crafted caves, and " +
      "a deep mine." },
  ];

  vttRes = [
    {id: 1, isClpsd: false, resName: "Sothus Abbey Ruins", isMap: true, isHandout: false, isVis: true,
      desc: "After rescuing Elina from orcs, she led you here to weather the storm. " },
    {id: 2, isClpsd: false, resName: "Forten Village", isMap: true, isHandout: false, isVis: true,
      desc: "You are stranded in the small, but friendly, Village of Forten until the Long Caravan " +
      "arrives this fall." },
    {id: 3, isClpsd: true, resName: "Lost Shrine of Mirethus", isMap: true, isHandout: false, isVis: false,
      desc: "You discovered an ancient shrine below the Sothus Abbey." },
    {id: 4, isClpsd: false, resName: "Adventure Background", isMap: false, isHandout: true, isVis: true,
      desc: "This document gives the adventure's background." },
    {id: 5, isClpsd: false, resName: "Catacomb Runes", isMap: false, isHandout: true, isVis: true,
      desc: "These runes are engraved on the floor of Forten's abbey." },
    {id: 6, isClpsd: true, resName: "Shrine Runes", isMap: false, isHandout: true, isVis: false,
      desc: "You found these runes concealed on the floor of Sothus Abbey." },
    {id: 7, isClpsd: true, resName: "Secret Plans", isMap: false, isHandout: true, isVis: false,
      desc: "After defeating the orcs, you captured these secret plans." },
    {id: 8, isClpsd: true, resName: "Ancient Map", isMap: false, isHandout: true, isVis: false, 
      desc: "Deep in the Lost Shrine of Mirethus, you discovered this ancient map." }
  ];

  chars = [
    {id: 1, isClpsd: true, charName: "Noble Gruzzen", plyrName: "Phredzie", isJoined: true, color: "#AA0000" },
    {id: 2, isClpsd: false, charName: "Skrudg the Terrible", plyrName: "GeoffMan", isJoined: false, color: "#AAAA00" },
    {id: 3, isClpsd: true, charName: "Landrae", plyrName: "MaryLittleLamb", isJoined: true, color: "#AA00AA" },
    {id: 4, isClpsd: true, charName: "Moridth", plyrName: "BoringName", isJoined: true, color: "#00AA00" },
    {id: 5, isClpsd: false, charName: "X'ample the Redd", plyrName: "AxxeMan", isJoined: false, color: "#0000AA" },
    {id: 6, isClpsd: true, charName: "Finieous Fingers", plyrName: "JDWebster", isJoined: true, color: "#AA7000" }
    //{id: 7, isClpsd: true, charName: "", plyrName: "Tal-N-Al", status: "Lurker" },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
