const id = "mwx-pvgd//univ-util-perftbl";

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-univ-util-perftbl',
  templateUrl: './univ-util-perftbl.component.html',
  styleUrls: ['./univ-util-perftbl.component.css']
})
export class UnivUtilPerftblComponent implements OnInit {

  skill_rows = [
    '-X',
    '-6',
    '-5',
    '-4',
    '-3',
    '-2',
    'N/-1',
    '0',
    '+1',
    '+2',
    '+3',
    '+4',
    '+5',
    '+6',
    '+7',
    '+8',
    '+9',
    '+10',
    '+X'];

  skill_row = 'N/-1';
  skill_min_success = 35;
  roll = 100;
  roll_result = 0;
  max_dmg = 0;
  mult_dmg = 0;
  final_dmg = 0;
  result_txt = "Roll Dice";

  constructor() { }

  ngOnInit() {
  }

  /*  Random number generator 01-100  */
  randomRoll() {
    var min = Math.ceil(1);
    var max = Math.floor(100);
    this.roll = Math.floor(Math.random() * (max - min)) + min;
  }

  calcResult() {

    const fid = id + "calcResult(): "; 
    if(this.skill_row === "-X") {
      this.skill_min_success = 5;
    } else if(this.skill_row === '-6') {
      this.skill_min_success = 10;
    } else if(this.skill_row === '-5') {
      this.skill_min_success = 15;
    } else if(this.skill_row === '-4') {
      this.skill_min_success = 20;
    } else if(this.skill_row === '-3') {
      this.skill_min_success = 25;
    } else if(this.skill_row === '-2') {
      this.skill_min_success = 30;
    } else if(this.skill_row === 'N/-1') {
      this.skill_min_success = 35;
    } else if(this.skill_row === '0') {
      this.skill_min_success = 40;
    } else if(this.skill_row === '+1') {
      this.skill_min_success = 45;
    } else if(this.skill_row === '+2') {
      this.skill_min_success = 50;
    } else if(this.skill_row === '+3') {
      this.skill_min_success = 55;
    } else if(this.skill_row === '+4') {
      this.skill_min_success = 60;
    } else if(this.skill_row === '+5') {
      this.skill_min_success = 65;
    } else if(this.skill_row === '+6') {
      this.skill_min_success = 70;
    } else if(this.skill_row === '+7') {
      this.skill_min_success = 75;
    } else if(this.skill_row === '+8') {
      this.skill_min_success = 80;
    } else if(this.skill_row === '+9') {
      this.skill_min_success = 85;
    } else if(this.skill_row === '+10') {
      this.skill_min_success = 90;
    } else {
      this.skill_min_success = 95;
    }

    //console.log(fid + "this.skill_row is '" + this.skill_row + "'");
    //console.log(fid + "skill_min_success is " + this.skill_min_success);

    if(this.roll < 1) this.roll = 1;
    if(this.roll > 100) this.roll = 100;
    if(this.roll <= this.skill_min_success) {
      this.roll_result = 10 * Math.trunc(
        (99 - (100 * (this.roll - 1) / this.skill_min_success) + 10 ) / 10
      );
      this.result_txt = this.roll_result + "% Success";
      this.mult_dmg = this.roll_result / 100;
      this.final_dmg = Math.round(this.max_dmg * this.mult_dmg);

    } else {
      this.roll_result = 0;
      this.roll_result = 
        99 - (100 * (this.roll - this.skill_min_success - 1) / 
        (100-this.skill_min_success));
      if(this.roll_result >= 80) {
        this.result_txt = "Marginal Failure";
      } else if(this.roll_result >= 20) {
        this.result_txt = "Total Failure";
      } else {
        this.result_txt = "Critical Failure";
      }
      this.mult_dmg = 0;
      this.final_dmg = 0;
    }

  }

}

