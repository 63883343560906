<button id="mwxSignupBtnModal" class="mwx-btn-inv btn btn-dark btn-sm" 
  (click)="open(mwx_signup_template)">
  <fa-icon [icon]="faUserPlus"></fa-icon>
  New User</button>

<!-- Modal: begin -->
<ng-template #mwx_signup_template>

  <form [formGroup]="signupForm" (ngSubmit)="onSignup()" >
    <div class="modal-header">
      <h4 class="modal-title" id="signupModalLabel">
        <fa-icon [icon]="faUserPlus"></fa-icon>
        New User</h4>
      <button type="button" class="close" aria-label="Close" 
        id="mwxSignupBtnClose"
        (click)="modalRef.hide()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <div class="modal-body">
        <div class="form-group" align="center">
          <div>
            <p id="message_txt_id" 
              [style.background]="messageTxtIdBkgdColor">
              {{ message_txt }}
            </p>
            <p style="color: #D1B68F; background-color: #A63D00; padding:5px;">
              <small>Request a Playtester Code via the Contact button.
              </small>
            </p>
          </div>

          <input id="mwxSignupUsername" 
            class="modal-input" type="text" placeholder="Screen Name"
            formControlName="username">
          <br>
          <input id="mwxSignupEmail"
            class="modal-input" type="email" placeholder="Email"
            formControlName="email">
          <br>
          <input id="mwxSignupPassword"
            class="modal-input" type="password" placeholder="Password"
            formControlName="password">
          <br>
          <input id="mwxSignupCode"
            class="modal-input" type="text" placeholder="Playtester Code"
            formControlName="code">
          <p><small><i>Privacy information at the About 
            page.</i></small></p>
        </div>
    </div>

    <div class="modal-footer">
      <button id="mwxSignupBtnCancel" 
        type="button" 
        class="btn btn-secondary btn-sm"
        (click)="modalRef.hide()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
        Cancel</button>
      <button id="mwxSignupBtnSubmit"
        class="btn btn-primary btn-dark btn-sm btn-default" 
        [disabled]="!signupForm.valid" >
        <fa-icon [icon]="faCheckSquare"></fa-icon>
        Submit</button>
    </div>
  </form>

</ng-template>
<!-- Modal: end -->

