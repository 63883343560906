<div>

  <div class="hdrRow" align="center">
    <div class="rounded hdrCol" align="center">
      <img class="hdrImg" src="/assets/logos/UniverseLogo-Light.svg">
    </div>
  </div>

  <div class="row">
    <div class="col" align="left">
      <div class="container" align="center">

        <accordion class="accrdn" [closeOthers]='true' [isAnimated]='true'>
          <accordion-group id="X1XX">
            <span accordion-heading>Attributes</span>
            <p><a href='#' (click)="getRefItems('group', 'attr', 
              'Cat')">Categorical</a></p>
            <p><a href='#' (click)="getRefItems('group', 'attr', 
              'Alph')">Alphabetical</a></p>
          </accordion-group>

          <accordion-group id="X2XX">
            <span accordion-heading>Abilities</span>
            <p><a href='#' (click)="getRefItems('group', 'abil', 
              'Cat')">Categorical</a></p>
            <p><a href='#' (click)="getRefItems('group', 'abil', 
              'Alph')">Alphabetical</a></p>
          </accordion-group>

          <accordion-group id="X3XX">
            <span accordion-heading>Aptitudes</span>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatAstr')">
              Categorical: Astrospace</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatComm')">
              Categorical: Commercial</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatComp')">
              Categorical: Computer</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatLife')">
              Categorical: Life Science</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatMedi')">
              Categorical: Medical</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatPhys')">
              Categorical: Physical Science</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatPlan')">
              Categorical: Planetary</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatRobo')">
              Categorical: Robotics</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatSoci')">
              Categorical: Social</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatTact')">
              Categorical: Tactical</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatTech')">
              Categorical: Technical</a><p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'CatTran')">
              Categorical: Transporation</a><p>
            <p><a href='#' (click)="getRefItems('group', 'apti', 
              'AlphAB')">Alphabetical: A-B</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'AlphC')">
              Alphabetical: C</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'AlphDG')">
              Alphabetical: D-G</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'AlphHM')">
              Alphabetical: H-M</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'AlphNR')">
              Alphabetical: N-R</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'AlphSU')">
              Alphabetical: S-U</a></p>
            <p><a href='#' (click)="getRefItems('group', 'apti',
              'AlphVZ')">
              Alphabetical: V-Z</a></p>
          </accordion-group>

          <accordion-group disabled=true id="X4XX">
            <span accordion-heading>Aptitudes, Psionic</span>
            <p><i>In Development</i><p>
          </accordion-group>

          <accordion-group id="X5XX">
            <span accordion-heading>Languages</span>
            <p><a href='#' (click)="getRefItems('group', 'lang', 
              'CatWS')">Categorical: Written & Spoken</a></p>
            <p><a href='#' (click)="getRefItems('group', 'lang', 
              'CatSO')">Categorical: Spoken Only</a></p>
            <p><a href='#' (click)="getRefItems('group', 'lang', 
              'CatWO')">Categorical: Written Only</a></p>
            <p><a href='#' (click)="getRefItems('group', 'lang', 
              'CatSgn')">Categorical: Signed Only</a></p>
            <p><a href='#' (click)="getRefItems('group', 'lang', 
              'Alph')">Alphabetical</a></p>
          </accordion-group>

          <accordion-group id="X7XX">
            <span accordion-heading>Equipment</span>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat01')">Categorical: Amenities</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat02')">Categorical: Clothing</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat03')">Categorical: Containers</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat04')">Categorical: Outdoors Gear</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat05')">Categorical: Tack</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat06')">Categorical: Extreme Environment</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat07')">Categorical: Specialized Gear</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat08')">Categorical: Tactical Gear</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat09')">Categorical: Instruments & Toolkits</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat10')">Categorical: Armaments</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat11')">Categorical: Prefabricated Systems</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat12')">Categorical: Cybernetics</a></p>
            <p><a href='#' (click)="getRefItems('group', 'equi', 
              'Cat13')">Categorical: Bioware</a></p>
          </accordion-group>

          <accordion-group disabled=true id="XBXX">
            <span accordion-heading>Races</span>
            <p><i>In Development</i><p>
          </accordion-group>

          <accordion-group disabled=true id="XCXX">
            <span accordion-heading>Worlds</span>
            <p><i>In Development</i><p>
          </accordion-group>

        </accordion>
      </div>

    </div>

    <div class="col" >

      <div class="container" align="center">
        <table class="tbl table-borderless" align="center">
          <thead>
            <tr>
              <th class="tbl-th">Item</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let refitem of ref">
              <td class="tbl-td">
                <a href='#' (click)='getRefItems("detail", 
                type.cat, refitem._id)'> {{ refitem.name }}</a></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col" align="center">
      <hr>
      <small>&copy;Tower Ravens, LLC; all rights reserved.  "Universe, 
        The Sci-Fi RPG" and the galaxy logo are registered 
        trademarks owned by Tower Ravens LLC</small>
    </div>
  </div>

</div>

