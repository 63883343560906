const id = "mwx-pvgd//pvgd-main/";

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxAuthService } from '@app/mwx-auth/mwx-auth.service';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxContactComponent } from '@app/mwx-contact/mwx-contact.component';
import { faFilePdf, faAddressCard, faSignOutAlt, faDiceD20,
  faQuestionCircle, faMapMarkedAlt, faEnvelope, faDraftingCompass } 
  from '@fortawesome/free-solid-svg-icons';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-pvgd-main',
  templateUrl: './pvgd-main.component.html',
  styleUrls: ['./pvgd-main.component.css']
})
export class PvgdMainComponent implements OnInit {

  /*  Font Icons  */
  faFilePdf = faFilePdf;
  faAddressCard = faAddressCard;
  faSignOutAlt = faSignOutAlt;
  faDiceD20 = faDiceD20;
  faQuestionCircle = faQuestionCircle;
  faMapMarkedAlt = faMapMarkedAlt;
  faEnvelope = faEnvelope;
  faDraftingCompass = faDraftingCompass;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "pvgdMain",             //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  constructor(
    public mwxAuthService: MwxAuthService,
    private messageBus: MessageBus) { 
  }

  ngOnInit(): void {
    const fid = id + "ngOnInit(): ";

    //  Connect message queue.
    this.msgq.msgqConnection = 
      this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";

    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }


/*
  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg: hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);

  }
*/

}
