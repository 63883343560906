<div id="modal-header" class="modal-header"
>

  <h4 class="modal-title">
    <fa-icon [icon]="faSignInAlt"></fa-icon>
    Sign In or Create Account
  </h4>

  <button type="button" class="close" aria-label="Close"
    id="mwxSignBtnClose" (click)="bsModalRef.hide()">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>

</div>


<div class="modal-body">
  <div align="center">
    <p>Sign into your account or create a new account to further
      explore the features available in Proving Ground Games.</p>
  </div>
</div>

<div class="modal-footer">
  <app-mwx-signin></app-mwx-signin>
  <app-mwx-signup></app-mwx-signup>
  <button id="mwxSignBtnCancel"
    type="button"
    class="btn btn-secondary btn-sm"
    (click)="bsModalRef.hide()">
    <fa-icon [icon]="faWindowClose"></fa-icon>
    Cancel</button>
</div>

