<div class="row">
  <div class="col">
    <h2><fa-icon [icon]="faUserShield"></fa-icon> X'ample the Redd</h2>
  </div>
</div>

<div class="row">

  <div class="col" align="center">
    <hr>

    <div tooltip="The game system can be left undefined or specified.  
      If specified, additional character tools become available." 
      container="body">
      <h4 align="left">Game System</h4>
        <h5>Orphans' Realm</h5>
      <hr>
    </div>

    <div tooltip="If the character has joined a campaign, that is 
      shown here; otherwise, a tool to join a campaign is displayed."
      container="body">
      <h4 align="left">Campaign</h4>
        <h5>X'ample & X'emplars
          <button style="margin: 5px 5px 0px 20px;" type="button" 
            class="btn btn-dark btn-sm">Open</button>
          <button style="margin: 5px 0px 0px 5px;" type="button" 
            class="btn btn-dark btn-sm">Quit</button>
        </h5>
      <hr>
    </div>

    <div>
      <h4 align="left">Token</h4>
      <div style="padding: 5px 10px 0px 10px;">
        <div class="row">

          <div class="col xmpl boxTokenMelee" >
            <img class="imgTokenMelee" 
              src="/assets/images/xmpl/xmpl-token.png" 
              tooltip="In close-in combat, the selected image portrays 
              the character.  Click to upload." container="body" />
          </div>

          <div class="col xmpl boxTokenRanged" align="center">
            <svg 
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="27" height="27"
              tooltip="When zoomed out, a smaller image is displayed.  
              Click to upload." container="body">
              <image width="25" height="25" [attr.xlink:href]="char_icon" />
              <circle cx="14" cy="14" r="12" [attr.stroke]="charColor" 
              stroke-width="3" fill-opacity="0"  />
            </svg>
          </div>

          <div class="col xmpl boxTokenExplore" align="center"
            tooltip="At large scales, only a dot of the character's 
            color is displayed." >
            <svg width="16" height="25">
              <circle cx="8" cy="15" r="6" [attr.fill]="charColor" />
            </svg>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p align="left">Token Color</p>
            <div class="col" >
              <input 
                readonly
                type="type" 
                style="height:30px; width:50px"
                [(colorPicker)]="charColor" 
                [style.background]="charColor" 
                cpAlphaChannel="disabled"
                cpCancelButton=true
                cpOKButton=true
                tooltip="The character's color is easily set."
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p align="left">Token Text</p>
            <textarea id="charHover" name="charHover" cols="35" rows="8" 
              readonly style="resize: both; overflow: auto; width:100%;"
              tooltip="This information is shown on the virtual tabletop when 
              hovering over your character's token." 
              >{{char_hover}}</textarea>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col" align="center">
    <div 
      tooltip="Character stats, specific to the game system, can be 
      manually entered or generated and maintained by Proving Ground." 
      container="body">
      <hr>
      <h4 align="left">Character Stats</h4>
      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdAttr = !isClpsdAttr"
          [attr.aria-expanded]="!isClpsdAttr" 
          aria-controls="collapseBasic">Attributes</button>
        <div id="charAttr" [collapse]="isClpsdAttr" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_orph_attr | nl2br">
        </div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdFght = !isClpsdFght"
          [attr.aria-expanded]="!isClpsdFght" 
          aria-controls="collapseBasic">Fighting</button>
        <div id="charFght" [collapse]="isClpsdFght" [isAnimated]="true" 
          align="left"
          style="background-color:rgba(128,128,128,0.5); margin:0px; 
          padding:10px; line-height:130%" 
          [innerHTML]="char_orph_fght | nl2br">
        </div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdMods = !isClpsdMods"
          [attr.aria-expanded]="!isClpsdMods" 
          aria-controls="collapseBasic">Modifiers</button>
        <div id="charMods" [collapse]="isClpsdMods" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_orph_mods | nl2br">
        </div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdSkls = !isClpsdSkls"
          [attr.aria-expanded]="!isClpsdSkls" 
          aria-controls="collapseBasic">Skills & Charms</button>
        <div id="charSkls" [collapse]="isClpsdSkls" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_orph_skls | nl2br">
        </div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdEqip = !isClpsdEqip"
          [attr.aria-expanded]="!isClpsdEqip" 
          aria-controls="collapseBasic">Equipment</button>
        <div id="charEqip" [collapse]="isClpsdEqip" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_orph_eqip | nl2br"></div>

      </div>

      <div style="padding-left: 10px; padding-right: 10px; padding-top: 5px;">

        <button type="button" class="btn btn-dark btn-block btn-sm" 
          (click)="isClpsdTres = !isClpsdTres"
          [attr.aria-expanded]="!isClpsdTres" 
          aria-controls="collapseBasic">Treasure</button>
        <div id="charTres" [collapse]="isClpsdTres" [isAnimated]="true" 
          align="left" style="background-color:rgba(128,128,128,0.5); 
          margin:0px; padding:10px; line-height:130%" 
          [innerHTML]="char_orph_tres | nl2br"></div>

      </div>

      <div>
        <button style="margin: 5px 0px 0px 0px;" type="button" 
          class="btn btn-dark btn-sm">Edit</button>
      </div>

    </div>

    <hr>
    <h4 align="left">References</h4>
    <p>
      <button class="btn btn-dark btn-sm" containerClass="customClass" 
        tooltip="Open a new tab containing online game resources." >
        <img class="imgOrph" src="/assets/logos/OrphansRealmLogo-Light.svg">
      </button>
    </p>

  </div>

  <div class="col" align="center">
    <hr>
    <h4 align="left">Notes</h4>
    <div style="padding: 5px 10px 0px 10px;">
      <div align=center>
        <img class="imgPortrait" [style.color]="charColor" 
          src="/assets/images/xmpl/xmpl-portrait.png"
          tooltip="Upload a character portrait for additional game flavor." >
      </div>
      <h5 align="left">Public Description & History</h5>
      <h6 align="left">[ Visible to all campaign members. ]</h6>
      <textarea id="charPubBkgd" name="charPubBkgd" cols="50" rows="10" 
        readonly
        style="resize: both; overflow: auto; width:100%;"
        tooltip="Add a character background or other character-defining text." 
        >{{char_pub_bkgd}}</textarea>
      <p> </p>
      <h5 align="left">Private Notes</h5>
      <h6 align="left">[ Visible to game master. ]</h6>
      <textarea id="charPrvBkgd" name="charPrvBkgd" cols="50" rows="10" 
        readonly
        style="resize: both; overflow: auto; width:100%;"
        tooltip="Add a character background or other character-defining text." 
        >{{char_prv_bkgd}}</textarea>
    </div>
  </div>
</div>

