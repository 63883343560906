
<div class="row">
  <div class="col">
    <h2><fa-icon [icon]="faDragon"></fa-icon> X'ample's X'hibition</h2>
  </div>
</div>

<div class="row">

  <div class="col">
    <hr>
    <div tooltip="Need text." container="body">
      <h4>Game System:</h4>
      <h5>Orphans' Realm</h5>
    </div>

    <hr>
    <div tooltip="Need text." container="body">
      <h4>Game Resources</h4>

      <ng-template #itemTemplate let-item="item" let-index="index">
        <div class="sortable-item">
          <span><b>{{item.value.name}}</b> [ {{item.value.type}} ]</span>
          <button style="float:right; margin: 0px">Edit</button>
        </div>
      </ng-template>

      <div *ngFor="let res of vttRes">
        <div *ngIf="res.content" style="padding: 5px 10px 0px 10px;">
          <button class="btn btn-dark btn-block btn-sm" (click)="res.isClpsd = !res.isClpsd"
            [attr.aria-expanded]="!res.isClpsd" aria-controls="collapseBasic"
            >{{res.resName}}</button>
          <div id="res.resName" [collapse]="res.isClpsd" [isAnimated]="true"
            style="margin:0px; padding:10px; background-color:rgba(128,128,128,0.5);">
            <bs-sortable
              [(ngModel)]="res.content"
              [itemTemplate]="itemTemplate"
              itemClass="sortable-item"
              itemActiveClass="sortable-item-active"
              placeholderItem="Drag here"
              placeholderClass="placeholderStyle"
              wrapperClass="sortable-wrapper">
            </bs-sortable>
          </div>
        </div>
      </div>

    </div>

    <hr>
    <div tooltip="Need text." container="body">
      <h4>Game System</h4>
      <p align="center">
        <button class="btn btn-dark btn-sm" containerClass="customClass"
          tooltip="Need text.">
          <img class="imgOrph" src="/assets/logos/OrphansRealmLogo-Light.svg">
        </button>
      </p>
    </div>

  </div>

  <div class="col">
    <hr>
    <div>
      <h4>Tools</h4>

      <div align=center style="padding: 0px 10px 0px 10px;">
        <div tooltip="Need text." container="body">
          <table>
            <th colspan=2>Resource Management</th>
            <tr>
              <td colspan=2><i>Resources include tabletops, handouts, and downloadables.  They
                can be diplayed to the players during a game.</i>
              </td>
            </tr>
            <tr>
              <td>Import an existing resource from a linked Game Box.</td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Import Resource</button>
              </td>
            </tr>
            <tr>
              <td>Create a new tabletop resource from map and token components.
              </td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Create Tabletop</button>
              </td>
            </tr>
            <tr>
              <td>Create a new handout resrouce from uploaded image and text.</td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Create Handout</button>
              </td>
            </tr>
            <tr>
              <td>Create a new downloadable resource, which players can 
                download to their device.</td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Create Downloadable</button>
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div tooltip="Need text." container="body">
          <table>
            <th colspan=2>Component Management</th>
            <tr>
              <td colspan=2><i>Components include maps, tokens, narrative text, and widgets.  These 
                are used to create resources.</i>
              </td>
            </tr>
            <tr>
              <td>Create a new map component by uploading a map image and setting its scale.</td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Create Map</button>
              </td>
            </tr>
            <tr>
              <td>Create a new PC, NPC, or Interactive token by uploading the outline image and
                setting its scale, the icon image, setting the default color, entering hover text,
                and entering any detailed text.</td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Create Token</button>
              </td>
            </tr>
            <tr>
              <td>Create a widget to use in a virtual tabletop by uploading the appropriate 
                configuration file.</td>
              <td align=center>
                <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
                  >Create Widget</button>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div>

  </div>

  <div class="col">
    <hr>
    <div tooltip="Need text." container="body">
      <h4>Linked Game Boxes</h4>
      <div align="center">
        <br>
        <p>
          <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
            >Link Game Box</button>
        </p>
        <p>
          <button style="margin: 5px 0px 0px 0px;" type="button" class="btn btn-dark btn-sm"
            >Unlink Game Boxes</button>
        </p>
      </div>
      <div align="left" style="margin:0px; padding:0px 10px 0px 10px; background-color:rgba(128,128,128,0.5);">
        <tree-ngx [nodeItems]='nodeItems' [options]='options' ></tree-ngx>
      </div>
    </div>
  </div>

</div>
