const id = "mwx-pvgd//tyro-tools/";

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageBus, Connection, Message } from 'ngx-message-bus';
import { MwxCatalogService } from '@app/mwx-auth/mwx-catalog.service';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { MwxMsgq } from '@app/mwx-data/mwx-msgq';
import { MwxTabHeading } from '@app/mwx-data/mwx-tab';

@Component({
  selector: 'app-tyro-tools',
  templateUrl: './tyro-tools.component.html',
  styleUrls: ['./tyro-tools.component.css']
})
export class TyroToolsComponent implements OnInit {

  /*  Font Icons  */
  faBook = faBook;

  /*  Message Queue  */
  msgq: MwxMsgq = {
    msgqId: "pvgdQueue",           //  The queue identifier
    procId: "tyroTools",           //  The process identifier
    groupId: "pvgdGrp"             //  The process group identifier
  };

  items = [ ];

  constructor(
    private chngDetRef: ChangeDetectorRef,
    private mwxCatalogService: MwxCatalogService,
    private messageBus: MessageBus) { }

  ngOnInit() {
    //  Connect message queue.
    this.msgq.msgqConnection = this.messageBus.connect(this.msgq.msgqId, this.msgq.procId);
  }

  ngAfterContentInit() {
    const fid = id + "ngAfterContentInit(): ";

    //  Set the event handler for the message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: this.handleMessage.bind(this)
    };
    this.msgq.msgqConnection.on(subscription);

    //  Load catalog items.
    this.getCatalogItems();
  }

  ngOnDestroy() {
    const fid = id + "ngOnDestroy(): ";
    
    //  Disconnect message queue.
    const subscription = {
      groupId: this.msgq.groupId,
      callback: null
    };
    this.msgq.msgqConnection.off(subscription);
    this.messageBus.disconnect(this.msgq.msgqConnection);
    this.msgq.msgqConnection = null;
  }

  handleMessage(data:any) {
    const fid = id + "handleMessage(): ";
    console.log(fid + "ERROR: message not handled");
  }

  public requestTab(tabid:string, hdg?:MwxTabHeading): void {
    const fid = id + "requestTab(): ";

    //  Send a message
    const message:Message<any> = {
      recipientIds: ["mwxTabpane"],
      payload: { cmd: 0x1011, data: tabid, hdg:hdg },
      groupId: this.msgq.groupId
    };
    this.msgq.msgqConnection.post(message);
  }

  private getCatalogItems(): void {
    const fid = id + "getItems(): ";

    this.mwxCatalogService.getCatalogItems('tyro').subscribe(
      data => {
        this.items = data.result;
        this.chngDetRef.detectChanges();
      },
      err => {
        console.log(fid + "ERROR: " + JSON.stringify(err));
      }
    );
  }

  private clickURI(item) {
    const fid = id + "clickURI: ";
    window.open(item.linkURI);
  }

}

