const id = "mwx-pvgd//mwx-contact/";

/*  Email routine
    https://www.w3schools.com/nodejs/nodejs_email.asp
*/

import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, 
  Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faUserPlus, faWindowClose, faCheckSquare, faEnvelope }
  from '@fortawesome/free-solid-svg-icons';
import { MwxContactService } from './mwx-contact.service';

@Component({
  selector: 'app-mwx-contact',
  templateUrl: './mwx-contact.component.html',
  styleUrls: ['./mwx-contact.component.css']
})
export class MwxContactComponent implements OnInit {

  modalRef: BsModalRef;

  private contactForm: UntypedFormGroup;

  /*  Font Icons  */
  faUserPlus = faUserPlus;
  faWindowClose = faWindowClose;
  faCheckSquare = faCheckSquare;
  faEnvelope = faEnvelope;

  constructor(
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    private mwxContactService: MwxContactService) { 
  }

  ngOnInit(): void {
    const fid = id + "ngOnInit(): ";

    //  Create form.
    this.createForm();
  }

  open(template: TemplateRef<any>) {
    const fid = id + "open(): ";
    this.modalRef = this.modalService.show(template);
  }

  private createForm(): void {
    const fid = id + "createForm(): ";

    this.contactForm = this.fb.group({
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      msg: new UntypedFormControl('', {
        validators: [Validators.required]
      })
    });
  }

  private onContact(): void {
    const fid = id + "onContact(): ";

    if(this.contactForm.valid) {
      const { email, msg } = this.contactForm.value;
      this.mwxContactService.sendMessage(email, msg)
        .subscribe(
          data => {
          },
          err => {
            console.log(fid + "ERROR: " + err);
          }
        );
    } else {
      console.log(fid + "ERROR: contactForm invalid");
    }
    this.modalRef.hide();
    this.contactForm.patchValue({msg: ""});

  }
}
