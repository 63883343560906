<div class="container-fluid"
  style="margins: 0px; padding: 0px;"
  align="center">

  <h1><fa-icon [icon]="faUserShield"></fa-icon> Characters</h1>
  <hr>

  <div class="row justify-content-center">
    <div *ngFor="let char of charlist" class="card-char">
      <div class="row">
        <div class="col">
          <h5>{{ char.name }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>Campaign: {{ char.cpgn }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>System: {{ char.gamesys }}</p>
          <hr>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col"></div>
        <div class="col">
          <button class="btn btn-dark btn-sm"
            (click)="requestTab('pvgdChar',
              {title:char.name, idx:char._id})">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
          </button>
        </div>
        <div class="col"></div>
        <div class="col">
          <button class="btn btn-dark btn-sm"
            (click)="deleteChar(char._id)">
            <fa-icon [icon]="faTrashAlt"></fa-icon>
          </button>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col" style="margin:10px; padding:10px">
      <button class="btn btn-dark btn-sm"
        (click)="createChar()"
        >Create Character
      </button>
    </div>
  </div>

</div>

